import { Tab, Tabs } from "@mui/material";
import { ReactElement, useState } from "react";
import { styled } from "@mui/system"; // Use MUI's styled function
import PrivateLayout from "../../components/layout/PrivateLayout";
import RoundedBox from "../../components/layout/RoundedBox";
import MyFilingsTab from "../../components/filings/MyFilingsTab";
import DraftFilingsTab from "../../components/filings/DraftFilingsTab";

// Styled Tab component
const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 600,
  textTransform: "capitalize",
  borderRadius: "8px",
  transition: "color 0.3s, background-color 0.3s, box-shadow 0.3s",
  "&.Mui-selected": {
    color: "#5261A0",
    backgroundColor: "white",
    boxShadow: "0px 5px 20px 0px #0000000D",
  },
  "&:not(.Mui-selected)": {
    color: "#7C7B84",
  },
}));

const MyFilingsScreen = (): ReactElement => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  // Function to render the content for the selected tab
  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return <MyFilingsTab />;
      case 1:
        return <MyFilingsTab />;
      case 2:
        return <DraftFilingsTab />;
      default:
        return null;
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <PrivateLayout>
      <RoundedBox color="normal">
        <RoundedBox className="bg-white px-10 py-12">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            centered
            variant="fullWidth"
            className="bg-[#F0F2F5] p-2 rounded-2xl mb-10"
            TabIndicatorProps={{
              style: {
                display: "none", // Hide the bottom indicator
              },
            }}
          >
            <StyledTab label="My Filings" />
            <StyledTab label="Firm Filings" />
            <StyledTab label="Draft Filings" />
          </Tabs>

          {/* Render the content using the abstracted function */}
          <div className="tab-content">{renderTabContent()}</div>
        </RoundedBox>
      </RoundedBox>
    </PrivateLayout>
  );
};

export default MyFilingsScreen;
