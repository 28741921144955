import { isBefore } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";

export interface Filing {
  id: number;
  envelope: string;
  caseNumber: string;
  caseTitle: string;
  clientMatterNumber: string;
  filingStatus: string;
  lastUpdate: string;
  description: string;
}


export type FilingFilters = {
  search?: string;
  statuses?: string[];
  envelope?: string;
  caseNumber?: string;
  clientMatterNumber?: string;
  dateFrom?: Dayjs | null;
  dateTo?: Dayjs | null;
};

const mockFilings: Filing[] = [
  {
    id: 1,
    envelope: "83917502",
    caseNumber: "ATC-93021",
    caseTitle: "State vs. Johnson",
    clientMatterNumber: "7384",
    filingStatus: "Failed",
    lastUpdate: "03/28/24, 8:22",
    description: "Petition",
  },
  {
    id: 2,
    envelope: "83917503",
    caseNumber: "ATC-93021",
    caseTitle: "State vs. Johnson",
    clientMatterNumber: "8492",
    filingStatus: "Submitting",
    lastUpdate: "03/28/24, 8:22",
    description: "Petition",
  },
  {
    id: 3,
    envelope: "83917504",
    caseNumber: "ATC-93022",
    caseTitle: "Doe vs. Smith",
    clientMatterNumber: "6492",
    filingStatus: "Submitted",
    lastUpdate: "03/28/24, 8:22",
    description: "Petition",
  },
  {
    id: 4,
    envelope: "83917505",
    caseNumber: "ATC-93023",
    caseTitle: "Acme Corp vs. XYZ Inc.",
    clientMatterNumber: "1048",
    filingStatus: "Accepted",
    lastUpdate: "03/28/24, 8:22",
    description: "Motion",
  },
  {
    id: 5,
    envelope: "83917506",
    caseNumber: "ATC-93024",
    caseTitle: "Brown vs. Green",
    clientMatterNumber: "4859",
    filingStatus: "Rejected",
    lastUpdate: "03/28/24, 8:22",
    description: "Petition",
  },
];

const mockDraftFilings: Filing[] = [
  {
    id: 10,
    envelope: "83917510",
    caseNumber: "ATC-93022",
    caseTitle: "State vs. Smith",
    clientMatterNumber: "7385",
    filingStatus: "Draft",
    lastUpdate: "03/29/24, 9:22",
    description: "Petition",
  },
  {
    id: 11,
    envelope: "83917511",
    caseNumber: "ATC-93023",
    caseTitle: "Doe vs. Johnson",
    clientMatterNumber: "7386",
    filingStatus: "Draft",
    lastUpdate: "03/30/24, 10:22",
    description: "Motion",
  },
  // Add more draft filings as needed
];

export const statuses = {
    submitting: "Submitting",
    failed: "Failed",
    submitted: "Submitted",
    accepted: "Accepted",
    rejected: "Rejected",
  };


const useFilings = () => {
  const [filings, setFilings] = useState<Filing[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchFilings = useCallback(
    async (options: FilingFilters = {}) => {
      setLoading(true);
      setError(null);
      try {
        // Simulate an API call
        setTimeout(() => {
          let filteredFilings = mockFilings;

          // Filter by statuses
          if (options.statuses && options.statuses.length > 0) {
            filteredFilings = filteredFilings.filter((filing) =>
              options.statuses!.some(
                (status) => filing.filingStatus.toLowerCase() === status.toLowerCase()
              )
            );
          }

          // Filter by search query
          if (options.search) {
            const searchLower = options.search.toLowerCase();
            filteredFilings = filteredFilings.filter(
              (filing) =>
                filing.envelope.toLowerCase().includes(searchLower) ||
                filing.caseNumber.toLowerCase().includes(searchLower) ||
                filing.clientMatterNumber.toLowerCase().includes(searchLower) ||
                filing.filingStatus.toLowerCase().includes(searchLower) ||
                filing.description.toLowerCase().includes(searchLower)
            );
          }

          // Filter by envelope
          if (options.envelope) {
            const envelopeLower = options.envelope.toLowerCase();
            filteredFilings = filteredFilings.filter((filing) =>
              filing.envelope.toLowerCase().includes(envelopeLower)
            );
          }

          // Filter by caseNumber
          if (options.caseNumber) {
            const caseNumberLower = options.caseNumber.toLowerCase();
            filteredFilings = filteredFilings.filter((filing) =>
              filing.caseNumber.toLowerCase().includes(caseNumberLower)
            );
          }

          // Filter by clientMatterNumber
          if (options.clientMatterNumber) {
            const clientMatterNumberLower = options.clientMatterNumber.toLowerCase();
            filteredFilings = filteredFilings.filter((filing) =>
              filing.clientMatterNumber.toLowerCase().includes(clientMatterNumberLower)
            );
          }

          // **Filter by date range**
          if (options.dateFrom || options.dateTo) {
            filteredFilings = filteredFilings.filter((filing) => {
              // Parse lastUpdate to Dayjs object
              const lastUpdateDate = dayjs(filing.lastUpdate, 'MM/DD/YY, H:mm', true);

              if (!lastUpdateDate.isValid()) {
                // Exclude filings with invalid date
                return false;
              }

              let isAfterFrom = true;
              let isBeforeTo = true;

              if (options.dateFrom) {
                isAfterFrom = lastUpdateDate.isAfter(options.dateFrom, 'day');
              }
              if (options.dateTo) {
                isBeforeTo =  lastUpdateDate.isBefore(options.dateFrom, 'day');
              }
              console.log({isAfterFrom})
              console.log({isBeforeTo})
              const inBetween = isAfterFrom && isBeforeTo || lastUpdateDate.isSame(options.dateTo, 'day')

              return inBetween;
            });
          }

          setFilings(filteredFilings);
          setLoading(false);
        }, 500);
      } catch (err) {
        setError('Failed to fetch filings');
        setLoading(false);
      }
    },
    []
  );

  const fetchFilingById = (id: number): Filing | undefined => {
    return filings.find((filing) => filing.id === id);
  };

  const fetchDraftFilings = useCallback(
    async (options: FilingFilters = {}) => {
      setLoading(true);
      setError(null);
      try {
        setTimeout(() => {
          let filteredFilings = mockDraftFilings;

          // Apply filters if necessary
          if (options.search) {
            const searchLower = options.search.toLowerCase();
            filteredFilings = filteredFilings.filter(
              (filing) =>
                filing.envelope.toLowerCase().includes(searchLower) ||
                  filing.caseNumber.toLowerCase().includes(searchLower) ||
                  filing.clientMatterNumber.toLowerCase().includes(searchLower) ||
                  filing.caseTitle.toLowerCase().includes(searchLower) ||
                  filing.description.toLowerCase().includes(searchLower)
            );
          }

          setFilings(filteredFilings);
          setLoading(false);
        }, 500);
      } catch (err) {
        setError('Failed to fetch draft filings');
        setLoading(false);
      }
    },
    []
  );


  useEffect(() => {
    fetchFilings();
  }, [fetchFilings]);

  return {
    filings,
    loading,
    error,
    fetchFilings,
    fetchFilingById,
    fetchDraftFilings,
  };
};

export default useFilings;
