import React, { ReactElement, useEffect, useState } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import GavelIcon from '@mui/icons-material/Gavel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Badge, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { DateTime } from 'luxon';
import { dateHour } from '../utils/utils';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

interface Notification {
  category: 'court' | 'users',
  envelope: string,
  case: string,
  date: Date,
  status: 'Accepted' | 'Failed' | 'Reviewed' | 'Under Review' | 'Submitted' | 'Rejected',
  type: 'service' | 'filing'
}

const Notifications = (): ReactElement => {
  const [visible, setVisible]: [boolean, Function] = useState(false);
  const [notifications, setNotifications]: [Notification[], Function] = useState([]);
  const [currentTab, setCurrentTab] = useState('all');

  const statusesClasses = {
    'Accepted': 'bg-green-600',
    'Failed': 'bg-red-600',
    'Reviewed': 'bg-orange-500',
    'Under Review': 'bg-base-blue',
    'Submitted': 'bg-yellow-500',
    'Rejected': 'bg-red-600',
    'unknown': 'bg-gray-400',
  }

  const statusesTextClasses = {
    'Accepted': 'text-green-600',
    'Failed': 'text-red-600',
    'Reviewed': 'text-orange-500',
    'Under Review': 'text-base-blue',
    'Submitted': 'text-yellow-500',
    'Rejected': 'text-red-600',
    'unknown': 'text-gray-400',
  }

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = () => {
    // #TODO: fetch real ones fron ws
    const notifications = [];
    for (let i = 0; i < Math.round(8 + Math.random() * 15); i++) {
      notifications.push({
        category: ['court', 'users'][Math.floor(Math.random() * 2)],
        envelope: 10000000 + Math.round(Math.random() * 89999999),
        case: 'ATC-' + (10000 + Math.round(Math.random() * 89999)),
        date: DateTime.now().minus({ seconds: Math.round(Math.random() * 1000000) }).toJSDate(),
        status: ['Accepted', 'Failed', 'Reviewed', 'Under Review', 'Submitted', 'Rejected'][Math.floor(Math.random() * 6)],
        type: ['service', 'filing'][Math.floor(Math.random() * 2)],
      });
    }
    setNotifications(notifications);
  }

  const getNotificationIcon = (notification: Notification) => {
    const classes = 'bg-background-overlay-blue p-2 rounded-md text-base-blue';
    const styles = { fontSize: '32px' };
    switch (notification.category) {
      case 'court':
        return <GavelIcon className={classes} style={styles} />
      case 'users':
        return <GroupIcon className={classes} style={styles} />
    }
  }

  const getWarningIcon = (notification: Notification) => {
    let className = 'rounded-full size-3 ';
    className += statusesClasses[notification.status] ? statusesClasses[notification.status] : statusesClasses.unknown;
    return <div className={className} />;
  }

  const toggleClose = () => {
    setVisible(!visible)
  }

  const tabs = [
    {
      name: 'All',
      type: 'all'
    }, {
      name: 'Fillings',
      type: 'filing'
    }, {
      name: 'Services',
      type: 'service'
    }
  ];

  const getTabClasses = (tab: any) => {
    let classes = 'flex-1 rounded-lg p-2 text-center cursor-pointer hover:bg-white';
    classes += currentTab == tab.type ? ' bg-white font-bold text-base-blue' : ''
    return classes
  }

  const filterNotifications = () => {
    return notifications.filter(notification => currentTab == 'all' || notification.type == currentTab)
  }

  return <>
    <div
      className="absolute top-4 right-4"
    >
      <Badge badgeContent={notifications?.length} color="error" overlap="circular">
        <button
          onClick={toggleClose}
          className="rounded-full bg-base-blue text-white p-2 hover:bg-hovered-light-blue "
        >
          <NotificationsNoneOutlinedIcon fontSize="large" />
        </button>
      </Badge>
    </div>
    <Dialog
      onClose={toggleClose}
      open={visible}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          position: 'fixed',
          right: '2em',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '600px',
          maxWidth: 'none',
          height: '95%',
          margin: 0,
        },
      }}
    >
      <DialogTitle>
        Notifications
        <div
          className="rounded-full bg-slate-500 w-6 h-6 text-center bold text-white hover:text-black cursor-pointer absolute"
          style={{ fontSize: '22px', lineHeight: '20px', top: '20px', right: '20px', width: '22px', height: '22px' }}
          onClick={toggleClose}
        >
          &times;
        </div>
        <div className="text-sm text-base-blue font-bold mb-4">
          Display Notifications by:
        </div>
        <div className='rounded-lg bg-base-light-gray flex gap-2 p-1 m-auto w-2/3 text-sm'>
          {
            tabs.map(tab => <div
              className={getTabClasses(tab)}
              onClick={() => setCurrentTab(tab.type)}
              key={`tab-${tab.name}`}
            >
              {tab.name}
            </div>)
          }
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="notifications text-sm">
          <div className="my-4">
            {
              filterNotifications().map((notification: Notification, notificationIndex) => <div
                key={`notification-${notificationIndex}`}
                className='flex my-1 gap-3 cursor-pointer hover:bg-background-overlay-blue p-1 rounded-md'
              >
                <div className='flex-initial flex items-center'>
                  {getNotificationIcon(notification)}
                </div>
                <div className='flex-initial w-32'>
                  <div className='text-black'>
                    Env. N° {notification.envelope}
                  </div>
                  <div style={{ fontSize: '75%' }} className='text-gray-600'>
                    Case N° {notification.case}
                  </div>
                </div>
                <div className='flex-initial flex items-center'>
                  {getWarningIcon(notification)}
                </div>
                <div className={'flex-initial flex  items-center font-semibold ' + statusesTextClasses[notification.status]}>
                  {notification.status},
                </div>
                <div className={'flex-1 flex items-center text-gray-600'}>
                  at {dateHour(notification.date)}
                </div>
                <div className='flex-initial flex items-center w-4'>
                  <NavigateNextIcon style={{ fontSize: '130%' }} />
                </div>
              </div>)
            }
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className='pb-2 w-full'>
          <Link to={'#'} className='w-1/3 m-auto text-center block p-2 rounded-full text-base-blue bg-background-overlay-blue hover:bg-hovered-light-blue hover:text-white'>
            Load More
          </Link>
        </div>
      </DialogActions>
    </Dialog>
  </>
};

export default Notifications;