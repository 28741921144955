import { ReactElement, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import FormInput from "../../components/forms/FormInput";
import FormLabel from "../../components/forms/FormLabel";
import { FormButton } from "../../components/forms/FormButton";
import { useAuth } from "../../hooks/auth";
import { useMessageBoard } from "../../components/assets/MessageBoard";

const AttorneyForm = ({ attorneyId = null, onSubmitSuccess = null }: { attorneyId?: null | number, onSubmitSuccess?: null | Function }): ReactElement => {

  const { axios } = useAuth({middleware: "auth"});
  const { addMessageSuccess } = useMessageBoard();

  useEffect(() => {
    if (!attorneyId) {
      return
    }
    //#TODO: fetch from WS
  }, [attorneyId]);

  const initialValues: {
    email: string
    first_name: string
    middle_name: string
    last_name: string
    bar_number: string
  } = {
    email: '',//'morettilucas+'+(Math.ceil(Math.random() * 10000000))+'@hotmail.com',
    first_name: '',
    middle_name: '',
    last_name: '',
    bar_number: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => {
      return yup.object().shape({
        email: yup.string().required("Please enter an email").email("Please enter a valid email"),
        first_name: yup.string().required("Please enter your first name"),
        last_name: yup.string().required("Please enter your last name"),
        bar_number: yup.string().required("Please enter the bar number"),
      });
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const {data} = await axios.post('/attorneys', values);
        addMessageSuccess('The attorney was created')
        onSubmitSuccess && onSubmitSuccess()
      } catch (e) {

      }
    }
  });

  return <form onSubmit={formik.handleSubmit} className="text-sm">
    <div className="mb-6">
      <FormLabel mandatory>
        Email
      </FormLabel>
      <FormInput
        formik={formik}
        type="email"
        name="email"
      />
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        First Name
      </FormLabel>
      <FormInput
        formik={formik}
        name="first_name"
      />
    </div>
    <div className="mb-6">
      <FormLabel>
        Middle Name
      </FormLabel>
      <FormInput
        formik={formik}
        name="middle_name"
      />
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        Last Name
      </FormLabel>
      <FormInput
        formik={formik}
        name="last_name"
      />
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        Bar Number
      </FormLabel>
      <FormInput
        formik={formik}
        name="bar_number"
      />
      <p className="text-red-700 my-2 text-xs">
        Note: Remember that users with an active Bar Number will be automatically created as a service contact.
      </p>
    </div>
    <div className="text-center">
      <FormButton className="m-auto w-3/5 inline-block">
        Create User
      </FormButton>
    </div>
  </form>;
};

export default AttorneyForm;