import { ReactElement, useEffect, useState } from "react";
import PrivateLayout from "../../components/layout/PrivateLayout";
import RoundedBox from "../../components/layout/RoundedBox";
import Notifications from "../../components/Notifications";
import { Link } from "react-router-dom";
import { SearchCaseDialogExistingCase } from "./SearchCaseDialog";

export const FileCaseRow = ({...props}): ReactElement => {
  const [showSearchCaseDialog, setShowSearchCaseDialog]: [any, Function] = useState(false);

  const onFileIntoAnExistingCaseClick = () => {
    setShowSearchCaseDialog(true)
  }

  return <div {...{...props, className: `flex gap-4 ${props.className ?? ''}` }}>
    <RoundedBox className="bg-white w-1/2 text-center">
      <h3 className="text-2xl mb-6">
        NEW CASE
      </h3>
      <p className="mb-6">
        Are you filing an original legal petition with the<br />
        courts?
      </p>
      <Link to="/file-case" className="px-16 py-3 text-base-blue bg-background-overlay-blue rounded hover:bg-hovered-light-blue hover:text-white active:bg-active-light-blue inline-block">
        File A New Case
      </Link>
    </RoundedBox>
    <RoundedBox className="bg-white w-1/2 text-center">
      <h3 className="text-2xl mb-6">
        EXISTING CASE
      </h3>
      <p className="mb-6">
        Are you filing on a case that already exists in the<br />
        courts?
      </p>
      <a
        className="px-16 py-3 text-base-blue bg-background-overlay-blue rounded hover:bg-hovered-light-blue hover:text-white active:bg-active-light-blue inline-block cursor-pointer"
        onClick={onFileIntoAnExistingCaseClick}
      >
        File Into An Existing Case
      </a>
    </RoundedBox>
    <SearchCaseDialogExistingCase
      open={showSearchCaseDialog}
      onClose={() => setShowSearchCaseDialog(false)}
    />
  </div>
}

const FileToCourtScreen = (): ReactElement => {

  const [vaPlans, setVaPlans]: [any, Function] = useState([]);

  useEffect(() => {
    fetchVaPlans()
  }, []);

  const fetchVaPlans = () => {
    //#TODO: fetch from backend?
    setVaPlans([
      {
        name: 'DIAMOND',
        price: 70,
        period: 'Completed Within 1 hour*'
      }, {
        name: 'PLATINUM',
        price: 40,
        period: 'Completed by 5pm*'
      }, {
        name: 'GOLD',
        price: 25,
        period: 'Completed within 24 hours*'
      }
    ])
  }

  return <PrivateLayout>
    <RoundedBox
      className="bg-background-overlay-blue bg-professional bg-no-repeat bg-contain mb-4 relative"
      style={{ backgroundPosition: 'top 0 right 2.5em' }}
    >
      <div className="p-6">
        <h2 className="text-base-blue text-2xl mb-2">
          Let’s embark on the Filing Journey,
        </h2>
        <p>
          Please choose one of the two options provided below
        </p>
      </div>
      <Notifications />
    </RoundedBox>

    <FileCaseRow className="mb-4"/>

    <RoundedBox
      className="bg-background-blue border-1 border-gray-200 flex gap-12"
      style={{ backgroundPosition: 'top 0 right 2.5em' }}
    >
      <div className="flex-1 text-center">
        <h3 className="text-2xl mb-6">
          VIRTUAL E-FILING ASSISTANT®
        </h3>
        <div style={{ maxWidth: '400px' }} className="m-auto">
          <p className="mb-6">
            Discover Our Newest Product Designed for Law Firms and
            Attorneys Today.
          </p>
          <p className="mb-6">
            Our latest product has been meticulously crafted with law firms, attorneys,
            and their teams in mind.
          </p>
          <p className="mb-6">
            Our objective was to create a comprehensive filing solution that offers
            speed, efficiency, all while being complemented by our industry-leading
            customer service.
          </p>
        </div>
        <Link to="#" className="rounded-full px-8 py-3 text-white bg-base-blue hover:bg-hovered-light-blue active:bg-active-light-blue inline-block">
          No time to File? We can do it for you
        </Link>
      </div>
      <div className="flex-1 flex flex-col gap-4">
        {
          vaPlans.map((plan:any) => <div className="w-80 m-auto rounded-md shadow-md bg-white p-3 text-center hover:shadow-lg cursor-pointer" key={plan.name}>
            <p className="mb-1 font-semibold uppercase">
              {plan.name}
            </p>
            <p className="mb-1">
              {plan.period}
            </p>
            <p className="text-base-blue text-xl">
              ${plan.price}
            </p>
          </div>)
        }
      </div>
    </RoundedBox>
  </PrivateLayout>;
};

export default FileToCourtScreen;
