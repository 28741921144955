import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useUsers from "../../hooks/useUsers";

interface EmailPreferencesDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (preferences: { submitted: boolean; accepted: boolean; rejected: boolean }) => void;
  userId: number; // Add userId prop
}

const DialogEmailPreferences: React.FC<EmailPreferencesDialogProps> = ({
  open,
  onClose,
  onSave,
  userId,
}) => {
  const { getEmailPreferences, updateEmailPreferences } = useUsers();
  const [preferences, setPreferences] = useState({
    submitted: false,
    accepted: false,
    rejected: false,
  });
  const [isSaved, setIsSaved] = useState(false);

  // Fetch email preferences when the dialog opens
  useEffect(() => {
    const fetchPreferences = async () => {
      if (open && userId) {
        try {
          const userPreferences = await getEmailPreferences(userId);

          if (userPreferences) {
            setPreferences({
              submitted: userPreferences.submitted,
              accepted: userPreferences.accepted,
              rejected: userPreferences.rejected,
            });
          }
        } catch (error) {
          console.error("Error fetching email preferences:", error);
        }
      }
    };

    fetchPreferences();
  }, [open, userId]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreferences((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleSave = async () => {
    try {
      // Update preferences using the hook method
      await updateEmailPreferences(userId, preferences);
      onSave(preferences);

      // Trigger the saved animation
      setIsSaved(true);

      // Revert the button back to its original state after 2 seconds
      setTimeout(() => {
        setIsSaved(false);
      }, 2000);
    } catch (error) {
      console.error("Error updating email preferences:", error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ "& .MuiDialog-paper": { padding: "20px", minWidth: "400px", borderRadius: "18px" } }}
    >
      <DialogTitle
        sx={{
          fontSize: "24px",
          position: "relative",
          paddingBottom: "10px",
          color: "#5261A0",
          marginBottom: "32px",
        }}
      >
        Configure Email Preferences
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84", fontWeight: "bold" }}>Efile Emails</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84", fontWeight: "bold", marginBottom: "16px" }}>MyFileRunner Emails</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferences.submitted}
                  onChange={handleCheckboxChange}
                  name="submitted"
                  sx={{
                    color: "#7C7B84",
                    "&.Mui-checked": {
                      color: "#5261A0",
                    },
                  }}
                />
              }
              label={<Typography sx={{ fontWeight: "bold", color: "#7C7B84" }}>Filing Submitted</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferences.accepted}
                  onChange={handleCheckboxChange}
                  name="accepted"
                  sx={{
                    color: "#7C7B84",
                    "&.Mui-checked": {
                      color: "#5261A0",
                    },
                  }}
                />
              }
              label={<Typography sx={{ fontWeight: "bold", color: "#7C7B84" }}>Filing Accepted</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={preferences.rejected}
                  onChange={handleCheckboxChange}
                  name="rejected"
                  sx={{
                    color: "#7C7B84",
                    "&.Mui-checked": {
                      color: "#5261A0",
                    },
                  }}
                />
              }
              label={<Typography sx={{ fontWeight: "bold", color: "#7C7B84" }}>Filing Rejected</Typography>}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleSave}
          sx={{
            backgroundColor: isSaved ? "#4CAF50" : "#5261A0", // Green when saved
            color: "white",
            textTransform: "capitalize",
            paddingX: 6,
            "&:hover": {
              backgroundColor: isSaved ? "#45A049" : "#40518A", // Darker green on hover when saved
            },
          }}
        >
          {isSaved ? "Saved!" : "Save"} {/* Change text when saved */}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogEmailPreferences;
