import { ReactElement, useEffect, useState } from "react";
import { useFormik } from "formik";
import { FormButton, FormButtonLight } from "../../../components/forms/FormButton";
import * as yup from 'yup';
import { WizardStep } from "../../../components/StepsWizard";
import FormInput from "../../../components/forms/FormInput";
import FormLabel from "../../../components/forms/FormLabel";
import { Case, DEFAULT_TYLER_US_COUNTRY_ID, Party, VALIDATION_PHONE, VALIDATION_ZIP_CODE, makeName, objectToQuery } from "../../../utils/utils";
import FormSelect from "../../../components/forms/FormSelect";
import HiddenCard from "../../../components/HiddenCard";
import FormSelectMultiple from "../../../components/forms/FormSelectMultiple";
import { useAuth } from "../../../hooks/auth";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import RoundedBox from "../../../components/layout/RoundedBox";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DialogConfirm from "../../../components/dialogs/DialogConfirm";
import { useSelector } from "react-redux";
import { selectCountries, selectPartyTypes, selectStates, selectSuffixes, setCountries, setStates, setSuffixes } from "../../../components/assets/CaseWizard";
import useConfigs from "../../../hooks/useConfigs";
import useAttorneys from "../../../hooks/useAttorneys";

const PartyForm = ({ party = false, fcase, onSubmit, ...props }: { party: Party | boolean, fcase: Case, onSubmit?: Function }): ReactElement => {

  const { axios } = useAuth({ middleware: 'auth' });
  
  const partyTypes = useSelector(selectPartyTypes)
  const {attorneys} = useAttorneys()
  const suffixes = useSelector(selectSuffixes)
  const states = useSelector(selectStates)
  const countries = useSelector(selectCountries)

  const {configRegex, configValidationMessage} = useConfigs();

  const isPersonalOptions = ['Personal', 'Business'];

  useEffect(() => {
    fetchSuffixes()
    fetchCountries()
  }, [fcase?.location_id]);

  useEffect(() => {
    if (!party) {
      return;
    }
    if(typeof party !== 'boolean') {
      if(party?.state) {
        party.country = party?.state?.mfr_country_id
      } else {
        //#TODO: check if fcase location is journaltech, tyler or whatever
        party.country = DEFAULT_TYLER_US_COUNTRY_ID
      }
      onCountryChange({id: party.country})
    }
    formik.setValues(party && typeof party === 'boolean' ? initialValues : party);
  }, [party]);

  const fetchSuffixes = async () => {
    let data = [];
    if(fcase?.location_id) {
      const params = objectToQuery({location_id: fcase.location_id})
      data = (await axios.get(`/name-suffixes?${params}`)).data
    }
    setSuffixes(data);
  }
  
  const fetchCountries = async () => {
    let data = [];
    if(fcase?.location_id) {
      data = (await axios.get(`/countries/${fcase.location_id}`)).data;
    }
    setCountries(data);
  }

  const onCountryChange = async (country: any) => {
    let data = [];
    if(country?.id) {
      data = (await axios.get(`/states/${country.id}`)).data;
    }
    setStates(data);
  }

  // --------------------------------------------
  
  const initialValues: Party = {
    party_type_id: undefined,
    business_type: 'Personal',

    first_name: '',
    last_name: '',
    middle_name: '',
    name_suffix_id: undefined,

    business_name: '',

    attorney_id: undefined,
    phone: '',

    street_address_1: '',
    street_address_2: '',
    city: '',
    state_id: undefined,
    zip_code: '',
    country: DEFAULT_TYLER_US_COUNTRY_ID
  }

  const formik = useFormik({
    initialValues,
    validationSchema: () => {
      const rules: any = {
        party_type_id: yup.string().required('Please select a type'),
        // attorney_id: yup.string().required('Please select an attorney'),
        business_type: yup.string().required('Please select an option'),
        phone: yup.string().matches(VALIDATION_PHONE, 'Please enter a valid phone number'),
        zip_code: yup.string().matches(VALIDATION_ZIP_CODE, 'Please enter a valid ZIP code or leave it empty'),
      }
      if(formik.values.business_type == 'Personal') {
        rules.first_name = yup.string().required('Please enter a name').matches(configRegex('PartyFirstName'), configValidationMessage('PartyFirstName'));
        rules.last_name = yup.string().required('Please enter a last name').matches(configRegex('PartyLastName'), configValidationMessage('PartyLastName'));
        rules.middle_name = yup.string().matches(configRegex('PartyMiddleName'), configValidationMessage('PartyMiddleName'));
      } else {
        rules.business_name = yup.string().required('Please enter a business name').matches(configRegex('PartyBusinessName'), configValidationMessage('PartyBusinessName'))
      }
      return yup.object().shape(rules);
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (typeof party === 'boolean') {
          await axios.post(`/filing-cases/${fcase.id}/parties`, values)
        } else {
          await axios.put(`/filing-cases/${fcase.id}/parties/${party.id}`, values)
        }
        onSubmit && onSubmit(values)
      } catch (e) {
        console.error(e)
      }
    }
  });

  const partyTypeOptionLabel = (partyType: any) => {
    const options: any = {
      mandatory: !!partyType.is_required,
      style: {
        marginBottom: 0
      }
    }
    return <FormLabel {...options}>
      {partyType.name}
    </FormLabel>
    // return <span className={partyType.is_required ? 'text-blue-600' : ''}>
    //   {`${partyType.name}${partyType.is_required ? ' [required]' : ''}`}
    // </span>
  }

  // --------------------------------------------
  
  return <>
    <label className="block mb-4">
      Enter the details for the Parties involved in this case.
    </label>
    <form onSubmit={formik.handleSubmit}>
      <div className="flex gap-4">
        <div className="flex-1">
          <div className="mb-6">
            <FormLabel mandatory>
              Party Type
            </FormLabel>
            <FormSelect
              formik={formik}
              name="party_type_id"
              options={partyTypes}
              optionValue="id"
              optionLabel={partyTypeOptionLabel}
              empty="Select a type"
            />
          </div>
          {
            formik.values.business_type == 'Personal' && <div className="mb-6">
              <FormLabel mandatory>
                First Name
              </FormLabel>
              <FormInput
                formik={formik}
                name="first_name"
              />
            </div>
          }
          {
            formik.values.business_type == 'Personal' && <div className="mb-6">
              <FormLabel mandatory>
                Last Name
              </FormLabel>
              <FormInput
                formik={formik}
                name="last_name"
              />
            </div>
          }
          {
            formik.values.business_type == 'Business' && <div className="mb-6">
              <FormLabel mandatory>
                Business name
              </FormLabel>
              <FormInput
                formik={formik}
                name="business_name"
              />
            </div>
          }
          <div className="mb-6">
            <FormLabel>
              Attorneys
            </FormLabel>
            <FormSelect
              formik={formik}
              name="attorney_id"
              options={attorneys}
              optionValue="id"
              optionLabel={makeName}
              empty="Select an attorney"
            />
          </div>
        </div>
        <div className="flex-1">
          <div className="mb-6">
            <FormLabel mandatory>
              Parsonal/Business
            </FormLabel>
            <FormSelectMultiple
              name="business_type"
              formik={formik}
              options={isPersonalOptions}
              inline
              type="radio"
            />
          </div>
          {
            formik.values.business_type == 'Personal' && <div className="mb-6">
              <FormLabel>
                Middle Name
              </FormLabel>
              <FormInput
                formik={formik}
                name="middle_name"
              />
            </div>
          }
          {
            formik.values.business_type == 'Personal' && <div className="mb-6">
              <FormLabel>
                Suffix
              </FormLabel>
              <FormSelect
                formik={formik}
                name="name_suffix_id"
                options={suffixes}
                optionValue="id"
                optionLabel="name"
                empty="None"
              />
            </div>
          }
          <div className="mb-6">
            <FormLabel>
              Phone
            </FormLabel>
            <FormInput
              formik={formik}
              name="phone"
            />
          </div>
        </div>
      </div>
      <HiddenCard
        title="Case Party Address (Optional)"
        className="mb-6"
      >
        <div className="p-2">
          <div className="mb-4 text-red-700 font-xs">
            Note: In case of entering case party address, please fill in all the required fields.
          </div>
          <div className="flex gap-4">
            <div className="flex-1">
              <div className="mb-6">
                <FormLabel>
                  Street Address
                </FormLabel>
                <FormInput
                  formik={formik}
                  name="street_address_1"
                />
              </div>
              <div className="mb-6">
                <FormLabel>
                  City
                </FormLabel>
                <FormInput
                  formik={formik}
                  name="city"
                />
              </div>
              <div className="">
                <FormLabel>
                  Zip Code
                </FormLabel>
                <FormInput
                  formik={formik}
                  name="zip_code"
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="mb-6">
                <FormLabel>
                  Street Address 2
                </FormLabel>
                <FormInput
                  formik={formik}
                  name="street_address_2"
                />
              </div>
              <div className="mb-6">
                <FormLabel>
                  Country
                </FormLabel>
                <FormSelect
                  formik={formik}
                  name="country"
                  options={countries}
                  optionValue="id"
                  optionLabel="name"
                  empty="Select"
                  onChange={onCountryChange}
                  searchable
                />
              </div>
              <div className="">
                <FormLabel>
                  State
                </FormLabel>
                <FormSelect
                  formik={formik}
                  name="state_id"
                  options={states}
                  optionValue='id'
                  optionLabel='name'
                  empty="Select"
                  searchable
                />
              </div>
            </div>
          </div>
        </div>
      </HiddenCard>
      <div className="flex gap-4">
        <div className="flex-1">
        </div>
        {
          typeof party === 'boolean' && <>
            <div className="flex flex-initial w-60">
              <FormButtonLight
                className="flex-1"
                type="submit"
                name="createAnotherParty"
                value={false}
                onClick={formik.handleChange}
              >
                Create Party
              </FormButtonLight>
            </div>
            <div className="flex flex-initial w-60">
              <FormButton
                className="flex-1"
                type="submit"
                name="createAnotherParty"
                value={true}
                onClick={formik.handleChange}
              >
                Save and Add Another Party
              </FormButton>
            </div>
          </>
        }
        {
          typeof party != 'boolean' && <>
            <div className="flex flex-initial w-60">
              <FormButtonLight
                className="flex-1"
                type="submit"
                name="createAnotherParty"
                value={false}
                onClick={formik.handleChange}
              >
                Update Party
              </FormButtonLight>
            </div>
          </>
        }
      </div>
    </form>
  </>
};

const PartyStep = ({ step, fcase, saveFcase, fetchFcase, setShowPrevButton, setShowNextButton, ...props }: { step: WizardStep, fcase: Case, saveFcase: Function, fetchFcase: Function, setShowPrevButton: Function, setShowNextButton: Function }): ReactElement => {

  const [selectedParty, setSelectedParty]: [Party | boolean, Function] = useState(false);
  const [selectedPartyDelete, setSeletedPartyDelete]: [Party|undefined, Function] = useState();

  const partyTypes = useSelector(selectPartyTypes)
  const {attorneys, fetchAttorneys} = useAttorneys()

  const {axios} = useAuth({middleware: 'auth'})

  useEffect(() => {
    if(!fcase) {
      return;
    }
    // fetchPartyTypes();
    step.props.parties = fcase.parties
    step.props.partyTypes = partyTypes
  }, [fcase, partyTypes]);

  useEffect(() => {
    if(!step) {
      return;
    }
    if(!step.current) {
      disableShowPartyForm();
    }
  }, [step]);

  const enableShowPartyForm = (party: Party|boolean = true) => {
    setShowNextButton(false)
    setSelectedParty(party)
    step.props.showingNewPartyForm = true
    step.props.disableShowPartyForm = disableShowPartyForm
  }

  const disableShowPartyForm = () => {
    setShowNextButton(true)
    setSelectedParty(null)
    delete step.props.showingNewPartyForm
    delete step.props.disableShowPartyForm
  }

  const onPartyFormSubmit = (values: any) => {
    disableShowPartyForm();
    fetchFcase()
    if (values.createAnotherParty == 'true') {
      setTimeout(() => {
        enableShowPartyForm()
      }, 10)
    }
  }

  // --------------------------------------

  const setFilingParty = (params: any) => {
    const party = params.row;
    const updateFilingParty = async (value: boolean) => {
      await axios.put(`/filing-cases/${party.filing_case_id}/parties/${party.id}`, {...party, filing_party: value})
      fetchFcase()
    }
    return <input type="checkbox" onChange={(e) => updateFilingParty(e.target.checked)} {...{checked: party.filing_party}}/>
  }

  const actions = (params: any) => {
    return <div className="text-right">
      <DeleteIcon
        onClick={() => deleteParty(params.row)}
        className="cursor-pointer"
      />
      <EditIcon
        onClick={() => editParty(params.row)}
        className="cursor-pointer mr-1"
      />
    </div>
  }

  // --------------------------------------

  const editParty = (party: Party) => {
    enableShowPartyForm({...party});
  }

  const deleteParty = (party: Party) => {
    setSeletedPartyDelete(party);
  }

  const doDelete = async (party: Party) => {
    if(!selectedPartyDelete) {
      return;
    }
    await axios.delete(`/filing-cases/${selectedPartyDelete.filing_case_id}/parties/${selectedPartyDelete.id}`).then(() => {
      fetchFcase();
      setSeletedPartyDelete(undefined);
    });
  }

  const getPartyType = (value: any, row: any) => {
    for(const partyType of partyTypes) {
      if(partyType.id == value) {
        return partyType.name
      }
    }
    return ''
  }

  const getAttorney = (value: any, row: any) => {
    for(const attorney of attorneys) {
      if(attorney.id == value) {
        return makeName(attorney)
      }
    }
    return ''
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Filing Party', width: 100, renderCell: setFilingParty },
    { field: 'party_type_id', headerName: 'Type', flex: 200, valueGetter: getPartyType },
    { field: 'fullName', headerName: 'Name', flex: 200, valueGetter: (value, row) => makeName(row)},
    { field: 'attorney_id', headerName: 'Attorney', flex: 200, valueGetter: getAttorney},
    { field: 'action', headerName: 'Action', flex: 200, renderCell: actions, align: 'right', headerAlign: 'right' },
  ];

  if(!fcase) {
    return <></>
  }

  return <>
    {
      fcase.parties.length == 0 && !selectedParty && <div className="p-20 text-center">
        <h2 style={{ maxWidth: '800px' }} className="m-auto text-base-blue text-2xl">
          Please add the parties involved in the case by entering their details (Plaintiff, Defendant, etc.)
        </h2>
        <img style={{ minWidth: '300px', maxWidth: '600px' }} src="/images/easter-egg-hunt.svg" className="m-auto my-20 w-1/4" />
        <FormButton onClick={() => enableShowPartyForm(true)} >
          Add New Party
        </FormButton>
      </div>
    }
    {
      fcase.parties.length > 0 && !selectedParty && <RoundedBox className="bg-white mb-4">
        <div className="mb-4">
          Case Parties
        </div>
        {
          step.current && <DataGrid
            autoHeight // not working
            rows={fcase.parties}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            className="grid-mfr mb-4"
            rowSelection={false}
          />
        }
        <div className="text-center">
          <FormButtonLight onClick={() => enableShowPartyForm(true)} className="m-auto w-1/4 rounded-full">
            Add New Party
          </FormButtonLight>
        </div>
      </RoundedBox>
    }
    {
      selectedParty && <PartyForm party={selectedParty} onSubmit={onPartyFormSubmit} {...{fcase}} />
    }
    <DialogConfirm
      title='Delete party'
      confirm={doDelete}
      cancel={() => {setSeletedPartyDelete(undefined)}}
      open={selectedPartyDelete}
      className="min-w-64"
    >
      {
        selectedPartyDelete && <>
          Are you sure to delete <b>{makeName(selectedPartyDelete)}</b>?
        </>
      }
    </DialogConfirm>
  </>
};

export default PartyStep;
