import { Edit } from "@mui/icons-material";
import { useState, useEffect } from "react";
import useAccount from "../../hooks/useAccount";
import RoundedBox from "../layout/RoundedBox";

interface EmailPreferences {
  accepted: number;
  rejected: number;
  serviceundeliverable: number;
  submitted: number;
  submissionfailed: number;
  receipted: number;
  returnforcorrection: number;
  accountlocked: number;
  submittedbulksummary: number;
}

const EmailPref = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { updateEmailPreferences, getEmailPreferences, loading } = useAccount();
  const [preferences, setPreferences] = useState<EmailPreferences>(getEmailPreferences());

  useEffect(() => {
    setPreferences(getEmailPreferences());
  }, [getEmailPreferences]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const saveChanges = async () => {
    const success = await updateEmailPreferences(preferences);
    if (success) {
      setIsEditing(false);
    }
  };

  const handleRadioChange = (key: keyof EmailPreferences, value: string) => {
    setPreferences(prev => ({
      ...prev,
      [key]: value === "Yes" ? 1 : 0
    }));
  };

  const setAllPreferences = (value: string) => {
    const newValue = value === "Yes" ? 1 : 0;
    const updated = Object.keys(preferences).reduce((acc, key) => {
      acc[key as keyof EmailPreferences] = newValue;
      return acc;
    }, {} as EmailPreferences);
    setPreferences(updated);
  };

  const preferenceLabels: Record<keyof EmailPreferences, string> = {
    accepted: "Filing Accepted",
    rejected: "Filing Rejected",
    submitted: "Filing Submitted",
    submissionfailed: "Filing Submission Failed",
    returnforcorrection: "Filing has been returned for correction",
    serviceundeliverable: "Service Undeliverable",
    accountlocked: "Account has been locked",
    submittedbulksummary: "Submitted bulk summary",
    receipted: "Filing Receipted"
  };

  // Split preferences into two columns
  const allPreferenceKeys = Object.keys(preferences) as Array<keyof EmailPreferences>;
  const midPoint = Math.ceil(allPreferenceKeys.length / 2);
  const leftColumnKeys = allPreferenceKeys.slice(0, midPoint);
  const rightColumnKeys = allPreferenceKeys.slice(midPoint);

  const PreferenceColumn = ({ keys }: { keys: Array<keyof EmailPreferences> }) => (
    <div>
      {keys.map((key) => (
        <div
          key={key}
          className="grid grid-cols-2 gap-4 items-center text-sm mb-2"
        >
          <div className="font-bold">
            {preferenceLabels[key]}:
          </div>
          <div className="leading-[38px]">
            {isEditing ? (
              <div className="flex gap-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    checked={preferences[key] === 0}
                    onChange={() => handleRadioChange(key, "No")}
                    className="mr-2"
                    disabled={loading}
                  />
                  <span>No</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    checked={preferences[key] === 1}
                    onChange={() => handleRadioChange(key, "Yes")}
                    className="mr-2"
                    disabled={loading}
                  />
                  <span>Yes</span>
                </label>
              </div>
            ) : (
              preferences[key] === 1 ? "Yes" : "No"
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <RoundedBox className="mt-4 bg-white">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-[#5261A0]">
          Email Preferences:
        </h2>
        {!isEditing ? (
          <button 
            className="cursor-pointer text-[#5261A0]" 
            onClick={handleEdit}
            disabled={loading}
          >
            <Edit className="h-5 w-5" />
          </button>
        ) : (
          <button
            className="cursor-pointer font-bold text-[#5261A0]"
            onClick={saveChanges}
            disabled={loading}
          >
            Save Changes
          </button>
        )}
      </div>

      {isEditing && (
        <div className="flex justify-end mb-4">
          <button
            className="cursor-pointer text-red-600 text-base mx-5 font-bold"
            onClick={() => setAllPreferences("No")}
            disabled={loading}
          >
            No to all
          </button>
          <button
            className="cursor-pointer text-[#5261A0] text-base font-bold"
            onClick={() => setAllPreferences("Yes")}
            disabled={loading}
          >
            Yes to all
          </button>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-4">
        <PreferenceColumn keys={leftColumnKeys} />
        <PreferenceColumn keys={rightColumnKeys} />
      </div>
    </RoundedBox>
  );
};

export default EmailPref;
