import { ReactElement, useEffect, useState } from "react";
import FormError from "./FormError";

const FormSelectMultiple = ({ name, formik, options, optionValue=undefined, optionLabel=undefined, type='checkbox', inline=false, styles={}, ...props }: {name: string, formik:any, options: any[], optionValue?: string|null, optionLabel?: string|null, type?: 'checkbox'|'radio', onChange?: Function, onBlur?: Function, className?: string, multiple?: boolean, inline?: boolean, styles?: any}): ReactElement => {
  const [_options, setOptions]: [any[], Function] = useState([]);
  const [_optionValue, setOptionValue]: [string, Function] = useState('id');
  const [_optionLabel, setOptionLabel]: [string, Function] = useState('name');

  useEffect(() => {
    if(!options) {
      return;
    }
    setOptions(options.map((option: any) => {
      if(typeof option !== 'object') {
        const obj: any = {}
        obj[_optionValue] = option;
        obj[_optionLabel] = option;
        return obj;
      }
      return option;
    }));
  }, [options, _optionValue, _optionLabel]);

  useEffect(() => {
    if (!optionValue) {
      return;
    }
    setOptionValue(optionValue);
  }, [optionValue]);

  useEffect(() => {
    if (!optionLabel) {
      return;
    }
    setOptionLabel(optionLabel);
  }, [optionLabel]);

  const getOptionValue = (option: any) => {
    return `${_optionValue ? option[_optionValue] : option}`
  }

  const getOptionLabel = (option: any) => {
    return `${_optionLabel ? option[_optionLabel] : option}`
  }

  const onChange = (event: any, option: any) => {
    const value = _options.find((option) => getOptionValue(option) == event.target.value);
    if (props.onChange) {
      props.onChange(event, value.length > 0 ? value : undefined);
    }
    let selected;
    if(type == 'checkbox') {
      selected = [...formik.values[name]].map((option: any) => `${option}`)
      if(event.target.checked) {
        selected.push(getOptionValue(option))
      } else {
        selected = selected.filter((op: any) => op != getOptionValue(option));
      }
    } else {
      selected = getOptionValue(option)
    }
    formik.setFieldValue(name, selected);
  }

  const onBlur = (event: any) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
    return formik.handleBlur(event)
  }

  const getContainerClasses = () => {
    let classes = inline ? '' : ' px-2 border rounded-md hover:border-gray-300 focus:outline-none focus:border-gray-300 bg-white h-32 overflow-y-auto';
    classes += props.className ? ` ${props.className}` : ``;

    return classes;
  }

  const getItemClasses = () => {
    let classes = inline ? 'inline-block py-2 pr-2' : 'p-1 block';
    classes += props.className ? ` ${props.className}` : ``;
    return classes;
  }

  return <>
    <div
      className={getContainerClasses()}
      style={{height: inline ? '36px' : 'auto', ...styles}}
    >
      {
        _options && _options.map(option => {
          const optionValue = getOptionValue(option);
          const optionLabel = getOptionLabel(option);
          return <label className={getItemClasses()} key={`option-${optionValue}`}>
            <input
              {...{
                ...props,
                value: optionValue,
                name,
                onChange: (e) => onChange(e, option),
                onBlur,
                type,
                checked: Array.isArray(formik?.values[name]) ? formik?.values[name].map((value: any) => `${value}`)?.includes(optionValue) : formik?.values[name] == optionValue,
                className: 'mr-1 relative',
                style: {
                  top: '1px'
                }
              }}
            />
            {optionLabel}
          </label>
        })
      }
    </div>
    <FormError formik={formik} name={name} />
  </>
}
export default FormSelectMultiple;