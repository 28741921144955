import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../hooks/auth";
import loaderReducer from "../components/assets/Loader";
import messageBoardReducer from "../components/assets/MessageBoard";
import { registrationSlice } from "../pages/auth/Register";
import { caseWizard } from "../components/assets/CaseWizard";
import { caseSearch } from "../components/assets/CaseSearch";

const store = configureStore({
  reducer: {
    auth: authReducer,
    loader: loaderReducer,
    messageBoard: messageBoardReducer,
    registration: registrationSlice.reducer,
    caseWizard: caseWizard.reducer,
    caseSearch: caseSearch.reducer,
  },
});

export default store;
