import { ReactElement } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import BackArrow from "../../../components/BackArrow";
import PrivateLayout from "../../../components/layout/PrivateLayout";
import RoundedBox from "../../../components/layout/RoundedBox";
import FormEditUser from "../../../components/forms/FormEditUser";

const EditUserPage = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};

  const handleGoBack = () => {
    navigate("/admin-dashboard/user-management");
  };

  return (
    <PrivateLayout>
      <RoundedBox color="normal">
        <div className="flex flex-col">
          <Button onMouseDown={handleGoBack} className="w-fit">
            <BackArrow />
          </Button>
          <RoundedBox className="bg-white p-6">
            <Typography className="py-4 text-base">Edit User</Typography>
            {email && (
              <FormEditUser userEmail={email} onSuccess={handleGoBack} />
            )}
          </RoundedBox>
        </div>
      </RoundedBox>
    </PrivateLayout>
  );
};

export default EditUserPage;
