import { ReactElement, useEffect, useState } from "react";

const properties = (props: any) : any => {
  return {
    ...props,
    className: "px-6 text-white bg-base-blue rounded hover:bg-hovered-light-blue active:bg-active-light-blue inline-block cursor-pointer" + (props.className ? ` ${props.className}` : ``),
    style: { paddingTop: '9px', paddingBottom: '8px', ...props.style }
  }
}

const propertiesLight = (props: any) : any => {
  return {
    ...props,
    className: "px-6 text-base-blue bg-background-overlay-blue rounded hover:bg-hovered-light-blue hover:text-white active:bg-active-light-blue inline-block cursor-pointer" + (props.className ? ` ${props.className}` : ``),
    style: { paddingTop: '9px', paddingBottom: '8px', ...props.style }
  }
}

export const FormButton = ({ children, ...props }: any): ReactElement => <button {...properties(props)}>
  {children}
</button>

export const FormButtonLight = ({ children, ...props }: any): ReactElement => <button {...propertiesLight(props)}>
  {children}
</button>

export const ButtonA = ({ children, ...props }: any): ReactElement => <a {...properties(props)}>
  {children}
</a>

export const ButtonALight = ({ children, ...props }: any): ReactElement => <a {...propertiesLight(props)}>
  {children}
</a>