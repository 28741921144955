import { useState, useEffect } from "react";
import { useAuth } from "./auth";
import { selectPaymentAccounts, setPaymentAccounts } from "../components/assets/CaseWizard";
import { useSelector, useDispatch } from "react-redux"; // Added useDispatch for dispatching actions

export type PaymentAccount = {
  id?: number;
  name: string;
  type: string;
  note: string;
  account_token: string;
  card_company: string;
  card_holder_name: string;
  card_holder_address: string;
  card_last4: string;
  card_month: string;
  card_year: string;
};

const usePaymentAccounts = () => {
  const dispatch = useDispatch(); // Use dispatch for Redux actions
  const paymentAccounts = useSelector(selectPaymentAccounts);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | Error>(null);
  const { axios } = useAuth({ middleware: "auth" });

  useEffect(() => {
  }, []);

  // Create payment account
  const createPaymentAccount = async (account: PaymentAccount) => {
    try {
      await axios.post('/payment-accounts', account);
      await fetchPaymentAccounts(); // Refresh the accounts list after creating a new account
    } catch (error: any) {
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  const syncPaymentAccounts = async () => {
    try {
      await axios.get('/payment-accounts/sync');
      await fetchPaymentAccounts(); // Refresh the accounts list after creating a new account
    } catch (error: any) {
      setError(error);
      setLoading(false);
      throw error;
    }
  };

  // Delete payment account
  const deletePaymentAccount = async (id: number) => {
    setLoading(true);
    try {
      await axios.delete(`/payment-accounts/${id}`);
      dispatch(
        setPaymentAccounts(paymentAccounts.filter(
          (account) => account.id !== id)
        )
      );
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  // Fetch payment accounts with optional query parameters
  const fetchPaymentAccounts = async (per_page: number = 10, page: number = 1, is_global: number = 0) => {
    setLoading(true);
    try {
      const response = await axios.get('/payment-accounts', {
        params: {
          per_page,
          page,
          is_global,
        },
      });
      dispatch(setPaymentAccounts(response.data?.data)); // Use dispatch to update Redux state
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  // Get payment account by ID
  const getPaymentAccountById = (id: number) => {
    return paymentAccounts.find((account) => account.id === id);
  };

  return {
    paymentAccounts,
    loading,
    error,
    fetchPaymentAccounts,
    createPaymentAccount,
    syncPaymentAccounts,
    deletePaymentAccount,
    getPaymentAccountById,
  };
};

export default usePaymentAccounts;
