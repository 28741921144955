import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from 'yup';
import UserAccessLayout from "../../components/layout/UserAccessLayout";
import { useAuth } from "../../hooks/auth";
import { useMessageBoard } from "../../components/assets/MessageBoard";
import { FormLabel } from "@mui/material";
import { FormButton } from "../../components/forms/FormButton";
import FormInput from "../../components/forms/FormInput";
import BackArrow from "../../components/BackArrow";

const PasswordForgotScreen = () => {

  const navigate = useNavigate();
  const { forgotPassword } = useAuth({ displayErrors: false });
  const { addMessageError, addMessageSuccess } = useMessageBoard();

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: yup.object().shape({
      email: yup.string().required('Email is required').email('Enter a valid email address')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response: any = await forgotPassword(values);
        if(response.data?.success) {
          navigate('/login');
          addMessageSuccess(response.data.message);
        } else {
          response.data.error && addMessageError(response.data.error?.message);
        }
      } catch (e: any) {
        console.log(e);
      }
    }
  });

  return <UserAccessLayout>
    <div className="w-full">
      <BackArrow>
        <Link to={"/login"} className="tracking-wider">
          Back to Sign In
        </Link>
      </BackArrow>

      <h2 className=" mt-16 mb-6 text-2xl text-extra-large text-base-blue text-start">
        Forgot Your Password?
      </h2>
      <p className="text-base text-base-gray font-medium tracking-wider">
        No worries! We'll help you get back into your account.
      </p>
      <form
        className="mt-6 space-y-32"
        onSubmit={formik.handleSubmit}>
        <div>
          <FormLabel required>
            Email
          </FormLabel>
          <FormInput
            name="email"
            type="email"
            autoComplete="email"
            placeholder="Enter your email"
            required
            formik={formik}
          />
        </div>

        {/* TODO: change button logic for submission and redirect */}
        <FormButton className="w-full">
          Submit
        </FormButton>
      </form>
    </div>
  </UserAccessLayout>
}

export default PasswordForgotScreen;