
import { ReactElement } from "react";
import { Button, Divider, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import BackArrow from "../../../components/BackArrow";
import PrivateLayout from "../../../components/layout/PrivateLayout";
import RoundedBox from "../../../components/layout/RoundedBox";
import EditEServiceDraftForm from "../../../components/services/details/EditEServiceDraftForm";

const EServiceDraftEditPage = (): ReactElement => {
  const { id } = useParams<{id: string}>();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/my-e-services?tab=draft");
  };

  return (
    <PrivateLayout>
      <RoundedBox color="normal">
        <div className="flex flex-col">
          <Button onMouseDown={handleGoBack} className="w-fit">
            <BackArrow />
          </Button>
          <RoundedBox className="bg-white p-6">
            <Typography className="py-4 text-base">Filing Summary</Typography>
            <Divider />
            {id && (
              <EditEServiceDraftForm draftId={id} onSuccess={handleGoBack} />
            )}
          </RoundedBox>
        </div>
      </RoundedBox>
    </PrivateLayout>
  );
};

export default EServiceDraftEditPage;
