import { ReactElement } from "react";
import MFRLogo from "../assets/MFRLogo";


const UserAccessLayout = ({ children, ...props }: any): ReactElement => {
  return <div className="flex h-screen p-4 gap-4">
    <div className="flex-1 flex items-center justify-center" style={{ padding: '0 10%'}}>
      <div className="m-auto flex-1 py-12" style={{ maxWidth: '800px'}}>
        {children}
      </div>
    </div>
    <div className="flex-initial w-2/5 relative overflow-hidden h-full flex">
      <div className="h-full w-full rounded-extra-large flex justify-center align-middle bg-gray-800 bg-transparent bg-cover bg-center bg-log-in-card-bg">
        <MFRLogo className="object-contain w-1/3" />
      </div>
    </div>
  </div>
}

export default UserAccessLayout;