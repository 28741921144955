import { ReactElement, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import FormInput from "../../components/forms/FormInput";
import FormLabel from "../../components/forms/FormLabel";
import { ButtonA, FormButton } from "../../components/forms/FormButton";
import { useAuth } from "../../hooks/auth";
import { useMessageBoard } from "../../components/assets/MessageBoard";
import FormTextarea from "../../components/forms/FormTextarea";
import FormSelect from "../../components/forms/FormSelect";
import DialogCloseable from "../../components/dialogs/DialogCloseable";
import FormError from "../../components/forms/FormError";
import usePaymentAccounts, { PaymentAccount } from "../../hooks/usePaymentAccount";
import { getCurrentApiUrl } from "../../utils/utils";

const PaymentAccountForm = ({ paymentAccountId = null, onSubmitSuccess = null }: { paymentAccountId?: null | number, onSubmitSuccess?: null | Function }): ReactElement => {

  const { axios } = useAuth({ middleware: 'auth' });
  const { addMessageSuccess } = useMessageBoard();
  const [showAddDetails, setShowAddDetails] = useState(false);
  const [requestXML, setRequestXML] = useState('');
  const {createPaymentAccount} = usePaymentAccounts()
  const hiddenForm = useRef(null);

  useEffect(() => {
    // this event is triggered from mfr api endpoint where the iframe is redirected after success /payment-accounts/toga-token
    const togaTokenCreated = async (event: any) => {
      if(event.data._event_type && event.data._event_type == 'toga-token') {
        setShowAddDetails(false);

        const field_names = ['account_token', 'card_company', 'card_holder_name', 'card_holder_address', 'card_last4', 'card_year', 'card_month'];
        for(let field_name of field_names) {
          await formik.setFieldValue(field_name, event.data[field_name]);
          await formik.setFieldTouched(field_name, true);
        }
        setTimeout(async () => {
          await formik.submitForm();
        }, 10)
      }
    };
    window.addEventListener('message', togaTokenCreated);

    return () => {
      window.removeEventListener('message', togaTokenCreated);
    };
  }, []);

  useEffect(() => {
    if (!paymentAccountId) {
      return
    }
    //#TODO: fetch from WS
  }, [paymentAccountId]);

  const types = [
    {
      code: 'CC',
      name: 'Credit Card'
    }, {
      code: 'BankAccount',
      name: 'E-Checks'
    }, {
      code: 'WV',
      name: 'Waiver'
    }
  ];

  const initialValues: PaymentAccount = {
    name: '',
    type: 'CC',
    note: '',
    account_token: '',
    card_company: '',
    card_holder_name  : '',
    card_holder_address  : '',
    card_last4: '',
    card_year: '',
    card_month: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: (a: any) => {
      const rules: any = {
        name: yup.string().required("Please enter the account name"),
      };
      if(formik.values.type != 'WV') {
        rules.account_token = yup.string().required("Account token missing");
        rules.card_company = yup.string().required("Card company missing");
        rules.card_holder_name = yup.string().required("Card holder name missing");
        rules.card_holder_address = yup.string().required("Card holder address missing");
        rules.card_last4 = yup.string().required("Card last 4 digits missing");
        rules.card_year = yup.string().required("Card expiration year missing");
        rules.card_month = yup.string().required("Card expiration month missing");
      }
      return yup.object().shape(rules);
    },
    onSubmit: async (values, { setSubmitting }) => {
      const data = { ...values, is_global: false };
      //#TODO: ver esto is global con permisos o algo!!
      try {
        await createPaymentAccount(data)
        addMessageSuccess('The payment account was created')
        onSubmitSuccess && onSubmitSuccess()
      } catch (e) {

      }
    }
  });

  const addDetails = async () => {
    const errors = await formik.validateForm();
    if (!!errors.name) {
      formik.setTouched({name: true});
      return false;
    }

    const transactionID = `${(new Date).getTime()}` + `${Math.ceil(Math.random() * 100000000)}`;
    const xmlDoc = new DOMParser().parseFromString('<PaymentRequest></PaymentRequest>', 'text/xml');
    let child;

    (child = xmlDoc.createElement('ClientKey')).textContent = process.env.REACT_APP_TOGA_CLIENT_KEY;
    xmlDoc.documentElement.appendChild(child);
    (child = xmlDoc.createElement('RedirectURL')).textContent = getCurrentApiUrl() + '/payment-accounts/toga-token';
    xmlDoc.documentElement.appendChild(child);
    (child = xmlDoc.createElement('TransactionID')).textContent = transactionID;
    xmlDoc.documentElement.appendChild(child);
    (child = xmlDoc.createElement('Amount')).textContent = '-1';
    xmlDoc.documentElement.appendChild(child);
    (child = xmlDoc.createElement('GetToken')).textContent = '1';
    xmlDoc.documentElement.appendChild(child);

    setRequestXML(new XMLSerializer().serializeToString(xmlDoc));

    setShowAddDetails(true)
    setTimeout(() => {
      if(hiddenForm.current) {
        hiddenForm.current.submit();
      }
    }, 10);
  }

  return <>
    <form onSubmit={formik.handleSubmit} className="text-sm">
      <div className="mb-6">
        <FormLabel mandatory>
          Payment Account Name
        </FormLabel>
        <FormInput
          formik={formik}
          name="name"
        />
      </div>
      <div className="mb-6">
        <FormLabel mandatory>
          Type
        </FormLabel>
        <FormSelect
          required
          options={types}
          name="type"
          formik={formik}
          optionValue='code'
          optionLabel='name'
        />
      </div>
      <div className="mb-6">
        <FormLabel>
          Note
        </FormLabel>
        <FormTextarea
          formik={formik}
          name="note"
        />
      </div>
      <div style={{display: 'none'}}>
        <FormInput
          formik={formik}
          name="account_token"
        />
        <FormInput
          formik={formik}
          name="card_company"
        />
        <FormInput
          formik={formik}
          name="card_holder_name"
        />
        <FormInput
          formik={formik}
          name="card_holder_address"
        />
        <FormInput
          formik={formik}
          name="card_last4"
        />
        <FormInput
          formik={formik}
          name="card_month"
        />
        <FormInput
          formik={formik}
          name="card_year"
        />
      </div>
      <FormError formik={formik} name={'account_token'} />
      <FormError formik={formik} name={'card_company'} />
      <FormError formik={formik} name={'card_holder_name'} />
      <FormError formik={formik} name={'card_holder_address'} />
      <FormError formik={formik} name={'card_last4'} />
      <FormError formik={formik} name={'card_month'} />
      <FormError formik={formik} name={'card_year'} />
      <div className="text-center">
        {
          formik.values?.type != 'WV' && <ButtonA className="m-auto w-3/5 inline-block" onClick={addDetails}>
            { formik.values?.type == 'CC' ? 'Add Card Details' : 'Add E-check Details' }
          </ButtonA>
        }
        {
          formik.values?.type == 'WV' && <FormButton className="m-auto w-3/5 inline-block">
            Save Waiver
          </FormButton>
        }
      </div>
    </form>
    <form action={process.env.REACT_APP_TOGA_URL} ref={hiddenForm} method="post" target="toga_iframe" className="hidden">
      <input name="RequestXML" defaultValue={requestXML} />
    </form>
    <DialogCloseable
      open={showAddDetails}
      onClose={() => setShowAddDetails(false)}
      // title="Card Details"
      width="1000px"
    >
      <iframe id='toga_iframe' name='toga_iframe' width="100%" height="700px" marginWidth={0} marginHeight={0} frameBorder="no" scrolling='no' style={{outline: 'none'}}/>
    </DialogCloseable>
  </>;
};

export default PaymentAccountForm;