import { Delete, Edit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useFilings, { FilingFilters } from '../../hooks/useFilings';
import RoundedBox from '../layout/RoundedBox';
import CustomTable from '../table/CustomTable';
import FilterDraftFilingsPanel from './filters/FilterDraftFilingsPanel';
import FilterFilingsSearchBar from './filters/FilterFilingsSearchBar';

const DraftFilingsTab = () => {
  const { filings, loading, fetchDraftFilings } = useFilings();
  const navigate = useNavigate();
  const [filters, setFilters] = useState<FilingFilters>({
    search: '',
    envelope: '',
    caseNumber: '',
    clientMatterNumber: '',
    dateFrom: null,
    dateTo: null,
  });

  // Fetch draft filings whenever filters change
  useEffect(() => {
    fetchDraftFilings(filters);
  }, [filters, fetchDraftFilings]);

  // Handlers for search and filters
  const handleSearchChange = useCallback((searchTerm: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: searchTerm,
    }));
  }, []);

  const handleFilterChange = useCallback((newFilters: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  }, []);

  // Define columns for the table with color mappings for the filingStatus field
  const columns = [
    { field: 'id', headerName: 'Draft ID' }, // Updated field
    { field: 'caseNumber', headerName: 'Case N' },
    { field: 'caseTitle', headerName: 'Case Title' },
    {
      field: 'filingStatus',
      headerName: 'Filing Status',
      colorMapping: {
        Failed: 'red',
        Draft: '#0096ff',
        Submitted: '#ffcc00',
        Accepted: 'green',
        Rejected: 'red',
      },
    },
    { field: 'lastUpdate', headerName: 'Last Update' },
  ];

  const handleEditFiling = (id: number) => {
    console.log('Editing filing with ID: ', id);
  };

  const handleDeleteFiling = (id: number) => {
    console.log('Deleting filing with ID: ', id);
  };

    const baseActions = [
      {
        label: 'Edit',
        onClick: handleEditFiling,
        icon: <Edit className="text-[#5261A0]" />,
      },
      {
        label: 'Delete',
        onClick: handleDeleteFiling,
        icon: <Delete className="text-[#5261A0]" />,
      },
    ];

  return (
    <>
      <RoundedBox className="px-10 py-6 border-2 border-[#5261A0] rounded-2xl mb-8">
        <Typography className="text-black">
          Only drafts saved in the last 30 days will be shown. If your status is "Submission Failed," click the hyperlink to view the reason for the failure. If the reason is "Unknown Error" or "Unknown Exception Error," please initiate the filing process anew, as we have updated the state's code, and you may encounter a loop. For further inquiries, please reach out to Customer Support.
        </Typography>
      </RoundedBox>
      <div className="flex flex-col space-y-4">
        <FilterFilingsSearchBar onSearchChange={handleSearchChange} />
        <FilterDraftFilingsPanel onFilterChange={handleFilterChange} />
      </div>
      <CustomTable
        rows={filings.map((filing) => ({
          ...filing,
          actions: baseActions,
        }))}
        columns={columns}
        loading={loading}
      />
      <Box className="flex flex-col w-full mt-4">
        <Link
          to="/file-case"
          className="bg-[#E7ECF8] w-[405px] text-[#5261A0] rounded-full text-center mx-auto text-base py-2 px-5 hover:bg-[#D0D8EE]"
        >
          Submit New Filing
        </Link>
        <span className="m-auto text-base text-[#5261A0] mt-4">
          No time to File?
          <Link to="/contact-us" className="text-[#5261A0] hover:underline ml-1">
            We can do it for you.
          </Link>
        </span>
      </Box>
    </>
  );
};

export default DraftFilingsTab;
