import { Button } from '@mui/material';
import RoundedBox from '../../layout/RoundedBox';

interface Update {
  status: string;
  date: string;
  time: string;
  color: string;
}

const updates: Update[] = [
  { status: 'Cancelled - Recipient not found', date: '03/24/2024', time: '09:31', color: '#F23131' },
  { status: 'In Progress - Processing', date: '03/19/2024', time: '10:45', color: '#FF8933' },
  { status: 'Pending - Verification', date: '03/18/2024', time: '10:45', color: '#5261A0' },
  { status: 'Service Initiated', date: '03/15/2024', time: '10:45', color: '#EBC028' },
];

const EServiceUpdates = () => {
  const handleRetryService = () => {
    console.log("Retry Service");
  };

  const handleViewAllUpdates = () => {
    console.log("View All Updates")
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-md font-semibold">
            Service ID ES-93021, <span className="text-red-500">Cancelled</span> at 09:31, 03/24/2024
          </h2>
        </div>
        <Button
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: "#5261A0",
            textTransform: "capitalize",
            fontSize: 14,
            "&:hover": { backgroundColor: "#3e4d7a" },
          }}
          onClick={handleRetryService}
        >
          Retry Service
        </Button>
      </div>
      <h3 className="text-md mb-4 text-[#5261A0] font-medium">Service Updates:</h3>
      <RoundedBox className='bg-white p-8'>
        <ul className="space-y-3">
          {updates.map((update, index) => (
            <li key={index} className="flex items-center">
              <span 
                className="h-4 w-4 rounded-full mr-4"
                style={{ backgroundColor: update.color }}
              />
              <p className="text-sm">
                <span className="font-bold">{update.status}</span>, at {update.time}, {update.date}
              </p>
            </li>
          ))}
        </ul>
      </RoundedBox>
      <div className="flex justify-center mt-4">
        <Button 
          sx={{
            backgroundColor: "#E5EBF8",
            color: "#5261A0",
            textTransform: "capitalize",
            fontSize: 14,
            paddingX: 8,
            borderRadius: "100px",
          }}
          onClick={handleViewAllUpdates}
        >
          View All Updates
        </Button>
      </div>
    </div>
  );
};

export default EServiceUpdates;
