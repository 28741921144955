import { TextField, TextFieldProps } from "@mui/material";

const SmallTextField = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      fullWidth
      size="small"
      variant="outlined" // Use 'standard' variant for underline/bottom border only
      InputProps={{
        sx: {
          fontSize: "14px", // Adjust font size
          height: "22px",
        },
      }}
    />
  );
};

export default SmallTextField;
