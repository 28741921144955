import { Typography } from "@mui/material";
import { ReactElement } from "react";
import PrivateLayout from "../../components/layout/PrivateLayout";
import RoundedBox from "../../components/layout/RoundedBox";
import UsersTable from "../../components/users/UsersTable";

const AdminDashboard = (): ReactElement => {
  return (
    <PrivateLayout>
      <RoundedBox color="normal">
        <RoundedBox className="bg-white py-16 px-8">
          <RoundedBox className="p-4 border-2 border-[#5261A0] rounded-md mb-8">
            <Typography className="text-black">
              Manage users associated with your firm. Revoke/Invoke admin
              privileges, unlock account, re-send activation mails and view the
              user info. Create new and edit current users to make them service
              contacts.
            </Typography>
            <Typography className="text-red-600">
              Note: Remember that users with an active Bar Number will be
              automatically created as a service contact.
            </Typography>
          </RoundedBox>
          <UsersTable />
        </RoundedBox>
      </RoundedBox>
    </PrivateLayout>
  );
};

export default AdminDashboard;
