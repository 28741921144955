import { useAuth } from "./auth";
import { selectConfigs, setConfigs } from "../components/assets/CaseWizard";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

// Updated User interface
export interface Config {
  code: string
  is_required: boolean
  is_visible: boolean
  regular_expression: string
  validation_message: string
}

const useConfigs = () => {
  const {axios} = useAuth({middleware: 'auth'});

  const configs: {[key: string]: Config} = useSelector(selectConfigs);

  const [configsReady, setConfigsReady] = useState(false);

  useEffect(() => {
      getConfigs()
  }, []);

  const getConfigs = async () => {
    if(!configs || Object.keys(configs).length === 0) {
      const confs: {[key: string]: Config} = {};
      try {
        const {data} = await axios.get('data-field-configs');
        for(const d of data) {
          confs[d.code] = d;
        }
        setConfigs(confs);
        setConfigsReady(true);
      } catch(e) {
        return;
      }
      return confs;
    }
    setConfigsReady(true);
    return configs;
  };
  
  // use only when you are sure the configs object has been fetched
  const getConfig = (config: string): Config|null => {
    return configs[config] ? configs[config] : null;
  }

  // use only when you are sure the configs object has been fetched
  const isVisible = (config: string, def:boolean = false): boolean => {
    return configs[config] ? !!configs[config].is_visible : def;
  };
  
  // use only when you are sure the configs object has been fetched
  const isRequired = (config: string, def:boolean = false): boolean => {
    return configs[config] ? !!configs[config].is_required : def;
  };
  
  // use only when you are sure the configs object has been fetched
  const configRegex = (config: string) => {
    return configs[config]?.regular_expression ? new RegExp(configs[config]?.regular_expression) : /.*/;
  }
  
  // use only when you are sure the configs object has been fetched
  const configValidationMessage = (config: string): string => {
    return configs[config] ? configs[config]?.validation_message : '';
  }
  
  return {
    configsReady,
    getConfig,
    isVisible,
    isRequired,
    configRegex,
    configValidationMessage,
  };
};

export default useConfigs;
