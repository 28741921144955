import React, { useEffect, useState } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from 'yup';
import UserAccessLayout from "../../components/layout/UserAccessLayout";
import FormError from "../../components/forms/FormError";
import { useAuth } from "../../hooks/auth";
import FormPassword from "../../components/forms/FormPassword";
import FormInput from "../../components/forms/FormInput";
import { FormLabel } from "@mui/material";


const LogInScreen = () => {
  const { login } = useAuth()

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: yup.object().shape({
      email: yup.string().required('Email is required').email('Enter a valid email address'),
      password: yup.string().required('Password is required')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      login(values).then(() => navigate('/')).catch(() => { });
    }
  });

  return <UserAccessLayout>
    <div className="w-full">
      <h2 className=" mb-6 text-2xl text-extra-large text-base-blue text-start">
        Sign In
      </h2>
      <p className="text-base text-base-gray font-medium tracking-wider">
        Please fill your details to access your account.
      </p>
      <form
        className="mt-6 space-y-8"
        onSubmit={formik.handleSubmit}>
        <div>
          <FormLabel required>
            Email
          </FormLabel>
          <FormInput
            name="email"
            type="email"
            autoComplete="email"
            placeholder="Enter your email"
            required
            formik={formik}
          />
        </div>

        <div>
          <FormLabel required>
            Password
          </FormLabel>
          <FormPassword
            name="password"
            autoComplete="current-password"
            placeholder="Enter your password"
            required
            formik={formik}
          />
        </div>

        <div className="flex flex-wrap justify-between ">
          <div className="flex items-center">
            <input
              id="rememberMe"
              name="rememberMe"
              type="checkbox"
              className="mr-4 scale-150"
            /> {/*TODO: Implement remember me functionality*/}
            <label
              htmlFor="rememberMe"
              className="text-base text-base-gray"
            >
              Remember me
            </label>
          </div>

          <div className="flex text-base">
            <Link to={"/forgot-password"} className="text-base-blue hover:underline">
              Forgot password?
            </Link>
          </div>
        </div>

        {/* TODO: change button logic for submission and redirect */}
        <div className=" text-white font-medium font-base ">
          <button
            type="submit"
            className=" w-full mt-10 bg-base-blue p-2 rounded hover:bg-hovered-light-blue active:bg-active-light-blue"
          // disabled={formik.isSubmitting}
          >
            Sign In
          </button>
        </div>
      </form>
      <div className="text-base mt-8 flex justify-center">
        <p className="text-base-gray">
          Don’t have an account?{' '}
          <Link to={"/register"} className="text-base-blue">
            Sign Up
          </Link>
        </p>
        {/*TODO: change redirect URL*/}
      </div>
    </div>
  </UserAccessLayout>
}

export default LogInScreen;