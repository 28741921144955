import { Alert, AlertColor, Snackbar, SnackbarContent, Stack } from "@mui/material";
import { ReactElement, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { createSlice } from '@reduxjs/toolkit'

export interface MessageBoardMessage {
  message: string,
  type?: AlertColor,
  key?: string
}

// slice ----------------------------------------

export const messageBoardSlice = createSlice({
  name: 'messageBoard',
  initialState: {
    messages: []
  },
  reducers: {
    addMessage: (state: { messages: MessageBoardMessage[] }, payload: { payload: MessageBoardMessage }) => {
      payload.payload.key = `${(new Date).getTime()}${Math.round(Math.random() * 999999999)}`;
      state.messages = [payload.payload, ...state.messages]
    },
    removeMessage: (state: { messages: MessageBoardMessage[] }, payload) => {
      state.messages = state.messages.filter((message: MessageBoardMessage) => message.key != payload.payload)
    },
  },
})

// export const { addMessage, removeMessage } = messageBoardSlice.actions

export const selectMessageBoard = (state: any) => state.messageBoard.messages

export default messageBoardSlice.reducer

// hook ----------------------------------------

export const useMessageBoard = () => {
  const dispatch = useDispatch();

  const addMessageSuccess = (message: string) => dispatch(messageBoardSlice.actions.addMessage({ message, type: 'success' }));

  const addMessageError = (message: string) => dispatch(messageBoardSlice.actions.addMessage({ message, type: 'error' }));

  const removeMessage = (key: string) => dispatch(messageBoardSlice.actions.removeMessage(key));

  return {
    addMessageSuccess,
    addMessageError,
    removeMessage
  };
}

// component ----------------------------------------

export const MessageBoard = (): ReactElement => {
  const messages: MessageBoardMessage[] = useSelector(selectMessageBoard);
  const { removeMessage } = useMessageBoard();
  const gap = 8;

  const handleClose = (message: MessageBoardMessage) => {
    if (message.key) {
      removeMessage(message.key);
    }
  }

  return <>
    {
      messages && messages.map((message, index) => <Snackbar
        open={true}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={10000}
        onClose={() => handleClose(message)}
        key={message.key}
        className="relative"
        style={{top: `${gap + index * (46 + gap)}px`}}
      >
        <Alert
          severity={message.type ? message.type : 'info'}
          variant="filled"
          sx={{ width: '100%' }}
          onClick={() => handleClose(message)}
          className="cursor-pointer"
        >
          {message.message}
        </Alert>
      </Snackbar>)
    }
  </>
}