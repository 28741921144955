import { ReactElement } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const BackArrow = ({onClick, className = '', children, ...props}:any): ReactElement => {
  const navigate = useNavigate();
  
  const localOnClick = (e: any) => {
    if(onClick) {
      return onClick(e);
    }
    return navigate(-1);
  }
  
  return <span {...{
      ...props,
      onClick: localOnClick,
      className: `text-base-blue text-lg hover:text-hovered-light-blue cursor-pointer inline-block ${className}`
    }}>
    <ArrowBackIcon fontSize="medium" />
    <span className="relative ml-2" style={{ top: '3px' }}>
      { children ? children : 'Back' }
    </span>
  </span>
}

export default BackArrow;