import { ReactElement } from "react";
import { Grid, Box } from "@mui/material";
import PrivateLayout from "../../components/layout/PrivateLayout";
import AccountInformation from "../../components/account/AccountInfo";
import PasswordInformation from "../../components/account/PasswordInfo";
import ProfileCard from "../../components/account/ProfileCard";
import EmailPref from "../../components/account/EmailPreferences";
import SyncAccounts from "../../components/account/SyncAccounts";

const AccountPage = (): ReactElement => {
  return (
    <PrivateLayout>
      <Grid container spacing={2} className="p-2">
        {/* Left Column */}
        <Grid item xs={12} md={10}>
          <AccountInformation />

          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <PasswordInformation />
            </Grid>

            <Grid item xs={12} md={7}>
              <SyncAccounts />
            </Grid>
          </Grid>

          <Box mt={2}>
            <EmailPref />
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Profile Card that spans two rows */}
          <Box sx={{ flexGrow: 1, minHeight: "400px" }}>
            <ProfileCard />
          </Box>

          {/* Delete Account Button 
          <Box mt={6} textAlign="center" sx={{ mt: "auto" }}>
            <RoundedBox>
              <button className="text-red-500 font-bold">Delete Account</button>
            </RoundedBox>
          </Box>
          */}
        </Grid>
      </Grid>
    </PrivateLayout>
  );
};

export default AccountPage;
