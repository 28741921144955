import React, { useState, useEffect } from "react";
import { Edit, Close } from "@mui/icons-material";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import useUsers, { User, UserFilters } from "../../hooks/useUsers";
import FilterUsers from "./filters/FilterUsers";
import CustomTable from "../table/CustomTable";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Box,
} from "@mui/material";

const UsersTable: React.FC = () => {
  const { users, loading, error, fetchUsers, deleteUser, syncUsers } = useUsers();
  const navigate = useNavigate();

  const [filters, setFilters] = useState<UserFilters>({ search: "", role: "all" });
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  useEffect(() => {
    fetchUsers(filters);
  }, [filters, fetchUsers]);

  const handleEditUser = (id: number) => {
    const user = users.find((user) => user.id === id);
    if (user) {
      const { email } = user;
      navigate(`/admin-dashboard/user-management/edit-user/${id}`, { state: { email } });
    }
  };

  const handleDeleteUser = (id: number) => {
    setSelectedUserId(id);
    setDeleteError(null)
    setOpen(true);
  };

const handleConfirmDelete = async () => {
  if (selectedUserId !== null) {
    const { success, message } = await deleteUser(selectedUserId);
    if (success) {
      setOpen(false);
      setSelectedUserId(null);
    } else {
      setDeleteError(message);
    }
      fetchUsers(filters)
  }
};

  const handleCancelDelete = () => {
    setOpen(false);
    setSelectedUserId(null);
    setDeleteError(null);
  };

   // Helper function to process user data and apply filters
  const processUserData = (users: any[]) => {
    return users
      .map((user: any) => {
        // Process roles and other fields as before
        let roles = [...user.roles];
        if (roles.includes("attorney") && roles.includes("filer")) {
          roles = roles.filter((role: string) => role !== "filer");
        }
        if (roles.includes("admin")) {
          roles = ["admin"];
        }

        const formattedRoles = roles
          .filter((role: string) => role !== "service_contact")
          .map((role: string) => role.charAt(0).toUpperCase() + role.slice(1));

        // Add actions to each user
        const actions = [
          {
            label: "Edit User",
            onClick: (id: number) => handleEditUser(id),
            icon: <Edit className="text-[#5261A0]" />,
          },
          {
            label: "Delete User",
            onClick: (id: number) => handleDeleteUser(id),
            icon: <GridDeleteIcon className="text-[#5261A0]" />,
          },
        ];

        return {
          ...user,
          bar_number: user.bar_number || "N/A",
          name: `${user.first_name} ${user.middle_name || ""} ${user.last_name}`.trim(),
          service_contact: roles.includes("service_contact") ? "Yes" : "No",
          roles: formattedRoles,
          rolesArray: roles, // Store the roles array for filtering
          actions, // Add actions to each user
        };
      })
      .filter((user) => {
        // Apply role filtering based on the selected role
        if (filters.role === "all") return true;
        return user.rolesArray.includes(filters.role);
      });
  };

  // Process user data
  const processedRows = processUserData(users);

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email ID", width: 250 },
    {
      field: "service_contact",
      headerName: "Service Contact",
      width: 150,
    },
    { field: "bar_number", headerName: "Bar Number", width: 150 },
    {
      field: "roles",
      headerName: "Roles",
      width: 200,
    },
  ];

  // If there's an error in fetching users
  if (error) {
    return <div>Error: {error}</div>;
  }

  // If there are no users available
  if (!loading && users.length === 0) {
    return <div>No users found</div>;
  }

  return (
    <div>
      {/* Sync users */}
      <div className="w-full flex justify-end">
        <Button
          variant="outlined"
          sx={{
            border: "solid 1px #5261A0",
            backgroundColor: "white",
            marginBottom: "16px",
            color: "#5261A0",
            paddingX: "32px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#5261A0",
              color: "white",
            },
          }}
          onClick={syncUsers}
        >
          Sync Users
        </Button>
      </div>

      {/* Filters for user search and filtering */}
      <FilterUsers onChange={setFilters} />

      {/* CustomTable component to display users */}
      <CustomTable columns={columns} rows={processedRows} loading={loading} />

      {/* Delete confirmation dialog */}
      <Dialog open={open} onClose={handleCancelDelete}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCancelDelete}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="mt-10">
          <DialogContentText>
            Are you sure you want to <b>delete</b> the user account?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className="mb-8"
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box className="flex justify-center mb-2">
            <Button
              onClick={handleCancelDelete}
              sx={{
                backgroundColor: "#5261A0",
                color: "white",
                paddingX: "32px",
                borderRadius: "32px",
                marginRight: "16px",
                "&:hover": {
                  backgroundColor: "#405080",
                },
              }}
            >
              Cancel
            </Button>
              <Button
                onClick={handleConfirmDelete}
                disabled={Boolean(deleteError)}
                sx={{
                backgroundColor: deleteError ? "#808080" : "#F23131",
                  color: "white",
                  paddingX: "32px",
                  borderRadius: "32px",
                  "&:hover": {
                  backgroundColor: deleteError ? "#808080" : "#D92C2C",
                  },
                }}
              >
                Delete
              </Button>
          </Box>
           <DialogContentText color="error">{deleteError}</DialogContentText>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersTable;
