import { useState, useCallback } from "react";
import { useAuth } from "./auth";

// Updated User interface
export interface User {
  id: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  status?: string;
  is_service_contact?: number;
  barNumber?: string;
  role?: string;
  is_firm_admin?: number;
}

export type UserFilters = {
  search?: string;
  page?: number;
  itemsPerPage?: number;
  sortBy?: string;
};

const useUsers = () => {
  const { axios, getUserData } = useAuth({ middleware: 'auth' });
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    total: 0,
    perPage: 10,
    lastPage: 1
  });

  // Fetch users with optional filters
  const fetchUsers = useCallback(async (options?: UserFilters) => {
    setLoading(true);
    setError(null);

    try {
      const params: Record<string, any> = {
        ...options,
        sort_by: options?.sortBy || "id_desc",
        items_per_page: options?.itemsPerPage || 10,
        page: options?.page || 1,
      };

      const response = await axios.get(`/users`, { params });

      // Set users and pagination data from the response
      const { data, current_page, last_page, per_page, total } = response.data;
      setUsers(data);  // 'data' contains the actual user data
      setPagination({
        currentPage: current_page,
        total,
        perPage: per_page,
        lastPage: last_page,
      });
      setLoading(false);
    } catch (err: any) {
      const message = err.response?.data?.message || "Failed to fetch users";
      setError(message);
      setLoading(false);
    }
  }, [axios]);

// Fetch user by email
  const fetchUserByEmail = async (email: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/users`, { params: { email } }); // Adjust the API as per your requirement
      const user = response.data.data.find((user: User) => user.email === email);
      setLoading(false);
      return user;
    } catch (err: any) {
      setError("Failed to fetch user by email");
      setLoading(false);
      return null;
    }
  };

const createUser = async (newUser: {
  email: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
  barNumber?: number;
}) => {
  setLoading(true);
  setError(null);
  try {
    // Build the query string
    const queryParams = new URLSearchParams(newUser).toString();
    
    // Send POST request with query params
    const response = await axios.post(`/users/?${queryParams}`);
    
    const user = response.data;
    setUsers((prevUsers) => [...prevUsers, user]);
  } catch (err: any) {
    const message = err.response?.data?.message || "Failed to create user";
    setError(message);
  } finally {
    setLoading(false);
  }
};

const editUser = async (id: number, updatedUser: Partial<User>) => {
  setLoading(true);
  setError(null);
  try {
    const response = await axios.put(`/users/${id}`, updatedUser);
    const updatedUserData = response.data;

    setUsers((prevUsers) =>
      prevUsers.map((user) => (user.id === id ? { ...user, ...updatedUserData } : user))
    );
  } catch (err: any) {
    const message = err.response?.data?.message || "Failed to update user";
    setError(message);
  } finally {
    setLoading(false);
  }
};

const updateFirmAdminStatus = async (userId: number, isFirmAdmin: number) => {
  try {
    const response = await axios.put(`/users/${userId}?is_firm_admin=${isFirmAdmin}`);
    console.log({ response });
    return response.data.success;
  } catch (error) {
    console.error("Error updating firm admin status:", error);
  }
};

const deleteUser = async (id: number) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`/users`);
      const allUsers = response.data.data;
      const adminUsers = allUsers.filter((user: User) => user.roles.includes("admin"));
      const userToDelete = allUsers.find((user: User) => user.id === id);

      if (!userToDelete) {
        setLoading(false);
        return { success: false, message: "User not found." };
      }

      const isDeletingAdmin = userToDelete.roles.includes("admin");

      if (isDeletingAdmin && adminUsers.length <= 1) {
        setLoading(false);
        return { success: false, message: "Cannot delete the last admin user." };
      }

      await axios.delete(`/users/${id}`);
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));

      setLoading(false);
      return { success: true };
    } catch (err: any) {
      const message = err.response?.data?.message || err.message || "Failed to delete user";
      setLoading(false);
      return { success: false, message };
    }
};

const getEmailPreferences = async (userId: number) => {
  try {
    const response = await axios.get(`/users/${userId}/email_preferences`);
    const preferences = response.data;

    // Convert numeric values (0 or 1) to boolean using Boolean()
    return {
      // MyFileRunner Emails
      accepted: Boolean(preferences.accepted),
      rejected: Boolean(preferences.rejected),
      submitted: Boolean(preferences.submitted),
      
      serviceundeliverable: Boolean(preferences.serviceundeliverable),
      submissionfailed: Boolean(preferences.submissionfailed),
      receipted: Boolean(preferences.receipted),
      returnforcorrection: Boolean(preferences.returnforcorrection),
      accountlocked: Boolean(preferences.accountlocked),
      submittedbulksummary: Boolean(preferences.submittedbulksummary),
    };
  } catch (error) {
    console.error("Error getting email preferences:", error);
    return {
      submitted: false,
      accepted: false,
      rejected: false,
    }; // Return default values in case of an error
  }
};

  const updateEmailPreferences = async (userId: number, preferences: {
    submitted: boolean,
    accepted: boolean,
    rejected: boolean,
  }) => {
    try {
      // Convert preferences to query parameters
      const params = new URLSearchParams({
        accepted: preferences.accepted ? "1" : "0",
        rejected: preferences.rejected ? "1" : "0",
        submitted: preferences.submitted ? "1" : "0",
      }).toString();

      // Send POST request to the endpoint
      const response = await axios.post(`/users/${userId}/email_preferences?${params}`);
      
      if (response.status === 200) {
        console.log("Preferences saved successfully!");
      }
    } catch (error) {
      console.error("Error saving preferences:", error);
    }
  };
  
  const syncUsers = async () => {
    const profile= getUserData();
    if(!profile) {
      return;
    }
    if (!profile.firm.id) {
      console.error("Firm not found.");
      return;
    }
    try {
      await axios.get(`/firm/${profile.firm.id}/syncUsers`);
      fetchUsers();
    } catch (error) {
      console.error("Error syncing users:", error);
    }
  }

  return {
    users,
    loading,
    error,
    createUser,
    editUser,
    deleteUser,
    fetchUserByEmail,
    fetchUsers,
    pagination,
    updateEmailPreferences,
    getEmailPreferences,
    updateFirmAdminStatus,
    syncUsers,
  };
};

export default useUsers;
