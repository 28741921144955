import { createSlice } from "@reduxjs/toolkit"
import store from "../../store/store"

export const caseSearch = createSlice({
  name: 'caseSearch',
  initialState: {
    cases: [],
  },
  reducers: {
    setCases: (state: any, payload: { payload: any[] }) => {
      state.cases = payload.payload
    },
  },
})

// -------------------------------------------------

export const setCases = (cases: any[]) => {
  store.dispatch(caseSearch.actions.setCases(cases))
}

// -------------------------------------------------

export const selectCases = (state: any) => state.caseSearch.cases