import { ReactElement } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import LogInScreen from "../pages/auth/LogIn";
import RegisterScreen from "../pages/auth/Register";
import ForgotPasswordScreen from "../pages/auth/PasswordForgot";

import HomeScreen from "../pages/Home";
import StatisticsScreen from "../pages/Statistics";
import MyReportsScreen from "../pages/MyReports";
import MyEServicesScreen from "../pages/my-services/myEServices";

import NoMatch from "../components/NoMatch";
import { Loader } from "../components/assets/Loader";
import { MessageBoard } from "../components/assets/MessageBoard";
import PasswordResetScreen from "../pages/auth/PasswordReset";
import FileToCourtScreen from "../pages/cases/FileToCourt";
import { FileCaseScreen } from "../pages/cases/FileCase";
import AdminDashboard from "../pages/admin-dashboard/AdminDashboardPage";
import EditUserPage from "../pages/admin-dashboard/edit-user/EditUserPage";
import CreateUserPage from "../pages/admin-dashboard/create-user/CreateUserPage";
import PaymentAccountsPage from "../pages/admin-dashboard/PaymentAccounts";
import AccountPage from "../pages/my-account/AccountPage";
import MyFilingsScreen from "../pages/my-filings/MyFilings";
import CaseDetails from "../pages/my-filings/details/CaseDetailsPage";
import EServiceDetails from "../pages/my-services/details/EServiceDetailsPage";
import EServiceDraftEditPage from "../pages/my-services/details/EServiceDraftEditPage";
import { FileIntoExistingCaseScreen } from "../pages/cases/FileIntoExistingCase";
import { EServiceCaseSearchResultScreen, FileIntoExistingCaseSearchResultScreen } from "../pages/cases/CaseSearchIndex";
import { EServiceCaseDetailsScreen, FileIntoExistingCaseDetailsScreen } from "../pages/cases/CaseDetails";
import { EServiceScreen } from "../pages/cases/EService";

const RoutesWithAnimation = () => {
  const location = useLocation();

  return (
    <>
      <Routes location={location} key={`routes-${location.key}`}>
        <Route path="/login" element={<LogInScreen />}></Route>
        <Route path="/register" element={<RegisterScreen />}></Route>
        <Route path="/forgot-password" element={<ForgotPasswordScreen />} ></Route>
        <Route path="/reset-password" element={<PasswordResetScreen />}></Route>
        <Route path="/statistics" element={<StatisticsScreen />}></Route>
        <Route path="/my-filings" element={<MyFilingsScreen />}></Route>
        <Route path="/my-filings/:id?" element={<CaseDetails />}></Route>
        <Route path="/my-e-services" element={<MyEServicesScreen />}></Route>
        <Route path="/received-service/:id?" element={<EServiceDetails />}></Route>
        <Route path="/sent-service/:id?" element={<EServiceDetails />}></Route>
        <Route path="/edit-draft-service/:id?" element={<EServiceDraftEditPage />}></Route>
        <Route path="/my-reports" element={<MyReportsScreen />}></Route>
        <Route path="/file-to-court" element={<FileToCourtScreen />}></Route>
        <Route path="/file-case/:id?" element={<FileCaseScreen />}></Route>
        <Route path="/file-into-existing-case/search" element={<FileIntoExistingCaseSearchResultScreen />}></Route>
        <Route path="/file-into-existing-case/case-details/:id/:location_id?" element={<FileIntoExistingCaseDetailsScreen />}></Route>
        <Route path="/file-into-existing-case/:id" element={<FileIntoExistingCaseScreen />}></Route>
        <Route path="/e-service/search" element={<EServiceCaseSearchResultScreen />}></Route>
        <Route path="/e-service/case-details/:id/:location_id?" element={<EServiceCaseDetailsScreen />}></Route>
        <Route path="/e-service/:id" element={<EServiceScreen />}></Route>
        <Route
          path="/admin-dashboard/user-management"
          element={<AdminDashboard />}
        ></Route>
        <Route
          path="/admin-dashboard/user-management/edit-user/:id?"
          element={<EditUserPage />}
        ></Route>
        <Route
          path="/admin-dashboard/user-management/create-user"
          element={<CreateUserPage />}
        ></Route>
        <Route
          path="/admin-dashboard/payment-accounts"
          element={<PaymentAccountsPage />}
        ></Route>
        <Route path="/my-account" element={<AccountPage />}></Route>
        <Route path="/" element={<HomeScreen />}></Route>
        <Route path="*" element={<NoMatch />}></Route>
      </Routes>
    </>
  );
};

const AppRouter = (): ReactElement => {
  return (
    <BrowserRouter>
      <RoutesWithAnimation />
      <MessageBoard />
      <Loader />
    </BrowserRouter>
  );
};

export default AppRouter;
