import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import RoundedBox from '../../../components/layout/RoundedBox';
import BackArrow from '../../../components/BackArrow';
import PrivateLayout from '../../../components/layout/PrivateLayout';
import CaseUpdates from '../../../components/filings/details/CaseUpdates';
import FilingInformation from '../../../components/filings/details/FilingInformation';

interface CaseDetailsProps {
  caseNumber: string;
  acceptedAt: string;
  updates: { status: string; time: string }[];
  location: string;
  court: string;
  envelopeDraft: string;
  filingType: string;
  submissionTime: string;
  description: string;
}

const CaseDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [caseDetails, setCaseDetails] = useState<CaseDetailsProps | null>(null);

  useEffect(() => {
    // Mock data to simulate fetched case details
    const mockData: CaseDetailsProps = {
      caseNumber: '12345',
      acceptedAt: '2024-10-10 14:35',
      updates: [
        { status: 'Filed', time: '2024-10-08 09:00' },
        { status: 'Reviewed', time: '2024-10-09 11:15' },
        { status: 'Accepted', time: '2024-10-10 14:35' },
      ],
      location: 'New York County',
      court: 'Supreme Court | Civil Calendar',
      envelopeDraft: '98765',
      filingType: 'Electronic',
      submissionTime: '2024-10-08 08:45',
      description: 'Initial complaint for civil damages',
    };

    // Simulate an API call with a delay
      setCaseDetails(mockData);
  }, [id]);

  const handleGoBack = () => {
    navigate('/my-filings')
  }

  return (
    <PrivateLayout>
      <RoundedBox color="normal">
        <RoundedBox className="bg-white px-4 py-6 mb-4">
        <BackArrow onClick={handleGoBack}>
            <span className="tracking-wider">Back</span>
          </BackArrow>
        </RoundedBox>
        <CaseUpdates />
        <FilingInformation />
      </RoundedBox>
    </PrivateLayout>
  );
};

export default CaseDetails;
