import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import PaymentAccountForm from "../../pages/payment-accounts/PaymentAccountsForm";

interface AddPaymentDialogProps {
  open: boolean;
  onClose: () => void;
  onSave?: (account: any) => void;
}

const AddPaymentDialog: React.FC<AddPaymentDialogProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const handleSubmitSuccess = (account: any) => {
    if (onSave) {
      onSave(account);
    }
    onClose();
  };

  return (
    <Dialog open={open}>
      <div
          className="absolute right-6 top-6 hover:bg-gray-100 rounded-full p-2"
      >
        <IconButton
          onClick={onClose}
        >
          <Close className="h-6 w-6 text-[#5261A0]" />
        </IconButton>
      </div>
      <div className="p-12 w-[500px]">
        <DialogTitle className="text-[#5261A0] text-3xl p-8 relative">
          Add New Payment Account
      </DialogTitle>
      <DialogContent className="p-8">
        <PaymentAccountForm 
          onSubmitSuccess={handleSubmitSuccess}
        />
      </DialogContent>
      </div>
    </Dialog>
  );
};

export default AddPaymentDialog;
