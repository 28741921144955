import { useState, ReactElement, useEffect } from "react";
import { Edit, Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import RoundedBox from "../layout/RoundedBox";
import SmallTextField from "../Inputs/SmallInput";
import useAccount from "../../hooks/useAccount";
import { useMessageBoard } from "../assets/MessageBoard";

const styles = {
  row: "grid grid-cols-12 gap-4 h-[22px] items-center",
  label: "col-span-4 font-bold text-sm leading-[22px]",
  content: "col-span-8 h-full",
  text: "text-sm leading-[22px]",
  header: "flex justify-between items-center mb-4",
  title: "text-lg font-semibold text-[#5261A0]",
  buttonGroup: "space-x-2",
  editButton: "text-[#5261A0]",
  cancelButton: "text-gray-500 font-bold px-4 py-2 rounded",
  saveButton: "text-[#5261A0] font-bold px-4 py-2 rounded",
  formContainer: "space-y-4"
};

interface FormRowProps {
  label: string;
  value: string;
  name: string;
  isEditing: boolean;
  type?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const FormRow = ({ label, value, name, isEditing, type = "text", onChange, onKeyDown }: FormRowProps) => {
  const renderContent = () => {
    if (isEditing) {
      return (
        <SmallTextField
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      );
    }
    
    return <p className={styles.text}>{type === "password" ? "••••••••" : value}</p>;
  };

  return (
    <div className={styles.row}>
      <div className={styles.label}>{label}</div>
      <div className={styles.content}>
        {renderContent()}
      </div>
    </div>
  );
};

const PasswordBox = () => {
  const { loading, changePassword } = useAccount();
  const { addMessageSuccess, addMessageError } = useMessageBoard();
  const [isEditing, setIsEditing] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [passwordData, setPasswordData] = useState({
    current_password: "",
    new_password: "",
    new_password_confirmation: ""
  });

  useEffect(() => {
    let timer;
    if (showDialog && countdown > 0) {
      timer = setInterval(() => setCountdown(c => c - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [showDialog, countdown]);

  const handleInputChange = (e) => {
    setPasswordData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSave();
    }
  };

  const handleCancel = () => {
    setPasswordData({
      current_password: "",
      new_password: "",
      new_password_confirmation: ""
    });
    setIsEditing(false);
  };

  const validatePassword = () => {
    if (!passwordData.current_password || !passwordData.new_password || !passwordData.new_password_confirmation) {
      addMessageError('All password fields are required');
      return false;
    }
    if (passwordData.new_password !== passwordData.new_password_confirmation) {
      addMessageError('New passwords do not match');
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (validatePassword()) {
      setShowDialog(true);
    }
  };

  const handleContinue = async () => {
    try {
      const success = await changePassword(passwordData);
      if (success) {
        addMessageSuccess('Password updated successfully');
        window.location.href = '/login';
      } else {
        addMessageError('Failed to update password');
        setShowDialog(false);
      }
    } catch (err) {
      addMessageError(err instanceof Error ? err.message : 'An unexpected error occurred');
      setShowDialog(false);
    }
  };

  const renderWarningDialog = () => (
    <Dialog open={showDialog}>
      <div className="absolute right-6 top-6 hover:bg-gray-100 rounded-full p-2">
        <button onClick={() => setShowDialog(false)}>
          <Close className="h-6 w-6 text-[#5261A0]" />
        </button>
      </div>
      <div className="p-12 w-[500px]">
        <DialogTitle className="text-[#5261A0] text-3xl p-8">
          Warning
        </DialogTitle>
        <DialogContent className="p-8">
          <p className="text-gray-600 mb-8">
            Once you change your password you will need to log in again with your new password.
          </p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setShowDialog(false)}
              className="text-gray-500 font-bold px-6 py-2 rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              onClick={handleContinue}
              disabled={countdown > 0}
              className="text-[#5261A0] font-bold px-6 py-2 rounded hover:bg-blue-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Continue {countdown > 0 && `(${countdown}s)`}
            </button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );

  const renderEditButton = () => (
    <div className={styles.buttonGroup}>
      {!isEditing ? (
        <button 
          className={styles.editButton}
          onClick={() => setIsEditing(true)}
          disabled={loading}
        >
          <Edit className="h-5 w-5" />
        </button>
      ) : (
        <div className="h-5">
          <button
            className={styles.cancelButton}
            onClick={handleCancel}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className={styles.saveButton}
            onClick={handleSave}
            disabled={loading}
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );

  const renderPasswordFields = () => {
    if (isEditing) {
      return (
        <>
          <FormRow
            label="Current Password:"
            value={passwordData.current_password}
            name="current_password" 
            type="password"
            isEditing={true}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <FormRow
            label="New Password:"
            value={passwordData.new_password}
            name="new_password"
            type="password"
            isEditing={true}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <FormRow
            label="Confirm Password:"
            value={passwordData.new_password_confirmation}
            name="new_password_confirmation"
            type="password"
            isEditing={true}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </>
      );
    }

    return (
      <FormRow
        label="Password:"
        value="••••••••"
        name="password"
        type="password"
        isEditing={false}
        onChange={() => {}}
      />
    );
  };

  return (
    <div className="h-full min-h-40">
      {renderWarningDialog()}
      
      <div className={styles.header}>
        <h2 className={styles.title}>Password Information:</h2>
        {renderEditButton()}
      </div>

      <div className={styles.formContainer}>
        {renderPasswordFields()}
      </div>
    </div>
  );
};

const PasswordInformation = (): ReactElement => {
  return (
    <RoundedBox className="bg-white">
      <PasswordBox />
    </RoundedBox>
  );
};

export default PasswordInformation;
