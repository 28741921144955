import { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import useAccount from "../../hooks/useAccount";
import { useMessageBoard } from "../assets/MessageBoard";
import { Button, Input } from "@mui/base";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import RoundedBox from "../layout/RoundedBox";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required"),
});

const SyncAccounts = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { loading, syncTylerAccount } = useAccount();
  const { addMessageSuccess, addMessageError } = useMessageBoard();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const success = await syncTylerAccount(values);
      if (success) {
        addMessageSuccess("Successfully synced with Tyler account");
        setIsOpen(false);
        resetForm();
      }
    } catch (err) {
      addMessageError(err instanceof Error ? err.message : 'Failed to sync Tyler account');
    }
  };

  return (
    <RoundedBox className="bg-white min-h-40 h-full">
      <h2 className="text-lg font-semibold text-[#5261A0] mb-4">
        Sync Account:
      </h2>
      <p className="text-gray-600 mb-4 text-md">
        To sync your account with other services, you may need to re-enter your user credentials and password for verification.
      </p>
      <Button 
        onClick={() => setIsOpen(true)}
        className="text-md mb-2 px-4 py-2 bg-[#5261A0] hover:bg-indigo-800 text-white rounded-lg transition-colors"
        disabled={loading}
      >
        Sync eFileCA
      </Button>

      <Dialog open={isOpen}>
        <div className="absolute right-6 top-6 hover:bg-gray-100 rounded-full p-2">
          <IconButton onClick={() => setIsOpen(false)}>
            <Close className="h-6 w-6 text-[#5261A0]" />
          </IconButton>
        </div>
        <div className="p-12 w-[500px]">
          <DialogTitle className="text-[#5261A0] text-3xl p-8">
            Sync eFileCA
          </DialogTitle>
          <DialogContent className="p-8">
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form className="space-y-4">
                  <div className="space-y-2">
                    <Field
                      name="email"
                      placeholder="Email"
                      className="w-full p-2 border rounded outline-none focus:border-[#5261A0]"
                    />
                    {errors.email && touched.email && (
                      <div className="text-red-500 text-sm">
                        {errors.email}
                      </div>
                    )}
                  </div>

                  <div className="space-y-2">
                    <Field
                      type="password"
                      name="password"
                      placeholder="Password"
                      className="w-full p-2 border rounded outline-none focus:border-[#5261A0]"
                    />
                    {errors.password && touched.password && (
                      <div className="text-red-500 text-sm">
                        {errors.password}
                      </div>
                    )}
                  </div>

                  <div className="flex justify-end space-x-4 pt-4">
                    <Button
                      onClick={() => setIsOpen(false)}
                      className="text-gray-500 font-bold px-6 py-2 rounded hover:bg-gray-100"
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="bg-[#5261A0] text-white font-bold px-6 py-2 rounded hover:bg-blue-50"
                      disabled={loading}
                    >
                      {loading ? "Syncing..." : "Sync"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </div>
      </Dialog>
    </RoundedBox>
  );
};

export default SyncAccounts;
