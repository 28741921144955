import { ReactElement, useEffect, useState } from "react";

const FormLabel = ({ children, className, mandatory = false, ...props }: {children:any, className?: string, mandatory?: boolean}): ReactElement => {

  return <label {...{
    ...props,
    className: `block font-normal text-base-gray mb-2 ${className ? className : ''}`
  }}>
    {children}
    {
      !!mandatory && <>
        {' '}<span className="text-red-700 font-bold relative bottom-1">*</span>
      </>
    }
  </label>
}
export default FormLabel;