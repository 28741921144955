import { ReactElement, useEffect, useState } from "react";
import FormError from "./FormError";

const FormTextarea = ({ formik, name, ...props }: any): ReactElement => {

  return <>
    <textarea
      {...{
        ...props,
        name,
        rows: props.rows ? props.rows : 5,
        onChange: formik?.handleChange,
        onBlur: formik?.handleBlur,
        value: formik?.values[name],
        className: "p-2 w-full border rounded-md tracking-wider hover:border-gray-300 focus:outline-none focus:border-gray-300" + (props.className ? ` ${props.className}` : '')
      }}
    />
    <FormError formik={formik} name={name} />
  </>
}
export default FormTextarea;