import { ArrowRight } from "@mui/icons-material";
import { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectAuth, useAuth } from "../hooks/auth";
import { Profile } from "../repositories/Models";

const UserMenu = (): ReactElement => {
  const user: Profile = useSelector(selectAuth);

  if(!user) {
    return <></>
  }
  return <NavLink to="/my-account">
    <div className="rounded-xl bg-[#CED4FB] hover:bg-background-overlay-blue flex items-center gap-4 cursor-pointer h-[56px]">
      <div className="flex-initial w-1/4 flex items-center justify-center">
        <img
          src={user?.thumb_url ? user?.thumb_url : "/images/no-user.svg"}
          className="rounded-full h-[41px] w-[41px]"
          alt="User Avatar"
        />
      </div>
      <div className="flex-1 text-black max-w-[100px]">
        <div className="truncate text-sm font-bold">
          {`${user?.user.first_name} ${user.user.last_name}`}
        </div>
        <div className="truncate mt-2 text-black">
          {user?.user.roles.map((rol: any) => rol.charAt(0).toUpperCase() + rol.slice(1)).join(', ')}
        </div>
      </div>
      <div className="flex-initial w-1/6 flex items-center justify-center text-black">
        <ArrowRight />
      </div>
    </div>
  </NavLink>
};

export default UserMenu;
