import { ReactElement, useState } from "react";
import FormError from "./FormError";

const FormPassword = ({ name, formik, ...props }: any): ReactElement => {
  const [showPassword, setShowPassword] = useState(false);

  return <>
    <div className="relative">
      <input
        {...{
          ...props,
          name,
          onChange: formik?.handleChange,
          onBlur: formik?.handleBlur,
          value: formik?.values[name],
          className: "p-2 w-full border text-base rounded-md tracking-wider hover:border-gray-300 focus:outline-none focus:border-gray-300" + (props.className ? ` ${props.className}` : ''),
          type: showPassword ? "text" : "password"
        }}
      />
      <button
        type="button"
        className="w-7 h-7 absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer outline-none text-base-gray"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        { showPassword && <img className="w-full h-full" src='/images/eye-regular.svg' /> }
        {!showPassword && <img className="w-full h-full" src='/images/eye-noregular.svg' />}
      </button>
    </div>
    <FormError formik={formik} name={name} />
  </>
}
export default FormPassword;