import { useState, useCallback, useEffect } from 'react';
import { PartialRecord, toQueryParams } from '../utils/utils';
import { useAuth } from './auth';

// Types
interface User {
  first_name: string;
  middle_name: string | null;
  last_name: string;
  email: string;
  bar_number: string | null;
  roles: string[];
  profile_photo: string;
}

export interface Firm {
  id: number;
  name: string;
  domain: string | null;
  phone: string;
  street_address: string;
  street_address_2: string | null;
  city: string;
  state: string;
  zip_code: string;
}

interface Password {
  question: string | null;
  has_answer: boolean;
}

interface AllowedPermissions {
  accepted_tnc: number;
  allow_admin: number;
  allow_file: number;
}

interface EmailPreferences {
  accepted: number;
  rejected: number;
  serviceundeliverable: number;
  submitted: number;
  submissionfailed: number;
  receipted: number;
  returnforcorrection: number;
  accountlocked: number;
  submittedbulksummary: number;
}

interface LinkedAccount {
  provider: string;
  token_expires_at: string | null;
  active: boolean;
}

export interface ProfileResponse {
  user: User;
  firm: Firm;
  password: Password;
  allowed_permissions: AllowedPermissions;
  filing_frequency: string | null;
  email_preferences: EmailPreferences;
  linked_accounts: LinkedAccount[];
}

interface PasswordChangeParams {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
}

interface SecurityQuestionParams {
  security_question: string;
  security_answer: string;
}

interface TylerSyncParams {
  email: string;
  password: string;
}

const useAccount = () => {
  const { axios } = useAuth({ middleware: 'auth' });
  const [profile, setProfile] = useState<ProfileResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchProfile = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('/profile');
      console.log({response})
      setProfile(response.data);
    } catch (err: any) {
      const message = err.response?.data?.message || 'Failed to fetch profile';
      setError(message);
      console.log({err})
      setProfile(null);
    } finally {
      setLoading(false);
    }
  }, [axios]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const updateProfile = async (updatedData: Partial<ProfileResponse>) => {
    setLoading(true);
    setError(null);

    try {
      await axios.put('/profile', updatedData);
      fetchProfile();
      return true;
    } catch (err: any) {
      const message = err.response?.data?.message || 'Failed to update profile';
      setError(message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const updateFirmDetails = async (
    firmData: PartialRecord<Firm>
  ) => {
    setLoading(true);
    setError(null);

    try {
      await axios.put(`profile/firm/${toQueryParams(firmData)}`);
      await fetchProfile();
      return true;
    } catch (err: any) {
      const message = err.response?.data?.message || 'Failed to update firm details';
      setError(message);
      return false;
    } finally {
      setLoading(false);
    }
  };

const updateUserDetails = async (
    userData: PartialRecord<User>
  ) => {
    setLoading(true);
    setError(null);

    try {
      await axios.put(`/profile${toQueryParams(userData)}`);
      await fetchProfile();
      return true;
    } catch (err: any) {
      const message = err.response?.data?.message || 'Failed to update user details';
      setError(message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const changePassword = async (params: PasswordChangeParams) => {
    setLoading(true);
    setError(null);

    try {
      const queryString = toQueryParams({
        current_password: params.current_password,
        new_password: params.new_password,
        new_password_confirmation: params.new_password_confirmation
      });
      
      const response = await axios.put(`/profile/password${queryString}`);
      console.log({response})
      return true;
    } catch (err: any) {
      const message = err.response?.data?.message || 'Failed to change password';
      setError(message);
      return false;
    } finally {
      setLoading(false);
    }
  };
  
  const updateEmailPreferences = async (preferences: PartialRecord<EmailPreferences>) => {
    setLoading(true);
    setError(null);

    try {
      // Convert the preferences object into query parameters
      const queryParams = Object.entries(preferences).map(([key, value]) => {
        // Ensure the value is converted to 0 or 1
        const numericValue = value ? 1 : 0;
        return `${key}=${numericValue}`;
      }).join('&');

      await axios.put(`/profile/email-preferences?${queryParams}`);
      
      // Update the local profile state with the new preferences
      await fetchProfile();


      return true;
    } catch (err: any) {
      const message = err.response?.data?.message || 'Failed to update email preferences';
      setError(message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Helper method to get current email preferences
  const getEmailPreferences = useCallback((): EmailPreferences => {
    return profile?.email_preferences || {
      accepted: 0,
      rejected: 0,
      serviceundeliverable: 0,
      submitted: 0,
      submissionfailed: 0,
      receipted: 0,
      returnforcorrection: 0,
      accountlocked: 0,
      submittedbulksummary: 0
    };
  }, [profile]);

  const uploadProfilePhoto = async (base64Image: string) => {
  setLoading(true);
  setError(null);

  try {
    await axios.post('/profile/photo', {
      photo: `data:image/png;base64,${base64Image}`
    });
    await fetchProfile();
    
    return true;
  } catch (err: any) {
    const message = err.response?.data?.message || 'Failed to upload profile photo';
    setError(message);
    return false;
  } finally {
    setLoading(false);
  }
};

const getProfilePhotoUrl = (photoPath: string | null | undefined) => {
  if (!photoPath) return null;
  
  const baseURL = axios.defaults.baseURL || '';
  return `${baseURL}${photoPath}`;
};

const syncTylerAccount = async (params: TylerSyncParams) => {
  setLoading(true);
  setError(null);

  try {
    await axios.put(`/profile/linked-accounts/tyler?email=${encodeURIComponent(params.email)}&password=${encodeURIComponent(params.password)}`);
    await fetchProfile();
    return true;
  } catch (err: any) {
    const message = err.response?.data?.message || 'Failed to sync Tyler account';
    setError(message);
    return false;
  } finally {
    setLoading(false);
  }
};

  return {
    profile,
    loading,
    error,
    fetchProfile,
    updateProfile,
    updateFirmDetails,
    updateUserDetails,
    updateEmailPreferences,
    getEmailPreferences,
    changePassword,
    uploadProfilePhoto,
    getProfilePhotoUrl,
    syncTylerAccount,
  };
};

export default useAccount;
