import { useState, ReactElement, useEffect } from "react";
import RoundedBox from "../layout/RoundedBox";
import SmallTextField from "../Inputs/SmallInput";
import useAccount from "../../hooks/useAccount";
import { useMessageBoard } from "../assets/MessageBoard";
import { Close, Edit } from "@mui/icons-material";
import { useAuth } from "../../hooks/auth";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

interface FirmData {
  name: string;
  phone: string;
  street_address: string;
  street_address_2: string | null;
  city: string;
  state: string;
  zip_code: string;
}

interface UserData {
  first_name: string;
  middle_name: string | null;
  last_name: string;
  email: string;
}

const styles = {
  row: "grid grid-cols-12 gap-4 h-[22px] items-center",
  label: "col-span-4 font-bold text-sm leading-[22px]",
  content: "col-span-8 h-full",
  text: "text-sm leading-[22px]",
  header: "flex justify-between items-center mb-4",
  title: "text-lg font-semibold text-[#5261A0]",
  buttonGroup: "space-x-2",
  editButton: "text-[#5261A0]",
  cancelButton: "text-gray-500 font-bold px-4 py-2 rounded",
  saveButton: "text-[#5261A0] font-bold px-4 py-2 rounded",
  formContainer: "space-y-4"
};

const Skeleton = () => (
  <div className="h-4 bg-gray-200 rounded animate-pulse" />
);

interface FormRowProps {
  label: string;
  value: string | null;
  name: string;
  isEditing: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
}

const FormRow = ({ label, value, name, isEditing, onChange, onKeyDown, isLoading }: FormRowProps) => {
  const renderContent = () => {
    if (isLoading) return <Skeleton />;
    
    if (isEditing) {
      return (
        <SmallTextField
          name={name}
          value={value || ''}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      );
    }
    
    return <p className={styles.text}>{value || '-'}</p>;
  };

  return (
    <div className={styles.row}>
      <div className={styles.label}>{label}</div>
      <div className={styles.content}>
        {renderContent()}
      </div>
    </div>
  );
};

const FirmInformation = ({ canEdit = false }: { canEdit?: boolean }) => {
  const { profile, loading, updateFirmDetails } = useAccount();
  const { addMessageSuccess, addMessageError } = useMessageBoard();
  const [isEditing, setIsEditing] = useState(false);
  const [localData, setLocalData] = useState<FirmData | null>(null);
  
  useEffect(() => {
    if (profile?.firm) {
      setLocalData(profile.firm);
    }
  }, [profile?.firm]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocalData(prev => prev ? {
      ...prev,
      [name]: value
    } : null);
  };

  const handleCancel = () => {
    setLocalData(profile?.firm || null);
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSave();
    }
  };

  const handleSave = async () => {
    if (!localData) return;

    if (!localData.name?.trim()) {
      addMessageError('Firm name is required');
      return;
    }

    try {
      const success = await updateFirmDetails(localData);
      if (success) {
        addMessageSuccess('Firm information updated successfully');
        setIsEditing(false);
      } else {
        addMessageError('Failed to update firm information');
      }
    } catch (err) {
      addMessageError(err instanceof Error ? err.message : 'An unexpected error occurred');
    }
  };

  return (
    <div className="h-full">
      <div className={styles.header}>
        <h2 className={styles.title}>Firm Information:</h2>
        {canEdit && (
          <div className={styles.buttonGroup}>
            {!isEditing ? (
              <button 
                className={styles.editButton}
                onClick={() => setIsEditing(true)}
                disabled={loading}
              >
                <Edit className="h-5 w-5" />
              </button>
            ) : (
              <div className="h-5">
                <button
                  className={styles.cancelButton}
                  onClick={handleCancel}
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  className={styles.saveButton}
                  onClick={handleSave}
                  disabled={loading}
                >
                  Save Changes
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.formContainer}>
        <FormRow
          label="Firm Name:"
          value={localData?.name || null}
          name="name"
          isEditing={isEditing}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          isLoading={loading}
        />
        <FormRow
          label="Phone Number:"
          value={localData?.phone || null}
          name="phone"
          isEditing={isEditing}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          isLoading={loading}
        />
        <FormRow
          label="Street Address:"
          value={localData?.street_address || null}
          name="street_address"
          isEditing={isEditing}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          isLoading={loading}
        />
        <FormRow
          label="Address Line 2:"
          value={localData?.street_address_2 || null}
          name="street_address_2"
          isEditing={isEditing}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          isLoading={loading}
        />
        <FormRow
          label="City:"
          value={localData?.city || null}
          name="city"
          isEditing={isEditing}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          isLoading={loading}
        />
        <FormRow
          label="State:"
          value={localData?.state || null}
          name="state"
          isEditing={isEditing}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          isLoading={loading}
        />
        <FormRow
          label="Zip Code:"
          value={localData?.zip_code || null}
          name="zip_code"
          isEditing={isEditing}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          isLoading={loading}
        />
      </div>
    </div>
  );
};

const PersonalInformation = () => {
  const { profile, loading, updateUserDetails, logout } = useAccount();
  const { addMessageSuccess, addMessageError } = useMessageBoard();
  const [isEditing, setIsEditing] = useState(false);
  const [localData, setLocalData] = useState<UserData | null>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (profile?.user) {
      setLocalData(profile.user);
    }
  }, [profile?.user]);

  useEffect(() => {
    let timer;
    if (showDialog && countdown > 0) {
      timer = setInterval(() => setCountdown(c => c - 1), 1000);
    }
    return () => clearInterval(timer);
  }, [showDialog, countdown]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocalData(prev => prev ? {
      ...prev,
      [name]: value
    } : null);
  };

  const handleCancel = () => {
    setLocalData(profile?.user || null);
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSave();
    }
  };

  const handleSave = async () => {
    if (!localData) return;

    if (!localData.first_name?.trim() || !localData.last_name?.trim()) {
      addMessageError('First name and last name are required');
      return;
    }

    // Check if email has changed
    if (localData.email !== profile?.user.email) {
      setShowDialog(true);
      return;
    }

    await saveUserData();
  };

  const saveUserData = async () => {
    if (!localData) return;
    
    try {
      const success = await updateUserDetails(localData);
      if (success) {
        addMessageSuccess('Personal information updated successfully');
        setIsEditing(false);
        setShowDialog(false);
        
        // If email was changed, log out the user
        if (localData.email !== profile?.user.email) {
          addMessageSuccess('Your email has been updated. Please log in with your new email.');
          setTimeout(() => {
            logout();
          }, 2000);
        }
      } else {
        addMessageError('Failed to update personal information');
        setShowDialog(false);
      }
    } catch (err) {
      addMessageError(err instanceof Error ? err.message : 'An unexpected error occurred');
      setShowDialog(false);
    }
  };

  const renderWarningDialog = () => (
    <Dialog open={showDialog}>
      <div className="absolute right-6 top-6 hover:bg-gray-100 rounded-full p-2">
        <button onClick={() => setShowDialog(false)}>
          <Close className="h-6 w-6 text-[#5261A0]" />
        </button>
      </div>
      <div className="p-12 w-[500px]">
        <DialogTitle className="text-[#5261A0] text-3xl p-8">
          Warning
        </DialogTitle>
        <DialogContent className="p-8">
          <p className="text-gray-600 mb-8">
            Changing your email address will require you to log out and log back in with your new email address.
          </p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setShowDialog(false)}
              className="text-gray-500 font-bold px-6 py-2 rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              onClick={saveUserData}
              disabled={countdown > 0}
              className="text-[#5261A0] font-bold px-6 py-2 rounded hover:bg-blue-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Continue {countdown > 0 && `(${countdown}s)`}
            </button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );

  return (
    <div className="h-full">
      {renderWarningDialog()}
      
      <div className={styles.header}>
        <h2 className={styles.title}>Personal Information:</h2>
        <div className={styles.buttonGroup}>
          {!isEditing ? (
            <button 
              className={styles.editButton}
              onClick={() => setIsEditing(true)}
              disabled={loading}
            >
              <Edit className="h-5 w-5" />
            </button>
          ) : (
            <div className="h-5">
              <button
                className={styles.cancelButton}
                onClick={handleCancel}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                className={styles.saveButton}
                onClick={handleSave}
                disabled={loading}
              >
                Save Changes
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.formContainer}>
        <FormRow
          label="First Name:"
          value={localData?.first_name || null}
          name="first_name"
          isEditing={isEditing}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          isLoading={loading}
        />
        <FormRow
          label="Middle Name:"
          value={localData?.middle_name || null}
          name="middle_name"
          isEditing={isEditing}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          isLoading={loading}
        />
        <FormRow
          label="Last Name:"
          value={localData?.last_name || null}
          name="last_name"
          isEditing={isEditing}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          isLoading={loading}
        />
        <FormRow
          label="E-mail:"
          value={localData?.email || null}
          name="email"
          isEditing={isEditing}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          isLoading={loading}
        />
      </div>
    </div>
  );
};

const AccountInformation = (): ReactElement => {
  const { profile } = useAccount();
  
  const userRole = profile?.user.roles[0];

  const canEdit = () => {
    if (userRole === 'admin') return true;
    return false;
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
      <RoundedBox className="bg-white">
        <FirmInformation canEdit={canEdit()} />
      </RoundedBox>
      <RoundedBox className="bg-white">
        <PersonalInformation />
      </RoundedBox>
    </div>
  );
};

export default AccountInformation;
