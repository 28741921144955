import { useFormik } from "formik";
import { ReactElement, createRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from 'yup';
import UserAccessLayout from "../../components/layout/UserAccessLayout";
import FormError from "../../components/forms/FormError";
import FormLabel from "../../components/forms/FormLabel";
import Dialog from "../../components/dialogs/Dialog";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import FormPassword from "../../components/forms/FormPassword";
import DialogCloseable from "../../components/dialogs/DialogCloseable";
import { createSlice } from "@reduxjs/toolkit";
import store from "../../store/store";
import { useSelector } from "react-redux";
import FormSelect from "../../components/forms/FormSelect";
import { useAuth } from "../../hooks/auth";
import { useMessageBoard } from "../../components/assets/MessageBoard";
import FormInput from "../../components/forms/FormInput";
import { FormButton } from "../../components/forms/FormButton";
import { VALIDATION_PASSWORD, VALIDATION_PHONE, VALIDATION_ZIP_CODE } from "../../utils/utils";

const TYPE_FIRM_ADMINISTRATOR = 'Firm Administrator'
const TYPE_INDIVIDUAL_USER = 'Individual User'

// --------------------------------------------

export const registrationSlice = createSlice({
  name: 'registration',
  initialState: {
    firms: [],
    filingFrequencies: [],
    securityQuestionDialogOpen: false,
    authorizationDialogOpen: false
  },
  reducers: {
    setFirms: (state: any, payload: { payload: any[] }) => {
      state.firms = payload.payload
    },
    setFilingFrequencies: (state: any, payload: { payload: any[] }) => {
      state.filingFrequencies = payload.payload
    },
    setSecurityQuestionDialogOpen: (state: any, payload: { payload: boolean }) => {
      state.securityQuestionDialogOpen = payload.payload
    },
    setAuthorizationDialogOpen: (state: any, payload: { payload: boolean }) => {
      state.authorizationDialogOpen = payload.payload
    },
  },
})

const setFirms = (firms: any[]) => {
  store.dispatch(registrationSlice.actions.setFirms(firms))
}

const setFilingFrequencies = (filingFrequencies: any[]) => {
  store.dispatch(registrationSlice.actions.setFilingFrequencies(filingFrequencies))
}

const setSecurityQuestionDialogOpen = (open: boolean) => {
  store.dispatch(registrationSlice.actions.setSecurityQuestionDialogOpen(open))
}

const setAuthorizationDialogOpen = (open: boolean) => {
  store.dispatch(registrationSlice.actions.setAuthorizationDialogOpen(open))
}

const selectFirms = (state: any) => state.registration.firms

const selectFilingFrequencies = (state: any) => state.registration.filingFrequencies

const selectSecurityQuestionDialogOpen = (state: any) => state.registration.securityQuestionDialogOpen

const selectAuthorizationDialogOpen = (state: any) => state.registration.authorizationDialogOpen

// --------------------------------------------

const RegisterFormSelectUserType = ({ formik, formWizardRef, ...props }: any): ReactElement => {

  const firms = useSelector(selectFirms);

  const handleOptionSelect = (option: string) => {
    formik.setFieldValue('userType', option)
  };
  // -----
  const handleSelectFirm = () => {
    formWizardRef.current?.nextTab()
  };

  const handleCreateFirm = () => {
    setFirms([]);
    formWizardRef.current?.nextTab()
  };

  const handleChangeEmail = () => {
    setFirms([]);
    formik.setFieldValue('email', '');
    formik.setTouched({ email: false });
    formWizardRef.current?.reset()
  };
  // -----

  return <div>
    <div className="mb-6">
      <FormLabel mandatory>
        Are you affiliated with a firm or would you like to register as
        an individual user?
      </FormLabel>
      <input
        id="userType"
        name="userType"
        type="text"
        required
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.userType}
        style={{ display: 'none' }}
      />
      <div className="flex flex-col space-y-8">
        <button
          type="button"
          className={`text-base-gray px-4 py-6 rounded shadow-left-and-bottom hover:border-selected-blue focus:outline-none border-2 ${formik.values.userType === TYPE_FIRM_ADMINISTRATOR
            ? "border-selected-blue"
            : "border-white"
            }`}
          onClick={(e) => { handleOptionSelect(TYPE_FIRM_ADMINISTRATOR) }}
        >
          Firm Administrator
        </button>
        <button
          type="button"
          className={`text-base-gray px-4 py-6 rounded shadow-left-and-bottom hover:border-selected-blue focus:outline-none border-2
            ${formik.values.userType === TYPE_INDIVIDUAL_USER
              ? "border-selected-blue"
              : "border-white"
            }`}
          onClick={(e) => { handleOptionSelect(TYPE_INDIVIDUAL_USER) }}
        >
          Individual User
        </button>
      </div>
      <FormError formik={formik} name="userType" />
    </div>

    <div className="mb-6">
      <FormLabel mandatory>
        Email
      </FormLabel>
      <FormInput
        name="email"
        type="email"
        autoComplete="email"
        placeholder="Enter your email"
        required
        formik={formik}
      />
    </div>
    {
      formik.values.userType == TYPE_FIRM_ADMINISTRATOR && <Dialog
        title="Attention"
        open={firms?.length > 0}
        buttons={[{
          text: 'Select firm',
          action: handleSelectFirm
        }, {
          text: 'Create new firm',
          action: handleCreateFirm
        }, {
          text: 'Change Email',
          action: handleChangeEmail
        }]}
      >
        Your email domain is associated with a registered firm. You have three options:
        <ol className="list-decimal list-inside ml-4">
          <li>Select your firm from the available choices.</li>
          <li>Alternatively, if you don't want to be associated with the registered firm and prefer to create a new one, you can do that too. Please note that other members of the existing firm will not have access to your files.</li>
          <li>Change the email you entered.</li>
        </ol>
      </Dialog>
    }
    {
      formik.values.userType == TYPE_INDIVIDUAL_USER && <Dialog
        title="Attention"
        open={firms?.length > 0}
        buttons={[{
          text: 'Select firm',
          action: handleSelectFirm
        }, {
          text: 'Change Email',
          action: handleChangeEmail
        }]}
      >
        Your email domain is associated with a registered firm. You have two options:
        <ol className="list-decimal list-inside ml-4">
          <li>Register as a member of the existing firm.</li>
          <li>Change the email you entered.</li>
        </ol>
      </Dialog>
    }
  </div>
}

// --------------------------------------------

const RegisterFormPersonalInfo = ({ formik, formWizardRef, ...props }: any): ReactElement => {

  const firms = useSelector(selectFirms)
  const securityQuestionDialogOpen = useSelector(selectSecurityQuestionDialogOpen)
  const [backUpSecurityQuestionData, setBackUpSecurityQuestionData] = useState({
    security_question: '',
    security_answer: ''
  });

  const handleSecurityQuestionDialogOpen = () => {
    setBackUpSecurityQuestionData({
      security_question: formik.values.security_question,
      security_answer: formik.values.security_answer
    })
    setSecurityQuestionDialogOpen(true);
  }

  const handleSecurityQuestionDialogClose = () => {
    formik.setFieldValue('security_question', backUpSecurityQuestionData.security_question);
    formik.setFieldValue('security_answer', backUpSecurityQuestionData.security_answer);
    setSecurityQuestionDialogOpen(false);
  }

  const handleSecurityQuestionDialogConfirm = async () => {
    const errors = await formik.setTouched({ security_question: true, security_answer: true });
    if (Object.keys({ ...errors }).length > 0) {
      return;
    }
    setSecurityQuestionDialogOpen(false);
  }

  return <div>
    <div className="mb-6">
      <FormLabel mandatory>
        First Name
      </FormLabel>
      <FormInput
        name="first_name"
        placeholder="Enter First Name"
        required
        formik={formik}
      />
    </div>
    <div className="mb-6">
      <FormLabel>
        Middle Name
      </FormLabel>
      <FormInput
        name="middle_name"
        placeholder="Enter Middle Name"
        required
        formik={formik}
      />
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        Last Name
      </FormLabel>
      <FormInput
        name="last_name"
        placeholder="Enter Middle Name"
        required
        formik={formik}
      />
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        Create Password
      </FormLabel>
      <FormPassword
        id="password"
        name="password"
        type="text"
        placeholder="Enter a new Password"
        required
        formik={formik}
      />
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        Repeat Password
      </FormLabel>
      <FormPassword
        id="password_confirmation"
        name="password_confirmation"
        type="text"
        placeholder="Enter a new Password"
        required
        formik={formik}
      />
      <div className="text-base-blue hover:underline text-right mt-2 cursor-pointer" onClick={handleSecurityQuestionDialogOpen}>
        Create a Security Question
      </div>
      <FormError formik={formik} name="security_question" className="text-right" />
    </div>

    <DialogCloseable
      open={securityQuestionDialogOpen}
      title="Sequrity Question"
      subtitle="Please create a security question for your account."
      onClose={handleSecurityQuestionDialogClose}
    >
      <div className="mb-6">
        <FormLabel mandatory>
          Security Question
        </FormLabel>
        <FormInput
          name="security_question"
          placeholder="Security Question"
          required
          formik={formik}
        />
      </div>
      <div className="mb-6">
        <FormLabel mandatory>
          Answer
        </FormLabel>
        <FormInput
          name="security_answer"
          placeholder="Answer"
          required
          formik={formik}
        />
      </div>
      <FormButton onClick={handleSecurityQuestionDialogConfirm} className="w-full">
        Confirm
      </FormButton>
    </DialogCloseable>
  </div>
}

// --------------------------------------------

const RegisterFormFirmSelect = ({ formik, formWizardRef, ...props }: any): ReactElement => {

  const firms = useSelector(selectFirms)
  const filingFrequencies = useSelector(selectFilingFrequencies)

  return <div>
    <div className="rounded-lg border-2 border-selected-blue p-8 text-base-gray text-center mb-8">
      Select the affiliated firm and register as a Filer, rather than an Admin.
      After logging in, you may find that not all firm files are visible, but
      you can still file a new case. You'll need to wait until your admin
      approves you to gain access to all the files.
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        Firm's name
      </FormLabel>
      <FormSelect
        required
        options={firms}
        name="firm_id"
        formik={formik}
        empty={'Select a firm...'}
        optionValue='id'
        optionLabel='name'
      />
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        Filing frequency
      </FormLabel>
      <FormSelect
        options={filingFrequencies}
        name="filing_frequency_id"
        optionValue="id"
        optionLabel="description"
        required
        formik={formik}
        empty={'Select a filing frequency...'}
      />
    </div>

  </div>
}

// --------------------------------------------

const RegisterFormFirmInfo = ({ formik, formWizardRef, ...props }: any): ReactElement => {

  const filingFrequencies = useSelector(selectFilingFrequencies)

  return <div>
    {
      formik.values.userType == TYPE_FIRM_ADMINISTRATOR && <div className="mb-6">
        <FormLabel mandatory>
          Firm's Name
        </FormLabel>
        <FormInput
          name="firm_name"
          formik={formik}
          placeholder="Enter your firm's name"
          required
        />
      </div>
    }
    <div className="mb-6">
      <FormLabel mandatory>
        Filing frequency
      </FormLabel>
      <FormSelect
        options={filingFrequencies}
        name="filing_frequency_id"
        optionValue="id"
        optionLabel="description"
        required
        formik={formik}
        empty={'Select a filing frequency...'}
      />
    </div>
  </div>
}

// --------------------------------------------

const RegisterFormContactInfo = ({ formik, formWizardRef, endForm, ...props }: any): ReactElement => {

  const { axios } = useAuth();

  const [states, setStates] = useState([]);
  const authorizationDialogOpen = useSelector(selectAuthorizationDialogOpen);

  useEffect(() => {
    fetchStates();
  }, [])

  const handleAuthorizationDialogConfirm = async () => {
    const errors = await formik.setTouched({ allow_admin: true, allow_file: true, accepted_tnc: true });
    if (Object.keys({ ...errors }).length > 0) {
      return;
    }
    formik.submitForm();
  }

  const fetchStates = async () => {
    const { data } = await axios.get('/states');

    setStates(data);
  }

  return <div>
    <div className="mb-6">
      <FormLabel mandatory>
        Phone number
      </FormLabel>
      <FormInput
        name="phone"
        formik={formik}
        placeholder="Enter your phone number"
        required
      />
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        Address
      </FormLabel>
      <FormInput
        name="street_address"
        formik={formik}
        placeholder="Enter your address"
        required
      />
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        State
      </FormLabel>
      <FormSelect
        required
        options={states}
        name="state"
        formik={formik}
        empty={'Select a state...'}
        optionValue='code'
        optionLabel='name'
      />
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        City
      </FormLabel>
      <FormInput
        name="city"
        placeholder="Enter your city"
        formik={formik}
        required
      />
    </div>
    <div className="mb-6">
      <FormLabel mandatory>
        Zip code
      </FormLabel>
      <FormInput
        name="zip_code"
        placeholder="Enter your zip code"
        formik={formik}
        required
      />
    </div>
    <div className=" text-white font-medium font-base ">
      <button
        type="button"
        className="w-full mt-10 bg-base-blue p-2 rounded hover:bg-hovered-light-blue active:bg-active-light-blue"
        onClick={endForm}
      >
        Continue
      </button>
    </div>
    <DialogCloseable
      open={authorizationDialogOpen}
      title="Authorization"
      subtitle="Please read the following information carefully."
      onClose={() => setAuthorizationDialogOpen(false)}
    >
      <div className="mb-6">
        <FormLabel className="flex">
          <input
            id="allow_admin"
            name="allow_admin"
            type="checkbox"
            placeholder="Security Question"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.allow_admin}
            className="flex-initial border text-base rounded-md tracking-wider hover:border-gray-300 focus:outline-none focus:border-gray-300 w-5 h-5 mr-2"
          />
          <div className="flex-1 text-md text-base-gray">
            Allow MFR Customer Service to be a Firm Admin on your behalf.
          </div>
        </FormLabel>
        <FormError error={formik.errors.allow_admin} touched={formik.touched.allow_admin} />
      </div>
      <div className="mb-6">
        <FormLabel className="flex">
          <input
            id="allow_file"
            name="allow_file"
            type="checkbox"
            placeholder="Security Question"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.allow_file}
            className="flex-initial border text-base rounded-md tracking-wider hover:border-gray-300 focus:outline-none focus:border-gray-300 w-5 h-5 mr-2"
          />
          <div className="flex-1 text-md text-base-gray">
            Allow MFR Customer Service to File to the courts on your behalf.
          </div>
        </FormLabel>
        <FormError error={formik.errors.allow_file} touched={formik.touched.allow_file} />
      </div>
      <div className="mb-6">
        <FormLabel className="flex">
          <input
            id="accepted_tnc"
            name="accepted_tnc"
            type="checkbox"
            placeholder="Security Question"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.accepted_tnc}
            className="flex-initial border text-base rounded-md tracking-wider hover:border-gray-300 focus:outline-none focus:border-gray-300 w-5 h-5 mr-2"
          />
          <div className="flex-1 text-md text-base-gray">
            I agree to the <Link to='/terms-and-conditions' target="_blank" className="text-base-blue hover:underline text-right mt-2 cursor-pointer">
              Terms and Conditions
            </Link>.
          </div>
        </FormLabel>
        <FormError error={formik.errors.accepted_tnc} touched={formik.touched.accepted_tnc} />
      </div>
      <button
        type="submit"
        className="text-white font-medium font-base w-full mt-10 bg-base-blue p-2 rounded hover:bg-hovered-light-blue active:bg-active-light-blue"
        onClick={handleAuthorizationDialogConfirm}
      >
        Confirm
      </button>
    </DialogCloseable>
  </div>
}

// --------------------------------------------

const RegisterScreen = (): ReactElement => {

  const { axios } = useAuth();
  const { addMessageSuccess } = useMessageBoard();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const firms = useSelector(selectFirms);
  const securityQuestionDialogOpen = useSelector(selectSecurityQuestionDialogOpen)
  const authorizationDialogOpen = useSelector(selectAuthorizationDialogOpen)

  const formWizardRef = createRef();

  useEffect(() => {
    fetchFilingFrequencies();
  }, [])

  const Subtitle = () => {
    switch (currentStep) {
      case 0:
        return "Select a user type."
      case 1:
        return firms.length == 0 ? "Please complete your Personal Information." : "Please complete your Firm’s Information.";
      case 2:
        return firms.length == 0 ? "Please complete your Firm’s Information." : "Please complete your Personal Information.";
      case 3:
        return "Please complete your Contact Information.";
    }
    return "";
  }

  const getValidationsCurrentValidatedFields = () => {
    const validations: any = getValidationsCurrentValidations()
    for (const i in validations) {
      validations[i] = true;
    }
    return validations;
  }

  const getValidationsCurrentValidations = () => {
    const select_user_type = {
      userType: yup.string().required("Please select a User Type"),
      email: yup.string().required("Email is required").email("Enter a valid email"),
    };
    const personal_info = {
      first_name: yup.string().required("Please enter your first name"),
      last_name: yup.string().required("Please enter your last name"),
      password: yup.string().required('Password is required').matches(VALIDATION_PASSWORD, 'Password should be atleast 8 characters with atleast 1 lower case,1 upper case and 1 number/symbol.'),
      password_confirmation: yup.string().required('Password is required').oneOf([yup.ref('password')], 'Passwords must match'),
      security_question: yup.string().required('Security question is required'),
    };
    const security_question = {
      security_question: yup.string().required('Question is required'),
      security_answer: yup.string().required('Answer is required'),
    };
    const firm_info: any = {
      filing_frequency_id: yup.string().required('Filing frequency is required'),
    };
    if (formik.values.userType == TYPE_FIRM_ADMINISTRATOR) {
      firm_info.firm_name = yup.string().required('Firm name is required');
    }
    const firm_select = {
      firm_id: yup.string().required('Please select a firm'),
      filing_frequency_id: yup.string().required('Filing frequency is required'),
    };
    const contact_info = {
      phone: yup.string().required('Phone number is required').matches(VALIDATION_PHONE, 'Please enter a valid phone number'),
      street_address: yup.string().required('Address is required'),
      state: yup.string().required('State is required'),
      city: yup.string().required('City is required'),
      zip_code: yup.string().required('Zip code is required').matches(VALIDATION_ZIP_CODE, 'Please enter a valid ZIP code'),
    };
    const authorization = {
      // allow_admin: yup.boolean().isTrue('Question is required'),
      // allow_file: yup.boolean().isTrue('Question is required'),
      accepted_tnc: yup.boolean().isTrue('You must accept Terms and Conditions'),
    };

    if (securityQuestionDialogOpen) {
      return security_question;
    }

    if (authorizationDialogOpen) {
      return authorization;
    }

    switch (currentStep) {
      case 0:
        return select_user_type;
      case 1:
        return firms.length == 0 ? personal_info : firm_select;
      case 2:
        return firms.length == 0 ? firm_info : personal_info;
      case 3:
        return contact_info;
    }

    return {};
  }

  const initialValues: {
    userType: string,

    email: string,
    password: string,
    password_confirmation: string,
    first_name: string,
    middle_name: string,
    last_name: string,

    allow_admin: boolean,
    allow_file: boolean,
    accepted_tnc: boolean

    filing_frequency_id: string,

    firm_id?: string,
    firm_name?: string,

    security_question: string,
    security_answer: string

    phone: string,
    street_address: string,
    state: string,
    city: string,
    zip_code: string,

    is_attorney: boolean
  } = {
    userType: '',

    email: '',
    password: '',
    password_confirmation: '',
    first_name: '',
    middle_name: '',
    last_name: '',

    allow_admin: false,
    allow_file: false,
    accepted_tnc: false,

    filing_frequency_id: '',

    firm_id: '',
    firm_name: '',

    security_question: '',
    security_answer: '',

    phone: '',
    street_address: '',
    state: '',
    city: '',
    zip_code: '',

    is_attorney: false
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => {
      let validations: any = getValidationsCurrentValidations();

      return yup.object().shape(validations);
    },
    onSubmit: async (values, { setSubmitting }) => {
      const data = { ...values, type: '' };
      if (!!values.firm_id) { // firm member
        data.type = 'firm_user';

        delete data.firm_name;
      } else if (values.userType == TYPE_FIRM_ADMINISTRATOR) { // firm admin
        data.type = 'firm_admin';

        delete data.firm_id;
      } else if (values.userType == TYPE_INDIVIDUAL_USER) { // individual
        data.type = 'individual';

        delete data.firm_name;
        delete data.firm_id;
      }

      try {
        await axios.post('/auth/register', data);
        addMessageSuccess('The user was created. You will receive a email to continue.')
        navigate('/login');
      } catch (e) {
        console.log(e)
      }
    }
  })

  const handleComplete = () => {
    console.log("Form completed!");
    // Handle form completion logic here
  }

  const tabChanged = ({ prevIndex, nextIndex }: { prevIndex: number; nextIndex: number }) => {
    setTimeout(() => setCurrentStep(nextIndex - 1), 10);
  }

  const fetchFirms = async (): Promise<any> => {
    try {
      const { data }: { data: any[] } = await axios.post('/auth/register/checkFirmEmail', {
        email: formik.values.email
      })
      return data
    } catch (e: any) {
      if (e.response.status == 422) {
        return [];
      }
      throw e
    }
  }

  const fetchFilingFrequencies = async (): Promise<any> => {
    const { data }: { data: any[] } = await axios.get('/auth/filing-frequencies')

    setFilingFrequencies(data)
  }

  const beforeStepPass0 = async (e: any, handleNext: any): Promise<boolean> => {
    try {
      const firms = await fetchFirms();
      setFirms(firms)
      firms.length == 0 && handleNext(e);
    } catch (e) {
    }
    return false;
  }

  const beforeStepPassCallbacks: { [key: string]: (e: any, handleNext: any) => Promise<boolean> } = {
    0: beforeStepPass0
  }

  const handleNextStep = async (e: any, handleNext: any) => {
    const errors = await formik.setTouched(getValidationsCurrentValidatedFields());
    if (Object.keys({ ...errors }).length > 0) {
      return;
    }
    if (!beforeStepPassCallbacks[currentStep]) {
      return handleNext(e);
    }
    return beforeStepPassCallbacks[currentStep](e, handleNext);
  }

  const endForm = async () => {
    const errors = await formik.setTouched(getValidationsCurrentValidatedFields());
    if (Object.keys({ ...errors }).length > 0) {
      return;
    }
    setAuthorizationDialogOpen(true)
  }

  const nextButtonTemplate = (handleNext: any) => <div className=" text-white font-medium font-base ">
    <button
      type="button"
      className="w-full mt-10 bg-base-blue p-2 rounded hover:bg-hovered-light-blue active:bg-active-light-blue"
      onClick={(e) => handleNextStep(e, handleNext)}
    >
      Continue
    </button>
  </div>

  const backButtonTemplate = (handleNext: any) => <></>

  return <UserAccessLayout>
    <div className="w-full">
      <h2 className="mb-6 text-2xl text-extra-large text-base-blue text-start">
        Sign Up
      </h2>
      <p className="text-base text-base-gray font-medium tracking-wider">
        <Subtitle />
      </p>
      <form className="space-y-8 mfr-wizard" onSubmit={formik.handleSubmit}>
        <FormWizard
          ref={formWizardRef}
          onComplete={handleComplete}
          onTabChange={tabChanged}
          nextButtonTemplate={nextButtonTemplate}
          backButtonTemplate={backButtonTemplate}
          color=''
        >
          <FormWizard.TabContent>
            <RegisterFormSelectUserType formik={formik} formWizardRef={formWizardRef} />
          </FormWizard.TabContent>
          <FormWizard.TabContent>
            {
              firms.length > 0 && <RegisterFormFirmSelect formik={formik} formWizardRef={formWizardRef} />
            }
            {
              firms.length == 0 && <RegisterFormPersonalInfo formik={formik} formWizardRef={formWizardRef} />
            }
          </FormWizard.TabContent>
          <FormWizard.TabContent>
            {
              firms.length > 0 && <RegisterFormPersonalInfo formik={formik} formWizardRef={formWizardRef} />
            }
            {
              firms.length == 0 && <RegisterFormFirmInfo formik={formik} formWizardRef={formWizardRef} />
            }
          </FormWizard.TabContent>
          <FormWizard.TabContent>
            <RegisterFormContactInfo formik={formik} formWizardRef={formWizardRef} endForm={endForm} />
          </FormWizard.TabContent>
        </FormWizard>
      </form>

      <div className="text-base mt-8 flex justify-center">
        <p className="text-base-gray">
          Already have an account?{" "}
          <Link to={"/login"} className="text-base-blue">
            Sign In
          </Link>{" "}
        </p>{" "}
      </div>
    </div>
  </UserAccessLayout>
}

export default RegisterScreen;
