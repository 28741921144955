import { ReactElement } from "react"
import { useDispatch, useSelector } from "react-redux";
import { createSlice } from '@reduxjs/toolkit';

// slice ----------------------------------------

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    n: 0,
  },
  reducers: {
    showLoader: (state) => {
      state.n += 1
    },
    hideLoader: (state) => {
      state.n -= 1
    },
  },
})

const selectLoader = (state: any) => state.loader.n

export default loaderSlice.reducer

// hook ----------------------------------------

export const useLoader = () => {
  const dispatch = useDispatch();

  const showLoader = () => dispatch(loaderSlice.actions.showLoader());

  const hideLoader = () => dispatch(loaderSlice.actions.hideLoader());

  return {
    showLoader,
    hideLoader
  };
}

// component ----------------------------------------

export const Loader = (): ReactElement => {
  const n = useSelector(selectLoader);
  
  return <>
    {
      n > 0 && <div className='fixed top-0 bottom-0 left-0 right-0' style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: 1000000 }}>
        <div className='flex items-center justify-center h-full'>
          <img src="/images/loader.svg" className={`loading-spinner w-24 h-24`} />
        </div>
      </div>
    }
  </>
}