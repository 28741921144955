
import { isBefore } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";

export interface Service {
  id: number;
  envelope: string;
  caseNumber: string;
  caseTitle: string;
  clientMatterNumber: string;
  serviceStatus: string;
  lastUpdate: string;
  description: string;
}


export type ServiceFilters = {
  search?: string;
  statuses?: string[];
  envelope?: string;
  caseNumber?: string;
  clientMatterNumber?: string;
  dateFrom?: Dayjs | null;
  dateTo?: Dayjs | null;
};

const mockServices: Service[] = [
  {
    id: 1,
    envelope: "83917502",
    caseNumber: "ATC-93021",
    caseTitle: "State vs. Johnson",
    clientMatterNumber: "7384",
    serviceStatus: "Failed",
    lastUpdate: "03/28/24, 8:22",
    description: "Petition",
  },
  {
    id: 2,
    envelope: "83917503",
    caseNumber: "ATC-93021",
    caseTitle: "State vs. Johnson",
    clientMatterNumber: "8492",
    serviceStatus: "Submitting",
    lastUpdate: "03/28/24, 8:22",
    description: "Petition",
  },
  {
    id: 3,
    envelope: "83917504",
    caseNumber: "ATC-93022",
    caseTitle: "Doe vs. Smith",
    clientMatterNumber: "6492",
    serviceStatus: "Submitted",
    lastUpdate: "03/28/24, 8:22",
    description: "Petition",
  },
  {
    id: 4,
    envelope: "83917505",
    caseNumber: "ATC-93023",
    caseTitle: "Acme Corp vs. XYZ Inc.",
    clientMatterNumber: "1048",
    serviceStatus: "Accepted",
    lastUpdate: "03/28/24, 8:22",
    description: "Motion",
  },
  {
    id: 5,
    envelope: "83917506",
    caseNumber: "ATC-93024",
    caseTitle: "Brown vs. Green",
    clientMatterNumber: "4859",
    serviceStatus: "Rejected",
    lastUpdate: "03/28/24, 8:22",
    description: "Petition",
  },
];

const mockDraftServices: Service[] = [
  {
    id: 10,
    envelope: "83917510",
    caseNumber: "ATC-93022",
    caseTitle: "State vs. Smith",
    clientMatterNumber: "7385",
    serviceStatus: "Draft",
    lastUpdate: "03/29/24, 9:22",
    description: "Petition",
  },
  {
    id: 11,
    envelope: "83917511",
    caseNumber: "ATC-93023",
    caseTitle: "Doe vs. Johnson",
    clientMatterNumber: "7386",
    serviceStatus: "Draft",
    lastUpdate: "03/30/24, 10:22",
    description: "Motion",
  },
  // Add more draft services as needed
];

export const statuses = {
    submitting: "Submitting",
    failed: "Failed",
    submitted: "Submitted",
    accepted: "Accepted",
    rejected: "Rejected",
  };


const useEServices = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchServices = useCallback(
    async (options: ServiceFilters = {}) => {
      setLoading(true);
      setError(null);
      try {
        // Simulate an API call
        setTimeout(() => {
          let filteredServices = mockServices;

          // Filter by statuses
          if (options.statuses && options.statuses.length > 0) {
            filteredServices = filteredServices.filter((service) =>
              options.statuses!.some(
                (status) => service.serviceStatus.toLowerCase() === status.toLowerCase()
              )
            );
          }

          // Filter by search query
          if (options.search) {
            const searchLower = options.search.toLowerCase();
            filteredServices = filteredServices.filter(
              (service) =>
                service.envelope.toLowerCase().includes(searchLower) ||
                service.caseNumber.toLowerCase().includes(searchLower) ||
                service.clientMatterNumber.toLowerCase().includes(searchLower) ||
                service.serviceStatus.toLowerCase().includes(searchLower) ||
                service.description.toLowerCase().includes(searchLower)
            );
          }

          // Filter by envelope
          if (options.envelope) {
            const envelopeLower = options.envelope.toLowerCase();
            filteredServices = filteredServices.filter((service) =>
              service.envelope.toLowerCase().includes(envelopeLower)
            );
          }

          // Filter by caseNumber
          if (options.caseNumber) {
            const caseNumberLower = options.caseNumber.toLowerCase();
            filteredServices = filteredServices.filter((service) =>
              service.caseNumber.toLowerCase().includes(caseNumberLower)
            );
          }

          // Filter by clientMatterNumber
          if (options.clientMatterNumber) {
            const clientMatterNumberLower = options.clientMatterNumber.toLowerCase();
            filteredServices = filteredServices.filter((service) =>
              service.clientMatterNumber.toLowerCase().includes(clientMatterNumberLower)
            );
          }

          // **Filter by date range**
          if (options.dateFrom || options.dateTo) {
            filteredServices = filteredServices.filter((service) => {
              // Parse lastUpdate to Dayjs object
              const lastUpdateDate = dayjs(service.lastUpdate, 'MM/DD/YY, H:mm', true);

              if (!lastUpdateDate.isValid()) {
                // Exclude services with invalid date
                return false;
              }

              let isAfterFrom = true;
              let isBeforeTo = true;

              if (options.dateFrom) {
                isAfterFrom = lastUpdateDate.isAfter(options.dateFrom, 'day');
              }
              if (options.dateTo) {
                isBeforeTo =  lastUpdateDate.isBefore(options.dateFrom, 'day');
              }
              console.log({isAfterFrom})
              console.log({isBeforeTo})
              const inBetween = isAfterFrom && isBeforeTo || lastUpdateDate.isSame(options.dateTo, 'day')

              return inBetween;
            });
          }

          setServices(filteredServices);
          setLoading(false);
        }, 500);
      } catch (err) {
        setError('Failed to fetch services');
        setLoading(false);
      }
    },
    []
  );

  const fetchServiceById = (id: number): Service | undefined => {
    return services.find((service) => service.id === id);
  };

  const fetchDraftServices = useCallback(
    async (options: ServiceFilters = {}) => {
      setLoading(true);
      setError(null);
      try {
        setTimeout(() => {
          let filteredServices = mockDraftServices;

          // Apply filters if necessary
          if (options.search) {
            const searchLower = options.search.toLowerCase();
            filteredServices = filteredServices.filter(
              (service) =>
                service.envelope.toLowerCase().includes(searchLower) ||
                  service.caseNumber.toLowerCase().includes(searchLower) ||
                  service.clientMatterNumber.toLowerCase().includes(searchLower) ||
                  service.caseTitle.toLowerCase().includes(searchLower) ||
                  service.description.toLowerCase().includes(searchLower)
            );
          }

          setServices(filteredServices);
          setLoading(false);
        }, 500);
      } catch (err) {
        setError('Failed to fetch draft services');
        setLoading(false);
      }
    },
    []
  );


  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  return {
    services,
    loading,
    error,
    fetchServices,
    fetchServiceById,
    fetchDraftServices,
  };
};

export default useEServices;
