import { useState, useCallback } from "react";
import { useAuth } from "./auth";
import { Case } from "../utils/utils";

// Updated User interface
export interface User {
  id: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  status?: string;
  is_service_contact?: number;
  barNumber?: string;
  role?: string;
  is_firm_admin?: number;
}

export type UserFilters = {
  search?: string;
  page?: number;
  itemsPerPage?: number;
  sortBy?: string;
};

const useServiceContacts = () => {
  const { axios } = useAuth({ middleware: 'auth' });

  const listFirmServiceContacts = async (): Promise<any> => {
    return (await axios.get('service-contacts')).data;
  };

  const saveFirmServiceContact = async (serviceContact: any, fcase?: Case): Promise<any> => {
    if(fcase?.id) {
      serviceContact.filing_case_id = fcase.id;
    }
    if(!serviceContact?.id) {
      await axios.post(`service-contacts`, serviceContact);
    } else {
      // await axios.put(`service-contacts/${serviceContact?.id}`, serviceContact); #TODO
    }
  };

  const searchServiceContacts = async (searchQuery: any): Promise<any> => {
    return (await axios.get('service-contacts/search', {params: searchQuery})).data;
  };

  const addFirmServiceContactToCase = async (fcase: Case, serviceContact: any): Promise<any> => {
    await axios.post(`filing-cases/${fcase.id}/service-contacts/${serviceContact.id}`)
  };

  const addExternalServiceContactToCase = async (fcase: Case, serviceContact: any): Promise<any> => {
    await axios.post(`filing-cases/${fcase.id}/service-contacts`, serviceContact)
  };

  const removeServiceContactFromCase = async (fcase: Case, serviceContact: any): Promise<any> => {
    await axios.delete(`filing-cases/${fcase.id}/service-contacts/${serviceContact.id}`)
  };

  return {
    searchServiceContacts,
    listFirmServiceContacts,
    saveFirmServiceContact,
    addFirmServiceContactToCase,
    addExternalServiceContactToCase,
    removeServiceContactFromCase
  };
};

export default useServiceContacts;
