import { ReactElement, useEffect, useMemo, useState } from "react";
import DialogCloseable from "../../components/dialogs/DialogCloseable";
import * as yup from 'yup';
import { useFormik } from "formik";
import FormInput from "../../components/forms/FormInput";
import FormSelect from "../../components/forms/FormSelect";
import FormSelectMultiple from "../../components/forms/FormSelectMultiple";
import FormLabel from "../../components/forms/FormLabel";
import { useAuth } from "../../hooks/auth";
import { setCases } from "../../components/assets/CaseSearch";
import { useNavigate } from "react-router-dom";
import { FormButtonLight } from "../../components/forms/FormButton";

export const SearchCaseDialog = ({ open, onClose, title, target, ...props }: {open: boolean, onClose: Function, title?: string, target: string}): ReactElement => {

  const { axios } = useAuth({ middleware: 'auth' });

  const [locations, setLocations] = useState([]);

  const navigate = useNavigate();

  const search_by = ['Case', 'Party'];
  const business_types = ['Personal', 'Business'];

  useEffect(() => {
    if (!open || locations.length > 0) {
      return;
    }
    fetchLocations()
  }, [open]);

  const fetchLocations = async () => {
    setLocations((await axios.get(`/locations/all`, { params: { type: 'initial' } })).data);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location_id: null,//3125,//224 Fresno - Traffic,
      search_by: 'Case',
      case_number: '',//'123456',//'5Y9D001CM',
      business_type: 'Personal',
      first_name: '',
      middle_name: '',
      last_name: '',
      business_name: '',
    },
    validationSchema: () => {
      const rules: any = {
        location_id: yup.string().required('Please select a location'),
      }
      if (formik.values.search_by == 'Case') {
        rules.case_number = yup.string().required('Please enter a case number');
      } else {
        if (formik.values.business_type == 'Personal') {
          rules.first_name = yup.string().required('Please enter a first name');
          rules.last_name = yup.string().required('Please enter a last name');
        } else {
          rules.business_name = yup.string().required('Please enter a business name');
        }
      }
      return yup.object().shape(rules);
    },
    onSubmit: async (params: any) => {
      try {
        setCases([]);
        const cases = (await axios.get('/filing-cases/search', { params })).data;
        setCases(cases);
        navigate(target);

      } catch (e) {
        console.error(e)
      }
    }
  });

  return <DialogCloseable
    width="600px"
    title={title ? title : "Search Case"}
    subtitle="Search Case Details"
    open={open}
    onClose={onClose}
  >
    <form onSubmit={formik.handleSubmit} className="text-sm">
      <div className="flex flex-col gap-4">
        <div className="flex-1">
          <FormLabel>Location</FormLabel>
          <FormSelect
            formik={formik}
            options={locations}
            optionValue="id"
            optionLabel="name"
            name="location_id"
            searchable
          />
        </div>
        <div className="flex-1">
          <FormLabel>Search By</FormLabel>
          <FormSelectMultiple
            formik={formik}
            options={search_by}
            name="search_by"
            inline={true}
            type='radio'
          />
        </div>
        {
          formik.values.search_by == 'Case' && <div className="flex-1">
            <FormLabel>Case Number</FormLabel>
            <FormInput formik={formik} name="case_number" />
          </div>
        }
        {
          formik.values.search_by == 'Party' && <>
            <div className="flex-1">
              <FormLabel>Party Type</FormLabel>
              <FormSelectMultiple
                formik={formik}
                options={business_types}
                name="business_type"
                inline={true}
                type='radio'
              />
            </div>
            {
              formik.values.business_type == 'Personal' && <>
                <div className="flex-1">
                  <FormLabel>First Name</FormLabel>
                  <FormInput formik={formik} name="first_name" />
                </div>
                <div className="flex-1">
                  <FormLabel>Middle Name</FormLabel>
                  <FormInput formik={formik} name="middle_name" />
                </div>
                <div className="flex-1">
                  <FormLabel>Last Name</FormLabel>
                  <FormInput formik={formik} name="last_name" />
                </div>
              </>
            }
            {
              formik.values.business_type == 'Business' && <div className="flex-1">
                <FormLabel>Business Name</FormLabel>
                <FormInput formik={formik} name="business_name" />
              </div>
            }
          </>
        }
        <div className="flex-1 text-center">
          <FormButtonLight className="mt-6 w-1/3">
            Search
          </FormButtonLight>
        </div>
      </div>
    </form>
  </DialogCloseable>
};

export const SearchCaseDialogExistingCase = ({open, onClose}: {open: boolean, onClose: Function}): ReactElement => {
  return <SearchCaseDialog
    open={open}
    onClose={onClose}
    title="File Into An Existing Case"
    target="/file-into-existing-case/search"
  />
}

export const SearchCaseDialogEService = ({open, onClose}: {open: boolean, onClose: Function}): ReactElement => {
  return <SearchCaseDialog
    open={open}
    onClose={onClose}
    title="E-Service"
    target="/e-service/search"
  />
}