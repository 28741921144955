import { useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; 
import "./styles.css";

interface TableProps {
    data: Array<any>;
    colDef: Array<any>;
};

const AgTable = ({ data, colDef }: TableProps) => {
    const [colDefs, setColDefs] = useState(colDef);

    const autoSizeStrategy = useMemo(() => {
        return {
          type: "fitGridWidth",
          defaultMinWidth: 10,
        };
      }, []);

    return (
        <div className="ag-theme-quartz ag-theme-my-fillings w-full h-[500px]">
            <AgGridReact
                rowData={data}
                columnDefs={colDefs}
                autoSizeStrategy={autoSizeStrategy}
            />
        </div>
    );
};

export default AgTable;
