import { useAuth } from "./auth";
import { useSelector } from "react-redux";
import { selectAttorneys, setAttorneys } from "../components/assets/CaseWizard";

const useAttorneys = () => {
  const { axios } = useAuth({ middleware: 'auth' });
  const attorneys = useSelector(selectAttorneys)

  const fetchAttorneys = async () => {
    setAttorneys((await axios.get('/attorneys')).data);
  };

  return {
    attorneys,
    fetchAttorneys
  };
};

export default useAttorneys;
