import { ReactElement } from "react";
import PrivateLayout from "../components/layout/PrivateLayout";
import RoundedBox from "../components/layout/RoundedBox";

const MyReportsScreen = ():ReactElement => {
  return <PrivateLayout>
    <RoundedBox color="normal">
      My Reports
    </RoundedBox>
  </PrivateLayout>;
};

export default MyReportsScreen;
