import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import LockIcon from '@mui/icons-material/Lock';
import CheckIcon from '@mui/icons-material/Check';
import { FormButton } from './forms/FormButton';
import RoundedBox from './layout/RoundedBox';
import BackArrow from "./BackArrow";

export interface Step {
  title: string
  subtitle: string
  element: (stepParams: any) => ReactElement
  prev?: Function
  next?: Function
}

export interface WizardStep extends Step {
  index: number
  current: boolean
  enabled: boolean
  props?: any
}

const StepsWizard = ({ steps, onTabChange, onFormComplete, stepParams, ...props }: { steps: Step[], onTabChange?: Function, onFormComplete?: Function, stepParams: any }): ReactElement => {

  const navigate = useNavigate();

  const [_steps, setSteps]: [WizardStep[], Function] = useState([]);
  const [stepCurrent, setStepCurrent]: [WizardStep | undefined, Function] = useState();
  const [showNextButton, setShowNextButton]: [boolean, Function] = useState(true);
  const [showPrevButton, setShowPrevButton]: [boolean, Function] = useState(true);

  useEffect(() => {
    const _steps: WizardStep[] = steps.map((step, index) => {
      return {
        ...step,
        current: !index,
        enabled: !index,
        index,
        props: {}
      }
    });
    setSteps(_steps)
    onClick(_steps[0])
  }, [])

  const getStepClasses = (step: WizardStep) => {
    let classes = 'rounded-full w-min p-2 m-auto border-1 border-base-blue hover:bg-selected-blue';
    if (step.current) {
      classes += ' bg-base-blue';
    } else if (step.enabled) {
      classes += ' bg-base-blue';
    } else {
      classes += ' bg-background-overlay-blue';
    }
    return classes;
  }

  const getStepGapClasses = (step: WizardStep) => {
    let classes = 'flex-1 rounded-full h-1 border-2 relative ';
    if (stepCurrent) {
      classes += stepCurrent.index <= step.index ? 'border-selected-blue bg-selected-blue' : 'border-base-blue bg-base-blue'
    }
    return classes;
  }

  const onClick = async (clicked: WizardStep, force = false) => {
    if(!force && !clicked.enabled) {
      return;
    }
    if (stepCurrent && stepCurrent.index == clicked.index) {
      return;
    }
    if (!(await _onTabChange(clicked))) {
      return;
    }
    clicked.enabled = true;
    if (_steps && _steps.length > 0) {
      setSteps(_steps.map(step => {
        return {
          ...step,
          current: clicked.index == step.index
        }
      }))
    }
    setStepCurrent(clicked);
  }

  const _onTabChange = async (step: WizardStep): Promise<boolean> => {
    if (onTabChange) {
      return (await onTabChange(stepCurrent, step)) !== false;
    }
    return true;
  }

  const onPrev = async () => {
    if (!stepCurrent) {
      return;
    }
    if (stepCurrent.prev && (await stepCurrent.prev(stepCurrent)) === false) {
      return;
    }
    if (stepCurrent.index == 0) {
      return navigate(-1);
    }
    goToStep(stepCurrent.index - 1)
  }

  const onNext = async () => {
    if (!stepCurrent) {
      return;
    }
    if (stepCurrent.next && (await stepCurrent.next(stepCurrent)) === false) {
      return;
    }
    if (stepCurrent.index == _steps.length - 1 && onFormComplete) {
      return onFormComplete();
    }
    goToStep(stepCurrent.index + 1)
  }
  
  const goToStep = async (n: number) => {
    onClick(_steps[n], true)
  }

  return <>
    {
      stepCurrent && <>
        <RoundedBox className="bg-background-overlay-blue bg-contain px-6 flex gap-3 mb-4">
          {
            _steps.map((step, index) => {
              const is_first = index == 0
              const is_last = index == steps.length - 1
              const styles: any = {};
              if (!is_first) {
                styles.marginLeft = '-36px';
              }
              if (!is_last) {
                styles.marginRight = '-36px';
              }
              return <React.Fragment key={`step-${index}`}>
                <div
                  className="flex-initial w-32 cursor-pointer"
                  style={styles}
                  onClick={() => onClick(step)}
                >
                  <div className={getStepClasses(step)}>
                    {step.current && <DataSaverOffIcon sx={{ color: '#fff' }} />}
                    {!step.current && !step.enabled && <LockIcon sx={{ color: '#5261A0' }} />}
                    {!step.current && step.enabled && <CheckIcon sx={{ color: '#fff' }} />}
                  </div>
                  <div className="text-center mt-2">
                    <div className="text-base-blue text-xs mb-1">
                      {step.title}
                    </div>
                    <div className="">
                      {step.subtitle}
                    </div>
                  </div>
                </div>
                {
                  !is_last && <div
                    className={getStepGapClasses(step)}
                    style={{ top: '17px' }}
                  />
                }
              </React.Fragment>
            })
          }
        </RoundedBox>
        {
          showPrevButton && <BackArrow onClick={onPrev} className="mb-4" />
        }
        <div className="m-auto">
          {
            _steps.map((step: WizardStep) =>
              // <div key={`step-body-${step.index}`} style={{ display: step.index != stepCurrent.index ? 'none' : 'block' }}>
              <div key={`step-body-${step.index}`} style={{ display: step.current ? 'block' : 'none' }}>
                {step.element({ ...stepParams, step, setShowPrevButton, setShowNextButton, goToStep })}
              </div>
            )
          }
          {
            showNextButton && <div className="flex">
              <div className="flex-1">
              </div>
              <div className="flex flex-initial gap-4 w-52">
                <FormButton
                  onClick={onNext}
                  className="flex-1"
                  type="button"
                >
                  {stepCurrent.index < _steps.length - 1 ? `Proceed to step ${stepCurrent.index + 2}` : `Submit`}
                </FormButton>
              </div>
            </div>
          }
        </div>
      </>
    }
  </>
}

export default StepsWizard;