import { ReactElement, useEffect, useState } from "react";
import PrivateLayout from "../../components/layout/PrivateLayout";
import StepsWizard, { Step, WizardStep } from "../../components/StepsWizard";
import { Case, EMPTY_CASE,  objectToQuery, Party } from "../../utils/utils";
import { selectCategories, selectCourts, setCaseTypes, setCategories, setCourts, setDamageAmounts, setFilerTypes, setFilingCodes, setPartyTypes, setProcedureRemedies } from "../../components/assets/CaseWizard";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import useAttorneys from "../../hooks/useAttorneys";
import usePaymentAccounts from "../../hooks/usePaymentAccount";
import { useMessageBoard } from "../../components/assets/MessageBoard";
import useConfigs from "../../hooks/useConfigs";
import useFilingCases from "../../hooks/useFilingCases";
import FilingStep from "./FileCaseSteps/FilingStep";
import ServiceContactsStep from "./FileIntoExistingCaseSteps/ServiceContactsStep";
import { SummaryStep } from "./FileIntoExistingCaseSteps/SummaryStep";

export const FileIntoExistingCaseScreen = (): ReactElement => {

  const { id } = useParams();

  const { axios } = useAuth({ middleware: 'auth' });

  const {fetchFilingCaseById, saveFilingCase} = useFilingCases()

  const [fcase, setFcase]: [Case | undefined, Function] = useState();

  const {attorneys, fetchAttorneys} = useAttorneys()
  const {fetchPaymentAccounts} = usePaymentAccounts()
  
  const navigate = useNavigate();
  const { addMessageError } = useMessageBoard();

  const {configsReady} = useConfigs();

  useEffect(() => {
    (async () => {
      const promises = [];
      let fcase: Case|undefined = EMPTY_CASE;
      if(!id) {
        setFcase(EMPTY_CASE)
      } else {
        promises.push(fetchFcase(parseInt(id)).then((fetchedCase?: Case) => {
          fetchedCase && fetchFilingCodes(fetchedCase);
          fetchFilerTypes(fetchedCase?.location);
        }));
      }
      fetchAttorneys();
      fetchPaymentAccounts();
    })()
  }, []);

  const displayError = async (message: string) => {
    addMessageError(message)
    navigate("/file-to-court");
    return undefined;
  }
  
  const fetchFilerTypes = async (court: any) => {
    let data:any[] = [];
    if(court?.id) {
      const params = objectToQuery({location_id: court.id});
      data = (await axios.get(`/filer-types?${params}`)).data;
    }
    setFilerTypes(data);
  }

  const fetchFcase = async (case_id?: number): Promise<Case | undefined> => {
    case_id = !fcase?.id ? case_id : fcase?.id
    if(!case_id) {
      return;
    }
    const filingCase = await fetchFilingCaseById(case_id)
    if(!filingCase) {
      return displayError('Case not found');
    }
    filingCase.case_category_id = filingCase.case_type?.case_category_id
    setFcase(filingCase);

    return filingCase;
  }

  const saveFcase = async (data: Case) => {
    try {
      const id = await saveFilingCase(data)
      if(id) {
        fetchFcase(id);
      } else {
        fetchFcase()
      }
    } catch(e) {
      return false;
    }
    return true;
  }

  const fetchFilingCodes = async (fcase: Case, params?: any) => {
    const options: any = {case_type_id: fcase.case_type_id};
    options.initial = (params?.initial ? params?.initial : fcase?.initial) ? 1 : 0

    const data:any[] = (await axios.get(`/filing-codes`, {params: options})).data.map((filingCode:any) => {
      filingCode.name += filingCode.fee ? ` ($${filingCode.fee})` : ``
      return filingCode;
    })
    setFilingCodes(data);
  }

  const steps: Step[] = [
    {
      title: 'Step 1',
      subtitle: 'FILINGS',
      element: (params: any) => <FilingStep {...params} />,
      next: (currentStep: WizardStep) => {
        const { filing_files } = currentStep.props
        if (filing_files.length == 0) {
          addMessageError('Please create the filings')
          return false;
        }
        return true
      },
      prev: (currentStep: WizardStep) => {
        const { filing_files, showingNewFilingForm, disableShowFilingForm } = currentStep.props
        if (showingNewFilingForm) {
          disableShowFilingForm();
        }
        if (filing_files.length === 0) {
          return true;
        }
        return !showingNewFilingForm;
      }
    }, {
      title: 'Step 2',
      subtitle: 'SERVICE CONTACTS',
      element: (params: any) => <ServiceContactsStep {...params} />,
      next: (currentStep: WizardStep) => {
        const { service_contacts } = currentStep.props;
        if (service_contacts.length == 0) {
          addMessageError('Please add service contacts')
          return false;
        }
        return true;
      },
      prev: (currentStep: WizardStep) => {
        const { service_contacts, showingNewServiceContactForm, disableShowServiceContactForm } = currentStep.props
        if (showingNewServiceContactForm) {
          disableShowServiceContactForm();
        }
        if (service_contacts.length === 0) {
          return true;
        }
        return !showingNewServiceContactForm;
      }
    }, {
      title: 'Step 3',
      subtitle: 'SUMMARY',
      element: (params: any) => <SummaryStep {...params}/>
    }
  ];

  const onTabChange = async (currentStep: WizardStep | undefined, targetStep: WizardStep) => {
    return true;
  }

  if(!configsReady) {
    return <></>;
  }

  return <PrivateLayout>
    <StepsWizard
      steps={steps}
      onTabChange={onTabChange}
      stepParams={{
        fcase,
        fetchFcase,
        saveFcase,
        fetchAttorneys,
        fetchPaymentAccounts,
        fetchFilingCodes
      }}
    />
  </PrivateLayout>
};