import React, { useState } from 'react';
import {
  Button,
  Popover,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { ExpandMore, CalendarMonth, Tune, ArrowDropDown } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface FilterDraftFilingsPanelProps {
  onFilterChange: (filters: any) => void;
}

const FilterDraftFilingsPanel: React.FC<FilterDraftFilingsPanelProps> = ({ onFilterChange }) => {
  const statuses = {
    Draft: 'Draft',
  };

  const [filters, setFilters] = useState({
    envelope: '',
    caseNumber: '',
    clientMatterNumber: '',
    dateFrom: null,
    dateTo: null,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({
    envelope: false,
    caseNumber: false,
    clientMatterNumber: false,
    filingAttorney: false,
    statuses: true,
  });

  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    onFilterChange(filters);
    setAnchorEl(null);
  };

  const handleFieldChange = (key: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleToggleExpand = (key: string) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [key]: !prevExpanded[key],
    }));
  };

  const handleClearFilters = () => {
    setFilters({
      envelope: '',
      caseNumber: '',
      clientMatterNumber: '',
      dateFrom: null,
      dateTo: null,
    });
    setDateFrom(null);
    setDateTo(null);
  };

  const handleDateFilterApply = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dateFrom,
      dateTo,
    }));
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          {/* Date Filter Button */}
          <Button
            variant="outlined"
            onClick={() => setDateFilterOpen(true)}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: '50px',
              borderColor: '#5261A0',
              color: '#5261A0',
              padding: '6px 16px',
              textTransform: 'none',
              fontWeight: 'bold',
              width: 300,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CalendarMonth style={{ marginRight: '8px' }} />
              Date Filter
            </div>
            <ArrowDropDown />
          </Button>

          <Button
            endIcon={<Tune />}
            onClick={handlePopoverOpen}
            sx={{
              borderRadius: '50px',
              borderColor: '#5261A0',
              color: '#5261A0',
              padding: '6px 16px',
              textTransform: 'none',
              fontWeight: 'bold',
            }}
          >
            More Filters
          </Button>
        </div>

        <Button
          variant="outlined"
          sx={{
            borderColor: '#5261A0',
            color: '#5261A0',
            borderRadius: '50px',
            textTransform: 'none',
            fontWeight: 'bold',
            padding: '6px 24px',
          }}
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
      </div>

      {/* Filters Popover */}
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="p-4 w-72">
          <div className="flex flex-col space-y-4">
            {/* Other Filters */}
            {[
              { label: 'Case Number', key: 'caseNumber' },
              { label: 'Case Title', key: 'caseTitle' },
            ].map(({ label, key }) => (
              <FormControl fullWidth variant="outlined" key={key}>
                <div
                  onClick={() => handleToggleExpand(key)}
                  className="flex items-center justify-between cursor-pointer"
                >
                  <span className="font-bold text-base mb-2">{label}:</span>
                  <ExpandMore />
                </div>
                <Collapse in={expanded[key]}>
                  <TextField
                    value={filters[key]}
                    onChange={(e) => handleFieldChange(key, e.target.value)}
                    variant="outlined"
                    className="mt-2"
                  />
                </Collapse>
              </FormControl>
            ))}
          </div>

          <Button
            onClick={handlePopoverClose}
            variant="contained"
            sx={{
              backgroundColor: '#5261A0',
              color: 'white',
              borderRadius: '50px',
              textTransform: 'none',
              fontWeight: 'bold',
              padding: '6px 24px',
              marginTop: '16px',
              '&:hover': {
                backgroundColor: '#3E4A7F',
              },
            }}
          >
            Filter
          </Button>
        </div>
      </Popover>

      {/* Date Picker Modal */}
      <Dialog open={dateFilterOpen} onClose={() => setDateFilterOpen(false)}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <div className="flex flex-col space-y-4 mt-2">
            <DatePicker
              label="From"
              value={dateFrom}
              onChange={(newValue) => setDateFrom(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            <DatePicker
              label="To"
              value={dateTo}
              onChange={(newValue) => setDateTo(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDateFilterOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleDateFilterApply();
              setDateFilterOpen(false);
            }}
            variant="contained"
            sx={{
              backgroundColor: '#5261A0',
              color: 'white',
              borderRadius: '50px',
              textTransform: 'none',
              fontWeight: 'bold',
              padding: '6px 24px',
              '&:hover': {
                backgroundColor: '#3E4A7F',
              },
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default FilterDraftFilingsPanel;
