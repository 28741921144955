import { ReactElement, useEffect, useState } from 'react';

const RoundedBox = (props: any): ReactElement => {
  const [children, setChildren]: [ReactElement, Function] = useState(<></>);
  const [attr, setAttr] = useState({});
  
  useEffect(() => {
    if(!props) {
      return;
    }
    const {children, ...attr}:{children: ReactElement, className:string} = props;
    attr.className = `${attr.className ? `${attr.className} ` : ''}rounded-3xl p-4`
    setAttr(attr);
    setChildren(children);
  }, [props]);

  return <div
    {...attr}
  >
    {children}
  </div>;
};

export default RoundedBox;