import { ReactElement, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const HiddenCard = ({ title, className = '', children, ...props }: any): ReactElement => {
  const [visible, setVisible] = useState(false);

  return <div {...{
    ...props,
    className: `rounded border-1 border-base-blue ${className}`
  }}>
    <div
      className="p-2 bg-base-blue text-white cursor-pointer flex"
      onClick={() => setVisible(!visible)}
    >
      <div className='flex-1' style={{lineHeight: '22px'}}>
        {title}
      </div>
      <div className='flex-initial'>
        { visible && <ArrowDropUpIcon /> }
        { !visible && <ArrowDropDownIcon /> }
      </div>
    </div>
    {
      visible && <div className="p-2">
        {children}
      </div>
    }
  </div>
}

export default HiddenCard;