

import { Close, Delete, Edit, Visibility } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FilingFilters } from '../../hooks/useFilings';
import RoundedBox from '../layout/RoundedBox';
import CustomTable from '../table/CustomTable';
import FilterServicesPanel from './filters/FilterEServicesPanel';
import FilterServicesSearchBar from './filters/FilterEServicesSearchBar';
import useEServices from '../../hooks/useEServices';

const DraftEServicesTab = () => {
  const { services, loading, fetchDraftServices } = useEServices();
  const navigate = useNavigate()
  const [filters, setFilters] = useState<FilingFilters>({
    search: '',
    statuses: [],
    envelope: '',
    caseNumber: '',
    clientMatterNumber: '',
    dateFrom: null,
    dateTo: null,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  // Fetch draft services whenever filters change
  useEffect(() => {
    fetchDraftServices(filters);
  }, [filters, fetchDraftServices]);

  // Handlers for search and filters
  const handleSearchChange = useCallback((searchTerm: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: searchTerm,
    }));
  }, []);

  const handleFilterChange = useCallback((newFilters: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  }, []);

  // Define columns for the table with color mappings for the filingStatus field
  const columns = [
    { field: 'envelope', headerName: 'Envelope N' },
    { field: 'caseNumber', headerName: 'Case N' },
    { field: 'clientMatterNumber', headerName: 'Client Matter N' },
    {
      field: 'serviceStatus',
      headerName: 'E-Service Status',
      colorMapping: {
        Failed: 'red',
        Draft: '#0096ff',
        Submitted: '#ffcc00',
        Accepted: 'green',
        Rejected: 'red',
      },
    },
    { field: 'lastUpdate', headerName: 'Last Update' },
    { field: 'description', headerName: 'Description' },
  ];

  const handleEditService = (id: number) => {
    navigate(`/edit-draft-service/${id}`);
  };

  const handleDeleteService = () => {
    setOpenDialog(true);
    setDeleteError(null);
  };

  const handleConfirmDelete = async () => {
    // Add delete logic here
    setOpenDialog(false);
  };

  const actions = () => {
    const baseActions = [
      {
        label: 'Edit',
        onClick: handleEditService,
        icon: <Edit className="text-[#5261A0]" />,
      },
      {
        label: 'Delete',
        onClick: handleDeleteService,
        icon: <Delete className="text-[#5261A0]" />,
      },
    ];
    return baseActions;
  };

  return (
    <>
      <RoundedBox className="p-4 border-2 border-[#5261A0] rounded-xl mb-4 text-center">
        <Typography variant="h6" className="text-black" sx={{ fontSize: 14 }}>NOTE: Only drafts saved in the last 30 days will be displayed.</Typography>
      </RoundedBox>
      <div className="flex flex-col space-y-4">
        <FilterServicesSearchBar onSearchChange={handleSearchChange} />
        <FilterServicesPanel onFilterChange={handleFilterChange} />
      </div>
      <CustomTable
        rows={services.map((service) => ({
          ...service,
          actions: actions(),
        }))}
        columns={columns}
        loading={loading}
      />
      <Box className="flex flex-col w-full">
        <Link
          to="/file-case"
          className="bg-[#E7ECF8] w-[405px] text-[#5261A0] rounded-full text-center mx-auto text-base py-2 px-5 hover:bg-[#D0D8EE]"
        >
          Submit New E-Service
        </Link>
      </Box>
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            width: '430px',
            height: '230px',
            position: 'relative',
          }
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <DialogContentText sx={{ textAlign: 'left', mb: 3 }}>
            Are you sure you want to <b>delete</b> this draft?
          </DialogContentText>
          <DialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'right', gap: 2 }}>
            <Button
              onClick={() => setOpenDialog(false)}
              sx={{
                color: '#5261A0',
                fontWeight: 700,
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDelete}
              sx={{
                color: '#F23131',
                fontWeight: 700,
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              Delete
            </Button>
          </Box>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DraftEServicesTab;
