import { ReactElement, useEffect, useState } from "react";
import { WizardStep } from "../../../components/StepsWizard";
import { Case, Party } from "../../../utils/utils";
import { FormButton, FormButtonLight } from "../../../components/forms/FormButton";
import RoundedBox from "../../../components/layout/RoundedBox";
import FormLabel from "../../../components/forms/FormLabel";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/auth";
import { useMessageBoard } from "../../../components/assets/MessageBoard";
import { CaseDetails } from "../CaseDetails";

export const SummaryStep = ({ step, fcase, saveFcase, fetchFcase, setShowPrevButton, setShowNextButton, goToStep, ...props }: { step: WizardStep, fcase: Case, saveFcase: Function, fetchFcase: Function, setShowPrevButton: Function, setShowNextButton: Function, goToStep: Function }): ReactElement => {

  const {axios} = useAuth({middleware: 'auth'});

  const navigate = useNavigate();

  const [showFees, setShowfees] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { addMessageError } = useMessageBoard();

  useEffect(() => {
    if(step.current) {
      setShowNextButton(false)
      setShowfees(true);
    } else {
      setShowNextButton(true)
      setShowfees(false);
    }
  }, [step.current]);

  // useEffect(() => {
  //   if(step.current) {
  //     setShowNextButton(false)
  //     setShowfees(true);
  //     return () => {
  //       setShowNextButton(true)
  //       setShowfees(false);
  //     }
  //   }
  // }, [step.current]);

  const onFCaseChange = async () => {
    return fetchFcase();
  }

  const submitCase = async () => {
    const errors = [];
    if(!fcase.parties.find((party: Party) => party.filing_party)) {
      errors.push('Please select a filing party');
    }
    if(!fcase.payment_account_id) {
      errors.push('Please select a payment account');
    }
    if(!fcase.attorney_id) {
      errors.push('Please select an attorney');
    }
    if(errors.length) {
      errors.forEach((error: any) => addMessageError(error))
      return;
    }
    try {
      await axios.post(`filing-cases/${fcase.id}/submit`);
      fetchFcase();
      setIsSubmitted(true)
    } catch(e) {
      console.log(e)
    }
  }

  const onCaseInformationEdit = () => {
    goToStep(1)
  }

  const onPartiesEdit = () => {
    goToStep(2)
  }

  const onFilingsEdit = () => {
    goToStep(3)
  }

  return <RoundedBox className="bg-white">
    {
      !isSubmitted && <>
        <div className="flex border-b-gray-300 border-b-1 pb-2">
          <div className="flex-1 flex flex-col justify-center">
            <FormLabel>
              Filing Summary
            </FormLabel>
          </div>
          <div className="flex-initial">
            <FormButton className="px-20" onClick={submitCase}>
              Submit File
            </FormButton>
          </div>
        </div>
        <CaseDetails {...{fcase, goToStep, showFees, onFCaseChange, fetchFcase, onCaseInformationEdit, onPartiesEdit, onFilingsEdit, onServiceContactsEdit: true, type: "new"}} />
      </>
    }
    {
      isSubmitted && <div className="p-20 text-center">
        <h2 style={{ maxWidth: '800px' }} className="m-auto text-base-blue text-2xl">
          Congratulations!
          <br/>
          Your E-Filing has been successfully submitted
        </h2>
        {
          fcase?.case_number && <p className="text-lg">
              Envelope number: {fcase.case_number}
          </p>
        }
        <img style={{ minWidth: '300px', maxWidth: '600px' }} src="/images/filing-submitted.svg" className="m-auto my-20 w-1/4" />
        <div className="flex gap-4 w-1/4 m-auto">
          <FormButtonLight className="flex-1" onClick={() => navigate('/my-filings')}>
            My filings
          </FormButtonLight>
          <FormButton className="flex-1" onClick={() => navigate('/file-case')} >
            Start a New Filing
          </FormButton>
        </div>
      </div>
    }
  </RoundedBox>
}

export default SummaryStep;