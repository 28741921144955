import { ReactElement } from 'react';
import RoundedBox from './layout/RoundedBox';
import MFRLogo from './assets/MFRLogo';
import { Link } from "react-router-dom";

const NoMatch = (): ReactElement => {
  return <div className="grid h-screen w-screen">
    <RoundedBox className="bg-background-overlay-blue m-4 flex items-center">
      <div className="flex-1 text-center">
        <Link to={"/"} className="m-auto mb-4 inline-block">
          <MFRLogo className="w-32" />
        </Link>
        <p className='text-base-blue text-3xl'>
          <Link to={"/"} className="text-base-blue hover:underline">
            Page Not Found
          </Link>
        </p>
        <p className='text-base-blue mt-2'>
          <Link to={"/"} className="text-gray-400 hover:underline">
            Go to home page
          </Link>
        </p>
      </div>
    </RoundedBox>
  </div>
};

export default NoMatch;