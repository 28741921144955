import { ReactElement, useEffect } from "react";
import { selectObjectByDot } from "../../utils/utils";

const FormError = ({name, formik, className = ''}: any): ReactElement => {

  const selectError = () => {
    const error = selectObjectByDot(formik?.errors, name);

    return typeof error === 'string' ? error : undefined;
  }

  return <>
  {
    selectObjectByDot(formik?.touched, name) && selectError() && <p className={`form-error text-red-700 mt-2 ${className}`}>
      {selectError()}
    </p>
  }
  </>
}
export default FormError;