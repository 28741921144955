import { Tab, Tabs } from "@mui/material";
import { ReactElement } from "react";
import { styled } from "@mui/system";
import { useSearchParams } from "react-router-dom";
import PrivateLayout from "../../components/layout/PrivateLayout";
import RoundedBox from "../../components/layout/RoundedBox";
import ReceivedEServicesTab from "../../components/services/ReceivedEServicesTab";
import SentEServicesTab from "../../components/services/SentEServicesTab";
import DraftEServicesTab from "../../components/services/DraftEServicesTab";

const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 600,
  textTransform: "capitalize",
  borderRadius: "8px",
  transition: "color 0.3s, background-color 0.3s, box-shadow 0.3s",
  "&.Mui-selected": {
    color: "#5261A0",
    backgroundColor: "white",
    boxShadow: "0px 5px 20px 0px #0000000D",
  },
  "&:not(.Mui-selected)": {
    color: "#7C7B84",
  },
}));

type TabValue = 'received' | 'sent' | 'draft';

const MyEServicesScreen = (): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = (searchParams.get("tab") as TabValue) || 'received';

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const tabValues: TabValue[] = ['received', 'sent', 'draft'];
    setSearchParams({ tab: tabValues[newValue] });
  };

  const getTabIndex = (value: TabValue): number => {
    const tabValues: TabValue[] = ['received', 'sent', 'draft'];
    return tabValues.indexOf(value);
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case 'received':
        return <ReceivedEServicesTab />;
      case 'sent':
        return <SentEServicesTab />;
      case 'draft':
        return <DraftEServicesTab />;
      default:
        return <ReceivedEServicesTab />;
    }
  };

  return (
    <PrivateLayout>
      <RoundedBox color="normal">
        <RoundedBox className="bg-white px-10 py-12">
          <Tabs
            value={getTabIndex(currentTab)}
            onChange={handleTabChange}
            centered
            variant="fullWidth"
            className="bg-[#F0F2F5] p-2 rounded-2xl mb-10"
            TabIndicatorProps={{
              style: { display: "none" },
            }}
          >
            <StyledTab label="Received E-Services" />
            <StyledTab label="Sent E-Services" />
            <StyledTab label="Draft E-Services" />
          </Tabs>
          <div className="tab-content">{renderTabContent()}</div>
        </RoundedBox>
      </RoundedBox>
    </PrivateLayout>
  );
};

export default MyEServicesScreen;
