import { DateTime } from "luxon"

export const dateHour = (date: Date) => {
  return DateTime.fromJSDate(date).toFormat('HH:mm, dd/LL/yyyy');
}

export const mockupListMaker = (type: string, n: number = 10, id: string = 'id', name: string = 'name'): any[] => {
  const list: any[] = [];
  for (let i = 1; i <= n; i++) {
    let item: any = {};
    item[id] = i;
    item[name] = `${type.charAt(0).toUpperCase() + type.slice(1)} ${i}`
    list.push(item);
  }
  return list;
}

const EMAIL_STR = '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}';
export const VALIDATION_EMAIL = new RegExp('^' + EMAIL_STR + '$');
export const VALIDATION_EMAIL_MULTIPLE = new RegExp('^(\\s*' + EMAIL_STR + '\\s*,)*\\s*(' + EMAIL_STR + ')\\s*$');
export const VALIDATION_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]|.*?[#?!@$%^&*-]).{8,}$/
export const VALIDATION_PHONE = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const VALIDATION_ZIP_CODE = /(^\d{5}(-\d{4})?$)/;
export const VALIDATION_CREDIT_CARD_NUMBER = /^\d{4}[ -]*\d{4}[ -]*\d{4}[ -]*\d{4}$/;

export type ResourceType = 'procinitial' | 'procsubsecuent' | 'damageinitial' | 'damagesubsecuent';
export type ResourceTypeMandatory = 'Avaliable' | 'Not Avaliable' | 'Required';
export interface CaseCategory {
  id: number
  category: string
  procedure_remedy_initial: ResourceTypeMandatory
  damage_amount_initial: ResourceTypeMandatory
  procedure_remedy_subsequent: ResourceTypeMandatory
  damage_amount_subsequent: ResourceTypeMandatory
}
export const FIXED_CATEGORY_RESOURCES: ResourceType[] = ['procinitial', 'procsubsecuent', 'damageinitial', 'damagesubsecuent'];

export const humanBytes = (bytes: number) => {
  const units: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let n: number = bytes;
  let n_next: number = 0;
  let unit: string = '';
  let base = 1
  for(let i in units) {
    unit = units[i]
    base *= 1024
    n_next = bytes / base
    if(n_next < 1 || parseInt(i) == units.length - 1) {
      break;
    }
    n = n_next
  }
  return `${n.toFixed(2)} ${unit}`;
}

export const objectToQuery = (object: any) => {
  return new URLSearchParams(object).toString()
}

export const checkChanged = (original: any, newData: any, fields: string[] = []) => {
  if(fields.length === 0) {
    for(let i in original) {
      fields.push(i);
    }
  }
  for(let field of fields) {
    if(original[field] != newData[field]) {
      return true;
    }
  }
  return false;
}

// -----------------------
export interface Location {
  code: string
  name: string
}
export interface CaseCategory {
  code: string
  name: string
}
export interface CaseType {
  code: string
  name: string
  case_category_id: number
  case_category?: any
}
export interface Attorney {
  id: number
  user: any
}
export interface FilerType {
  id: number
  code: string
  name: string
}
// -----------------------
export interface Case {
  id?: number
  location_id?: number
  case_type_id?: number

  title: string
  case_category_id?: number
  filer_type_id?: number
  attorney_id?: number
  payment_account_id?: number

  damage_amount_id?: number
  procedure_remedy_id?: number[],

  initial: boolean

  parties: Party[]
  filing_files: Filing[]
  filing_case_procedure_remedies: any[]
  service_contacts: any[]

  location?: Location
  case_category?: CaseCategory
  case_type?: CaseType
  attorney?: Attorney
  payment_account?: any
  case_number?: string
  filer_type?: FilerType
}

export interface Party {
  id?: number
  filing_case_id?: number
  party_type_id?: number
  first_name: string
  last_name: string
  attorney_id?: number
  
  business_name: string

  business_type?: string,
  middle_name: string
  name_suffix_id?: number
  phone: string

  street_address_1?: string
  street_address_2?: string
  city?: string
  state_id?: number
  zip_code?: string
  country?: number
  state?: {
    id: number
    mfr_country_id: number
  }
  representing_party?: boolean
  filing_party?: boolean

  party_type?: any
}

export interface Filing {
  id?: number
  filing_code_id?: number
  description: string

  title?: string // veeer
  reference_number?: string // veer

  client_number?: string
  courtesy_copies?: string

  instructions?: string
  preliminary_copies?: string
  display_order?: number

  components: {[key: string]: FilingComponent}
  filing_file_optional_services: any[]
}

export interface FilingDocument {
  id?: number
  name: string
  mime: string
  size: number
}

export interface FilingComponent {
  id: number
  code: string
  name: string
  required: boolean
  allow_multiple: boolean
  allowed_file_types: string[]

  files: FilingDocument[]
}

export interface File {
  name: string
  size: number
  url?: string
}

export const EMPTY_CASE: Case = {
  location_id: undefined,
  case_type_id: undefined,

  title: '',
  case_category_id: undefined,
  filer_type_id: undefined,
  attorney_id: undefined,
  payment_account_id: undefined,

  damage_amount_id: undefined,
  procedure_remedy_id: [],

  initial: true,

  parties: [],
  filing_files: [],
  filing_case_procedure_remedies: [],
  service_contacts: [],
}

export const currency = (amount: number) => `\$${amount.toFixed(2)}`

export const makeName = (entity: any) => {
  if(!entity) {
    return '';
  }
  if(!!entity.business_type && entity.business_type == 'Business') {
    return entity.business_name;
  }
  return entity.first_name + (entity.middle_name ? ` ${entity.middle_name}` : '') + (entity.last_name ? ` ${entity.last_name}` : '');
}

export const DEFAULT_TYLER_US_COUNTRY_ID:number = parseInt(process.env.REACT_APP_DEFAULT_TYLER_US_COUNTRY_ID)
export const DEFAULT_JOURNALTECH_US_COUNTRY_ID:number = parseInt(process.env.REACT_APP_DEFAULT_JOURNALTECH_US_COUNTRY_ID)

export const selectObjectByDot = (obj: any, dot: string) => {
  if(!obj) {
    return undefined;
  }
  const dots = dot.split('.');
  let result = obj;
  for(const d of dots) {
    if(typeof result[d] == 'undefined') {
      return undefined;
    }
    result = result[d];
  }
  return result;
}

export const getCurrentApiUrl = () : string => {
  try {
    const urlsMap = JSON.parse(process.env.REACT_APP_API_URL)
    if(urlsMap[window.location.hostname]) {
      return urlsMap[window.location.hostname];
    }
    return urlsMap[Object.keys(urlsMap)[0]]
  } catch(e) {
  }
  return process.env.REACT_APP_API_URL;
}

/**
 * Generic type to make all properties in an object optional
 */
export type PartialRecord<T> = {
  [P in keyof T]?: T[P] | null;
};

/**
 * Transforms any object type into URL query parameters
 * @param params Generic object containing parameter key-value pairs
 * @returns URLSearchParams string with leading '?' if params exist
 */
export const toQueryParams = <T extends Record<string, any>>(params: PartialRecord<T>): string => {
  const searchParams = new URLSearchParams();
  
  Object.entries(params).forEach(([key, value]) => {
    if (value != null) {
      if (Array.isArray(value)) {
        value.forEach(item => {
          if (item != null) {
            searchParams.append(key, item.toString());
          }
        });
      } else {
        searchParams.append(key, value.toString());
      }
    }
  });
  
  const queryString = searchParams.toString();
  return queryString ? `?${queryString}` : '';
};
