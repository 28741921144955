import { createSlice } from "@reduxjs/toolkit"
import store from "../../store/store"

export const caseWizard = createSlice({
  name: 'caseWizard',
  initialState: {
    courts: [],
    categories: [],
    filerTypes: [],
    procedureRemedies: [],
    damageAmounts: [],
    paymentAccounts: [],
    caseTypes: [],
    partyTypes: [],
    attorneys: [],
    suffixes: [],
    states: [],
    countries: [],
    filingCodes: [],
  },
  reducers: {
    setCourts: (state: any, payload: { payload: any[] }) => {
      state.courts = payload.payload
    },
    setCategories: (state: any, payload: { payload: any[] }) => {
      state.categories = payload.payload
    },
    setFilerTypes: (state: any, payload: { payload: any[] }) => {
      state.filerTypes = payload.payload
    },
    setProcedureRemedies: (state: any, payload: { payload: any[] }) => {
      state.procedureRemedies = payload.payload
    },
    setDamageAmounts: (state: any, payload: { payload: any[] }) => {
      state.damageAmounts = payload.payload
    },
    setCaseTypes: (state: any, payload: { payload: any[] }) => {
      state.caseTypes = payload.payload
    },
    setPaymentAccounts: (state: any, payload: { payload: any[] }) => {
      state.paymentAccounts = payload.payload
    },
    setPartyTypes: (state: any, payload: { payload: any[] }) => {
      state.partyTypes = payload.payload
    },
    setAttorneys: (state: any, payload: { payload: any[] }) => {
      state.attorneys = payload.payload
    },
    setSuffixes: (state: any, payload: { payload: any[] }) => {
      state.suffixes = payload.payload
    },
    setStates: (state: any, payload: { payload: any[] }) => {
      state.states = payload.payload
    },
    setCountries: (state: any, payload: { payload: any[] }) => {
      state.countries = payload.payload
    },
    setFilingCodes: (state: any, payload: { payload: any[] }) => {
      state.filingCodes = payload.payload
    },
    setConfigs: (state: any, payload: { payload: any[] }) => {
      state.configs = payload.payload
    },
  },
})

// -------------------------------------------------

export const setCourts = (courts: any[]) => {
  store.dispatch(caseWizard.actions.setCourts(courts))
}

export const setCategories = (categories: any[]) => {
  store.dispatch(caseWizard.actions.setCategories(categories))
}

export const setFilerTypes = (filerTypes: any[]) => {
  store.dispatch(caseWizard.actions.setFilerTypes(filerTypes))
}

export const setProcedureRemedies = (procedureRemedies: any[]) => {
  store.dispatch(caseWizard.actions.setProcedureRemedies(procedureRemedies))
}

export const setDamageAmounts = (damageAmounts: any[]) => {
  store.dispatch(caseWizard.actions.setDamageAmounts(damageAmounts))
}

export const setCaseTypes = (caseTypes: any[]) => {
  store.dispatch(caseWizard.actions.setCaseTypes(caseTypes))
}

export const setPaymentAccounts = (paymentAccounts: any[]) => {
  store.dispatch(caseWizard.actions.setPaymentAccounts(paymentAccounts))
}

export const setPartyTypes = (partyTypes: any[]) => {
  store.dispatch(caseWizard.actions.setPartyTypes(partyTypes))
}

export const setAttorneys = (attorneys: any[]) => {
  store.dispatch(caseWizard.actions.setAttorneys(attorneys))
}

export const setSuffixes = (suffixes: any[]) => {
  store.dispatch(caseWizard.actions.setSuffixes(suffixes))
}

export const setStates = (states: any[]) => {
  store.dispatch(caseWizard.actions.setStates(states))
}

export const setCountries = (countries: any[]) => {
  store.dispatch(caseWizard.actions.setCountries(countries))
}

export const setFilingCodes = (filingCodes: any[]) => {
  store.dispatch(caseWizard.actions.setFilingCodes(filingCodes))
}

export const setConfigs = (configs: any) => {
  store.dispatch(caseWizard.actions.setConfigs(configs))
}

// -------------------------------------------------

export const selectCourts = (state: any) => state.caseWizard.courts

export const selectPaymentAccounts = (state: any) => state.caseWizard.paymentAccounts

export const selectCategories = (state: any) => state.caseWizard.categories

export const selectFilerTypes = (state: any) => state.caseWizard.filerTypes

export const selectDamageAmounts = (state: any) => state.caseWizard.damageAmounts

export const selectCaseTypes = (state: any) => state.caseWizard.caseTypes

export const selectProcedureRemedies = (state: any) => state.caseWizard.procedureRemedies

export const selectPartyTypes = (state: any) => state.caseWizard.partyTypes

export const selectAttorneys = (state: any) => state.caseWizard.attorneys

export const selectSuffixes = (state: any) => state.caseWizard.suffixes

export const selectStates = (state: any) => state.caseWizard.states

export const selectCountries = (state: any) => state.caseWizard.countries

export const selectFilingCodes = (state: any) => state.caseWizard.filingCodes

export const selectConfigs = (state: any) => state.caseWizard.configs