import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ViewPaymentAccountDialogProps {
  open: boolean;
  onClose: () => void;
  account: any;
}

const ViewPaymentAccountDialog: React.FC<ViewPaymentAccountDialogProps> = ({
  open,
  onClose,
  account,
}) => {
  if (!account) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ "& .MuiDialog-paper": { padding: "45px" } }}
    >
      <DialogTitle
        sx={{
          color: "#5261A0",
          fontSize: "35px",
          marginBottom: "32px",
        }}
      >
        Payment Account Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8, color: "#5261A0" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ marginBottom: "32px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84", fontWeight: "bold" }}>
              Payment Account Name
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84" }}>{account.name}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84", fontWeight: "bold" }}>
              Payment Account Type
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84" }}>{account.type}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84", fontWeight: "bold" }}>
              Card Holder Name
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84" }}>
              {account.cardHolderName}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84", fontWeight: "bold" }}>
              Card Number
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84" }}>
              {account.cardNumber}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84", fontWeight: "bold" }}>
              Expires on
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ color: "#7C7B84" }}>
              {account.expirationDate}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: "#5261A0",
            color: "white",
            textTransform: "capitalize",
            paddingX: 6,
            "&:hover": {
              backgroundColor: "#40518A", // Slightly darker shade on hover
            },
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewPaymentAccountDialog;
