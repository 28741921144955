import React from "react";
import { IMapping } from "../../../interfaces";

interface Props {
    status: string;
    type: 'borderLess' | 'bgLess';
}

const bgColors: IMapping = {
    'Failed': 'bg-red-500',
    'Submitting':'bg-sky-600',
    'Submitted': 'bg-amber-400',
    'Accepted':'bg-lime-600',
    'Rejected': 'bg-red-500',
}
const borderColors: IMapping = {
    'Failed': 'border-red-500',
    'Submitting':'border-sky-600',
    'Submitted': 'border-amber-400',
    'Accepted':'border-lime-600',
    'Rejected': 'border-red-500',
}
const textColors: IMapping = {
    'Failed': 'text-red-500',
    'Submitting':'text-sky-600',
    'Submitted': 'text-amber-400',
    'Accepted':'text-lime-600',
    'Rejected': 'text-red-500',
}

const StatusChip = ({ status, type }: Props) => {
    let style = '';
    switch(type) {
        case 'bgLess':
            style = `bg-none border ${borderColors[status]} ${textColors[status]}`;
            break;
        
        case 'borderLess':
            style = `${bgColors[status]} text-white`;
            break;
    };
    return (
        <span className={`px-2 py-1 font-semibold rounded-xl ${style}`}>
            {status}
        </span>
    );
};

export default React.memo(StatusChip);