import React from 'react';
import { Box, Button, Grid, Typography, TextField, MenuItem, Checkbox } from '@mui/material';

const SectionHeader = ({ title, hasAddButton = false, hasEditButton = false }) => (
  <Box sx={{ 
    backgroundColor: '#E7EDFF', 
    px: 2,
    py: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2
  }}>
    <Typography sx={{ fontWeight: 500, fontSize: 14 }}>{title}</Typography>
    <Box>
      {hasAddButton && (
        <Button
          size="small"
          sx={{
            backgroundColor: '#5261A0',
            color: 'white',
            textTransform: 'none',
            fontSize: 12,
            mr: 1,
            '&:hover': { backgroundColor: '#3e4d7a' }
          }}
        >
          Add new
        </Button>
      )}
      {hasEditButton && (
        <Button
          size="small"
          sx={{
            minWidth: 'auto',
            p: 0.5,
            color: '#5261A0',
          }}
        >
          <svg width="16" height="16" viewBox="0 0 24 24">
            <path fill="currentColor" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
          </svg>
        </Button>
      )}
    </Box>
  </Box>
);

const mockData = {
  caseInformation: {
    filerType: 'Not Applicable',
    location: '4th Court of Appeals',
    caseCategory: 'Civil - Contract',
    caseType: 'Repair and Remedy',
    filingAttorney: 'Texas Attorney',
    paymentAccount: 'Test TX prod',
    dateFiled: '2024-03-05',
    damageSought: 'Only non-monetary relief'
  },
  parties: [
    { filingParty: false, partyType: 'Plaintiff', name: 'SwiftTech v. CyberSec Co.' },
    { filingParty: true, partyType: 'Defendant', name: 'SwiftTech v. CyberSec Co.' }
  ],
  filings: [
    { filingType: 'Request', description: 'Request', referenceNumber: '8392' }
  ],
  serviceContacts: [
    { name: 'Lily Smith', firmOrganization: 'Nexus Legal', email: 'lilysmith@nexuslegal.com' },
    { name: 'Jane Doe', firmOrganization: 'Nexus Legal', email: 'janedoe@nexuslegal.com' }
  ],
  fees: {
    filingFee: 0.00,
    totalThisFiling: 0.00,
    caseInitiationFee: 54.00,
    totalServiceFees: 0.00,
    totalServiceTaxFees: 0.00,
    convenienceFee: 1.75,
    fileRunnerServiceFees: 6.00,
    fileRunnerTaxFees: 0.50,
    totalCourtServiceFees: 0.00,
    totalMailServiceFees: 0.00,
    totalRedactionFees: 0.00,
    envelopeTotal: 62.25
  }
};

const EditEServiceDraftForm = ({ draftId, onSuccess }) => {
  const [data, setData] = React.useState(mockData);

  const CaseInfoRow = ({ label, value, index }) => (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      p: 2,
      backgroundColor: index % 2 === 0 ? '#F7F9FC' : 'transparent'
    }}>
      <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>{label}</Typography>
      <Typography sx={{ fontSize: 14 }}>{value}</Typography>
    </Box>
  );

  return (
    <Box className="p-6 bg-white rounded-lg">
      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={6}>
          <Box className="space-y-6">
            {/* Case Information Section */}
            <Box>
              <SectionHeader title="Case Information" />
              <Box className="space-y-4">
                <Box sx={{ mb: 4 }}>
                  <Typography sx={{ fontSize: 14, color: 'text.secondary', mb: 1 }}>Filer Type</Typography>
                  <TextField
                    select
                    fullWidth
                    value={data.caseInformation.filerType}
                    variant="outlined"
                    SelectProps={{
                      sx: { 
                        backgroundColor: 'white',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0E0E0',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0E0E0',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#5261A0',
                        },
                      }
                    }}
                  >
                    <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                  </TextField>
                </Box>
                
                <Box className="space-y-2">
                  <CaseInfoRow 
                    label="Location"
                    value={data.caseInformation.location}
                    index={1}
                  />
                  <CaseInfoRow 
                    label="Case Category"
                    value={data.caseInformation.caseCategory}
                    index={2}
                  />
                  <CaseInfoRow 
                    label="Case Type"
                    value={data.caseInformation.caseType}
                    index={3}
                  />
                  <CaseInfoRow 
                    label="Filing Attorney"
                    value={data.caseInformation.filingAttorney}
                    index={4}
                  />
                  <CaseInfoRow 
                    label="Payment Account"
                    value={data.caseInformation.paymentAccount}
                    index={5}
                  />
                  <CaseInfoRow 
                    label="Date Filed"
                    value={data.caseInformation.dateFiled}
                    index={6}
                  />
                  <CaseInfoRow 
                    label="Damage Sought"
                    value={data.caseInformation.damageSought}
                    index={7}
                  />
                </Box>
              </Box>
            </Box>

            {/* Parties Section */}
            <Box>
              <SectionHeader title="Parties" hasAddButton hasEditButton />
              <Box>
                <Box sx={{ display: 'flex', p: 2, borderBottom: '1px solid #E0E0E0' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: 'text.secondary', fontSize: 14, fontWeight: 700 }}>Filing Party</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: 'text.secondary', fontSize: 14, fontWeight: 700 }}>Party Type</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: 'text.secondary', fontSize: 14, fontWeight: 700 }}>Name</Typography>
                  </Box>
                </Box>
                <Box className="space-y-2">
                  {data.parties.map((party, index) => (
                    <Box key={index} sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: 2,
                      backgroundColor: index % 2 === 0 ? '#F7F9FC' : 'transparent'
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                        <Checkbox checked={party.filingParty} size="small" sx={{ mr: 2 }} />
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={{ fontSize: 14 }}>{party.partyType}</Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={{ fontSize: 14 }}>{party.name}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>

            {/* Filings Section */}
            <Box>
              <SectionHeader title="Filings" hasAddButton hasEditButton />
              <Box>
                <Box sx={{ display: 'flex', p: 2, borderBottom: '1px solid #E0E0E0' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: 'text.secondary', fontSize: 14, fontWeight: 700 }}>Filing Type</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: 'text.secondary', fontSize: 14, fontWeight: 700 }}>Description</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: 'text.secondary', fontSize: 14, fontWeight: 700 }}>Reference Number</Typography>
                  </Box>
                </Box>
                <Box className="space-y-2">
                  {data.filings.map((filing, index) => (
                    <Box key={index} sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: 2,
                      backgroundColor: index % 2 === 0 ? '#F7F9FC' : 'transparent'
                    }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={{ fontSize: 14 }}>{filing.filingType}</Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={{ fontSize: 14 }}>{filing.description}</Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={{ fontSize: 14 }}>{filing.referenceNumber}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>

            {/* Service Contacts Section */}
            <Box>
              <SectionHeader title="Service Contacts" hasAddButton hasEditButton />
              <Box>
                <Box sx={{ display: 'flex', p: 2, borderBottom: '1px solid #E0E0E0' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: 'text.secondary', fontSize: 14, fontWeight: 700 }}>Name</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: 'text.secondary', fontSize: 14, fontWeight: 700 }}>Firm Organization</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ color: 'text.secondary', fontSize: 14, fontWeight: 700 }}>Email</Typography>
                  </Box>
                </Box>
                <Box className="space-y-2">
                  {data.serviceContacts.map((contact, index) => (
                    <Box key={index} sx={{
                      display: 'flex',
                      alignItems: 'center',
                      p: 2,
                      backgroundColor: index % 2 === 0 ? '#F7F9FC' : 'transparent'
                    }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={{ fontSize: 14 }}>{contact.name}</Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={{ fontSize: 14 }}>{contact.firmOrganization}</Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={{ fontSize: 14 }}>{contact.email}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item xs={6}>
          <Box className="space-y-6">
            {/* Filing Attorney Section */}
            <Box>
              <SectionHeader title="Filing Attorney" />
              <Box>
                <Typography sx={{ fontSize: 14, color: 'text.secondary', mb: 1 }}>Filing Attorney</Typography>
                <TextField
                  select
                  fullWidth
                  value="John Cook"
                  variant="outlined"
                  SelectProps={{
                    sx: { 
                      backgroundColor: 'white',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0E0E0',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0E0E0',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#5261A0',
                      },
                    }
                  }}
                >
                  <MenuItem value="John Cook">John Cook</MenuItem>
                </TextField>
              </Box>
            </Box>

            {/* Fees Section */}
            <Box>
              <SectionHeader title="Fees" />
              <Box className="space-y-2">
                {Object.entries(data.fees).map(([key, value], index) => (
                  <Box key={key} sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 2,
                    backgroundColor: index % 2 === 0 ? '#F7F9FC' : 'transparent'
                  }}>
                    <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>
                      {key.replace(/([A-Z])/g, ' $1').trim().charAt(0).toUpperCase() + key.replace(/([A-Z])/g, ' $1').trim().slice(1)}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>${value.toFixed(2)}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            {/* Payment Section */}
            <Box>
              <SectionHeader title="Payment" />
              <Box>
                <Typography sx={{ fontSize: 14, color: 'text.secondary', mb: 1 }}>Payment Account</Typography>
                <TextField
                  select
                  fullWidth
                  value="Payment Account 1"
                  variant="outlined"
                  SelectProps={{
                    sx: { 
                      backgroundColor: 'white',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0E0E0',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0E0E0',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#5261A0',
                      },
                    }
                  }}
                >
                  <MenuItem value="Payment Account 1">Payment Account 1</MenuItem>
                </TextField>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
        <Button
          variant="contained"
          onClick={onSuccess}
          sx={{
            backgroundColor: "#5261A0",
            textTransform: "none",
            px: 4,
            '&:hover': { backgroundColor: "#3e4d7a" }
          }}
        >
          Serve into this case
        </Button>
      </Box>
    </Box>
  );
};

export default EditEServiceDraftForm;
