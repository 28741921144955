import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  Button,
} from "@mui/material";
import { useDebounce } from "../../../hooks/useDebounce";
import { UserFilters } from "../../../hooks/useUsers";
import { GridSearchIcon } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

interface FilterUsersProps {
  onChange: (filters: UserFilters) => void;
}

const FilterUsers: React.FC<FilterUsersProps> = ({ onChange }) => {
  const [search, setSearch] = useState<UserFilters["search"]>("");
  const [role, setRole] = useState<UserFilters["type"]>("all");
  const navigation = useNavigate();

  const debouncedSearch = useDebounce(search, 500);
  const debouncedRole = useDebounce(role, 500);

  useEffect(() => {
    onChange({ search: debouncedSearch, role: debouncedRole });
  }, [debouncedSearch, debouncedRole]);

  const handleCreateUser = () => {
    navigation("/admin-dashboard/user-management/create-user");
  };

  // Roles for filtering
  const roles = {
    all: "All Roles",
    attorney: "Attorney",
    admin: "Firm Admin",
    filer: "Filer",
  };

  return (
    <div>
      <TextField
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search by Name, Email ID, Bar Number..."
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <div className="bg-[#5261A0] p-2 rounded-full">
                <GridSearchIcon className="text-white" />
              </div>
            </InputAdornment>
          ),
          classes: {
            notchedOutline: "border-none",
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "50px",
            marginBottom: "16px",
          },
        }}
        className="w-full text-black"
      />
      <div className="flex justify-between items-center w-full mb-4">
        <div className="flex items-center space-x-2 rounded-full px-4 border-1 max-h-10">
          <span className="font-bold text-base text-[#757575]">Show:</span>
          <FormControl variant="outlined">
            <Select
              labelId="role-label"
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
              }}
              sx={{
                borderRadius: "50px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              {Object.entries(roles).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#5261A0",
            color: "white",
            textTransform: "none",
          }}
          onMouseDown={handleCreateUser}
        >
          Add New User
        </Button>
      </div>
    </div>
  );
};

export default FilterUsers;
