import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import { useDebounce } from '../../../hooks/useDebounce';

interface FilterFilingsSearchBarProps {
  onSearchChange: (searchTerm: string) => void;
}

const FilterFilingsSearchBar: React.FC<FilterFilingsSearchBarProps> = ({ onSearchChange }) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    onSearchChange(debouncedSearch);
  }, [debouncedSearch, onSearchChange]);

  return (
    <TextField
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      placeholder="Search by Client Matter N, Case N, Case Title, or Court Location..."
      variant="outlined"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div className="bg-[#5261A0] p-2 rounded-full">
              <GridSearchIcon className="text-white" />
            </div>
          </InputAdornment>
        ),
        classes: {
          notchedOutline: 'border-none',
        },
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '50px',
        },
      }}
      className="w-full text-black"
    />
  );
};

export default FilterFilingsSearchBar;
