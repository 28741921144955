import { ReactElement } from "react";

const properties = (props: any) : any => {
  return {
    ...props,
    className: "text-white bg-base-blue rounded-full hover:bg-hovered-light-blue active:bg-active-light-blue inline-block cursor-pointer ml-2" + (props.className ? ` ${props.className}` : ``),
    style: { padding: '2px 15px 0', lineHeight: '20px', ...props.style }
  }
}
export const ButtonAction = ({ children, ...props }: any): ReactElement => <button {...properties(props)}>
  {children}
</button>