import { ReactElement, useEffect, useState } from "react";
import { WizardStep } from "../../../components/StepsWizard";
import { Case, Party } from "../../../utils/utils";
import { FormButton, FormButtonLight } from "../../../components/forms/FormButton";
import RoundedBox from "../../../components/layout/RoundedBox";
import FormLabel from "../../../components/forms/FormLabel";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/auth";
import { useMessageBoard } from "../../../components/assets/MessageBoard";
import { CaseDetails } from "../CaseDetails";
import useConfigs from "../../../hooks/useConfigs";
import { SearchCaseDialogEService } from "../SearchCaseDialog";

export const SummaryStep = ({ step, fcase, saveFcase, fetchFcase, setShowPrevButton, setShowNextButton, goToStep, eService = false, ...props }: { step: WizardStep, fcase: Case, saveFcase: Function, fetchFcase: Function, setShowPrevButton: Function, setShowNextButton: Function, goToStep: Function, eService: boolean}): ReactElement => {

  const {axios} = useAuth({middleware: 'auth'});
  
  const {isRequired, configsReady} = useConfigs();

  const navigate = useNavigate();

  const [showFees, setShowFees] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showSearchCaseDialog, setShowSearchCaseDialog]: [any, Function] = useState(false);

  const { addMessageError } = useMessageBoard();

  useEffect(() => {
    if(step.current) {
      setShowNextButton(false)
      setShowFees(true);
    } else {
      setShowNextButton(true)
      setShowFees(false);
    }
  }, [step.current]);

  const onFCaseChange = async () => {
    return fetchFcase();
  }

  const onFilingsEdit = () => {
    goToStep(0)
  }

  const onServiceContactsEdit = true;
  
  const submitCase = async () => {
    const errors = [];
    if(!fcase.parties.find((party: Party) => party.filing_party)) {
      errors.push('Please select a filing party');
    }
    if(!fcase?.service_contacts.length) {
      errors.push('Please add a service contact');
    }
    if(!fcase?.payment_account_id) {
      errors.push('Please select a payment account');
    }
    if(!fcase?.attorney_id) {
      errors.push('Please select an attorney');
    }
    if(isRequired('FilingFilerType') && !fcase?.filer_type_id) {
      errors.push('Please select an filer type');
    }
    if(errors.length) {
      errors.forEach((error: any) => addMessageError(error))
      return;
    }
    try {
      await axios.post(`filing-cases/${fcase?.id}/submit-e-service`);
      fetchFcase();
      setIsSubmitted(true);
    } catch(e) {
      console.log(e)
    }
  }
  
  if(!configsReady) {
    return <></>;
  }

  return <RoundedBox className="bg-white">
    {
      !isSubmitted && <>
        <div className="flex border-b-gray-300 border-b-1 pb-2">
          <div className="flex-1 flex flex-col justify-center">
            <FormLabel>
              Filing Summary
            </FormLabel>
          </div>
          <div className="flex-initial">
            <FormButton className="px-20" onClick={submitCase}>
              Submit E-Service
            </FormButton>
          </div>
        </div>
        <CaseDetails {...{fcase, goToStep, showFees, onFCaseChange, fetchFcase, onFilingsEdit, onServiceContactsEdit, type: "e-service"}} />
      </>
    }
    {
      isSubmitted && <div className="p-20 text-center">
        <h2 style={{ maxWidth: '800px' }} className="m-auto text-base-blue text-2xl">
          Congratulations!
          <br/>
          Your E-Filing has been successfully submitted
        </h2>
        {
          fcase?.case_number && <p className="text-lg">
              Envelope number: {fcase.case_number}
          </p>
        }
        <img style={{ minWidth: '300px', maxWidth: '600px' }} src="/images/filing-submitted.svg" className="m-auto my-20 w-1/4" />
        <div className="flex gap-4 w-1/3 m-auto">
          <FormButtonLight className="flex-1" onClick={() => navigate('/my-filings')}>
            My filings
          </FormButtonLight>
          <FormButton className="flex-1" onClick={() => setShowSearchCaseDialog(true)} >
            Start a New E-Service
          </FormButton>
        </div>
      </div>
    }
    <SearchCaseDialogEService
      open={showSearchCaseDialog}
      onClose={() => setShowSearchCaseDialog(false)}
    />
  </RoundedBox>
};