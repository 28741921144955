import { Edit } from '@mui/icons-material';
import { useAuth } from '../../hooks/auth';
import useAccount from '../../hooks/useAccount';
import React, { useState, useRef } from 'react';

const ProfileCard = () => {
  const { logout } = useAuth();
  const { profile, loading, uploadProfilePhoto, getProfilePhotoUrl } = useAccount();
  const [showCropModal, setShowCropModal] = useState(false);
  const [cropImage, setCropImage] = useState('');
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);

  const handleEdit = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        try {
          const reader = new FileReader();
          reader.onload = async (event) => {
            setCropImage(event.target?.result as string);
            setShowCropModal(true);
            setPosition({ x: 0, y: 0 });
            setScale(1);
          };
          reader.readAsDataURL(file);
        } catch (error) {
          console.error('Error reading image:', error);
        }
      }
    };
    input.click();
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setStartPos({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - startPos.x,
        y: e.clientY - startPos.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleWheel = (e: React.WheelEvent) => {
    e.preventDefault();
    const delta = e.deltaY * -0.01;
    const newScale = Math.max(0.1, Math.min(3, scale + delta));
    setScale(newScale);
  };

  const handleCrop = async () => {
    const canvas = canvasRef.current;
    const image = imageRef.current;
    
    if (canvas && image) {
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Draw the image with current position and scale
      ctx.save();
      ctx.translate(canvas.width/2, canvas.height/2);
      ctx.scale(scale, scale);
      ctx.drawImage(
        image,
        -image.width/2 + position.x/scale,
        -image.height/2 + position.y/scale
      );
      ctx.restore();

      // Get the cropped image as base64
      const croppedImage = canvas.toDataURL('image/jpeg').split(',')[1];
      await uploadProfilePhoto(croppedImage);
      setShowCropModal(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (loading) {
    return (
      <div className="rounded-lg bg-white p-6 shadow-sm">
        <div className="animate-pulse space-y-4">
          <div className="h-48 w-48 mx-auto rounded-full bg-gray-200" />
          <div className="h-6 w-32 mx-auto bg-gray-200 rounded" />
          <div className="h-4 w-24 mx-auto bg-gray-200 rounded" />
        </div>
      </div>
    );
  }

  const userName = profile ? `${profile.user.first_name} ${profile.user.last_name}` : 'Loading...';
  const userRole = profile?.user.roles[0] || 'User';
  const capitalizedRole = userRole.charAt(0).toUpperCase() + userRole.slice(1);

  return (
    <>
      <div className="rounded-lg bg-white p-6 shadow-sm">
        <div className="relative flex justify-center">
          <img
            src={getProfilePhotoUrl(profile?.user?.profile_photo) || "https://ui-avatars.com/api/?name=" + encodeURIComponent(userName)}
            alt={`${profile?.user?.first_name}'s profile`}
            className="rounded-full mb-4 w-48 h-48 object-cover"
            onError={(e) => {
              const img = e.target as HTMLImageElement;
              img.src = "https://ui-avatars.com/api/?name=" + encodeURIComponent(userName);
            }}
          />
          <button
            onClick={handleEdit}
            className="absolute right-0 p-1 rounded-full text-[#5261A0] hover:text-indigo-800 transition-colors"
          >
            <Edit className="w-5 h-5" />
          </button>
        </div>
        
        <div className="text-center space-y-2">
          <h3 className="font-semibold text-gray-900 text-2xl">
            {userName}
          </h3>
          <p className="text-gray-600">
            {capitalizedRole}
          </p>
        </div>
        <button
          onClick={handleLogout}
          className="w-full mt-8 mb-2 px-4 py-2 bg-[#5261A0] hover:bg-indigo-800 text-white rounded-lg transition-colors"
        >
          Log Out
        </button>
      </div>

      {showCropModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-[600px] mx-4">
            <h3 className="text-xl font-semibold mb-4">Crop Profile Photo</h3>
            <div 
              className="relative w-[500px] h-[500px] overflow-hidden border-2 border-gray-200 rounded-lg mb-4 mx-auto"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              onWheel={handleWheel}
            >
              <div className="absolute inset-0 flex items-center justify-center">
                <img
                  ref={imageRef}
                  src={cropImage}
                  alt="Crop preview"
                  className="max-w-none"
                  style={{
                    transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
                    cursor: isDragging ? 'grabbing' : 'grab'
                  }}
                />
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                <div className="w-80 h-80 rounded-full border-2 border-white ring-8 ring-black ring-opacity-60" />
              </div>
              </div>
            </div>
            <canvas
              ref={canvasRef}
              width={400}
              height={400}
              className="hidden"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowCropModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleCrop}
                className="px-4 py-2 bg-[#5261A0] hover:bg-indigo-800 text-white rounded-lg transition-colors"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileCard;
