import { ReactElement } from "react";
import PrivateLayout from "../../components/layout/PrivateLayout";
import { FileCaseRow } from "./FileToCourt";
import { selectCases } from "../../components/assets/CaseSearch";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RoundedBox from "../../components/layout/RoundedBox";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ButtonAction } from "../../components/table/ButtonAction";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import useFilingCases from "../../hooks/useFilingCases";
import { useMessageBoard } from "../../components/assets/MessageBoard";
import BackArrow from "../../components/BackArrow";

const SearchCaseIndex = ({ fileTarget, viewTarget, className, ...props }: { fileTarget: string, viewTarget: string, className?: string}): ReactElement => {

  const cases = useSelector(selectCases);
  const navigate = useNavigate();
  const { fetchExistingFilingCaseById } = useFilingCases();
  const { addMessageError } = useMessageBoard();

  const fileIntoThisFcase = async (id: string, location_id: number) => {
    try {
      const fcase = await fetchExistingFilingCaseById(id, location_id, true);
      navigate(`${fileTarget}/${fcase?.id}`);
    } catch (e: any) {
      addMessageError(e.message);
    }
  }

  const actions = (params: GridRenderCellParams) => <div className="actions">
    <Link to={`${viewTarget}/${params.row.tracking_id}/${params.row.location_id}`}>
      <RemoveRedEyeOutlinedIcon />
    </Link>
    <ButtonAction onClick={() => fileIntoThisFcase(params.row.tracking_id, params.row.location_id)}>
      File
    </ButtonAction>
  </div>

  const columns: GridColDef[] = [
    { field: 'location', headerName: 'Location', flex: 1.5 },
    { field: 'title', headerName: 'Title', flex: 3 },
    { field: 'docket_id', headerName: 'Case Number', flex: 1 },
    { field: 'case_type', headerName: 'Case Type', flex: 1 },
    { field: 'actions', headerName: 'Actions', flex: 1, renderCell: actions, align: 'right', headerAlign: 'right' },
  ];

  return <RoundedBox className={`bg-white ${className ? className : ''}`}>
    <DataGrid
      className="grid-mfr"
      rows={cases}
      columns={columns}
      rowSelection={false}
      // hideFooterPagination={true}
      getRowId={(row: any) => row?.tracking_id}
    />
  </RoundedBox>
}

export const FileIntoExistingCaseSearchResultScreen = (): ReactElement => {
  return <PrivateLayout>
    <FileCaseRow />
    <SearchCaseIndex
      fileTarget="/file-into-existing-case"
      viewTarget="/file-into-existing-case/case-details"
      className="mt-4"
    />
  </PrivateLayout>
}

export const EServiceCaseSearchResultScreen = (): ReactElement => {
  return <PrivateLayout>
    <BackArrow/>
    <SearchCaseIndex
      fileTarget="/e-service"
      viewTarget="/e-service/case-details"
      className="mt-4"
    />
  </PrivateLayout>
}
