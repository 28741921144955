import { ReactElement, ReactNode, useEffect } from 'react';
import SideBar from './SideBar';
import RoundedBox from './RoundedBox';
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from 'react-router-dom';
import { selectAuth, useAuth } from '../../hooks/auth';
import { useSelector } from 'react-redux';
import { Profile } from '../../repositories/Models';

const PrivateLayout = ({ children = <></> }: { children?: ReactNode }): ReactElement => {
  const location = useLocation();

  const { getUserData } = useAuth({ middleware: 'auth' });
  const user: Profile = useSelector(selectAuth);

  useEffect(() => {
    getUserData();
  }, []);

  const routeVariants = {
    initial: { opacity: 0, scale: 0.92 },
    in: { opacity: 1, scale: 1 },
    out: { opacity: 0, scale: 0.92 },
  }

  if(!user) {
    return <></>
  }

  return <div className="private-layout flex p-4 gap-4 text-base-gray text-md">
    <div className="flex-initial w-64">
      <SideBar />
    </div>

    <div className="flex-1">
      <AnimatePresence>
        <motion.div
          key={`motion-${location.key}`}
          transition={{ type: "tween", ease: "easeIn", duration: 0.2 }}
          animate={"in"}
          initial={"initial"}
          variants={routeVariants}
        >
          <RoundedBox className="flex-1  bg-background-blue">
            {children}
          </RoundedBox>
        </motion.div>
      </AnimatePresence>
    </div>
  </div>
};

export default PrivateLayout;