import { useState, useCallback } from "react";
import { useAuth } from "./auth";
import { Case } from "../utils/utils";

// Updated User interface
export interface User {
  id: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  status?: string;
  is_service_contact?: number;
  barNumber?: string;
  role?: string;
  is_firm_admin?: number;
}

export type UserFilters = {
  search?: string;
  page?: number;
  itemsPerPage?: number;
  sortBy?: string;
};

const useFilingCases = () => {
  const { axios } = useAuth({ middleware: 'auth' });

  const fetchFilingCaseById = async (id: number, full = true): Promise<Case|undefined> => {
    try {
      const options: any = {};
      if(full) {
        options.params = {full: 1};
      }
      return (await axios.get(`/filing-cases/${id}`, options)).data
    } catch (err: any) {
    }
  };

  const fetchExistingFilingCaseById = async (id: string, location_id: number, persist = false): Promise<Case|undefined> => {
    const options: any = {params: {location_id, persist: persist ? 1 : 0}};
    return (await axios.get(`filing-cases/fetch/${id}`, options)).data
  };

  const saveFilingCase = async (fcase: Case): Promise<number> => {
    try {
      if(!fcase?.id) {
        const resultFCase = (await axios.post(`/filing-cases`, fcase)).data;
        return resultFCase.id;
      } else {
        await axios.put(`/filing-cases/${fcase?.id}`, fcase);
      }
    } catch(e) {
    }
    return 0;
  };

  const updateFilingCaseAttorney = async (fcase: Case, attorney_id: number): Promise<any> => {
    return axios.put(`/filing-cases/${fcase?.id}/attorney`, {attorney_id});
  };

  const updateFilingCasePaymentAccount = async (fcase: Case, payment_account_id: number): Promise<any> => {
    return axios.put(`/filing-cases/${fcase?.id}/payment-account`, {payment_account_id});
  };

  const updateFilingCaseFilerCase = async (fcase: Case, filer_type_id: number): Promise<any> => {
    return axios.put(`/filing-cases/${fcase?.id}/filer-type`, {filer_type_id});
  };

  return {
    fetchFilingCaseById,
    fetchExistingFilingCaseById,
    saveFilingCase,
    updateFilingCaseAttorney,
    updateFilingCasePaymentAccount,
    updateFilingCaseFilerCase
  };
};

export default useFilingCases;
