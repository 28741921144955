import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from 'yup';
import UserAccessLayout from "../../components/layout/UserAccessLayout";
import { useAuth } from "../../hooks/auth";
import { useMessageBoard } from "../../components/assets/MessageBoard";
import FormPassword from "../../components/forms/FormPassword";
import { FormButton } from "../../components/forms/FormButton";
import { FormLabel } from "@mui/material";
import BackArrow from "../../components/BackArrow";

const PasswordResetScreen = () => {
  const navigate = useNavigate();
  const { addMessageSuccess } = useMessageBoard()
  let [searchParams, setSearchParams] = useSearchParams();
  const { resetPassword } = useAuth();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: yup.object().shape({
      password: yup.string().required('Password is required').min(8, 'Password needs at least 8 chars'),
      confirmPassword: yup.string().required('Password is required').min(8, 'Password needs at least 8 chars').oneOf([yup.ref('password')], 'Passwords must match'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const data = {
          password: values.password,
          password_confirmation: values.confirmPassword,
          token: `${searchParams.get('token')}`,
          email: `${searchParams.get('email')}`
        }
        await resetPassword(data);
        addMessageSuccess('The password was reset');
        navigate('/');
      } catch (e) {

      }
      // navigate('/');
    }
  });

  return <UserAccessLayout>
    <div className="w-full">
      <BackArrow>
        <Link to={"/login"} className="atracking-wider">
          Back to Sign In
        </Link>
      </BackArrow>

      <h2 className="mt-16 mb-6 text-2xl text-extra-large text-base-blue text-start">
        Set a Password
      </h2>
      <p className="text-base text-base-gray font-medium tracking-wider">
        Your previous password has been reseted. Please set a new password.
      </p>
      <form
        className="mt-6 space-y-8 mr-16"
        onSubmit={formik.handleSubmit}>
        <div>
          <FormLabel required>
            Create Password
          </FormLabel>
          <FormPassword
            id="password"
            name="password"
            autoComplete="password"
            placeholder="At least 8 characters"
            required
            formik={formik}
          />
        </div>

        <div>
          <FormLabel required>
            Repeat Password
          </FormLabel>
          <FormPassword
            name="confirmPassword"
            autoComplete="current-password"
            placeholder="Enter your created password"
            required
            formik={formik}
          />
        </div>
        <div className=" text-white font-medium font-base ">
          <FormButton className="w-full">
            Set Password
          </FormButton>
        </div>
      </form>
    </div>
  </UserAccessLayout>
}

export default PasswordResetScreen;