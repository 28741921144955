import { Delete, Edit, Visibility } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useEServices, { ServiceFilters } from '../../hooks/useEServices';
import CustomTable from '../table/CustomTable';
import FilterServicesPanel from './filters/FilterEServicesPanel';
import FilterServicesSearchBar from './filters/FilterEServicesSearchBar';

const SentEServicesTab = () => {
  const { services, loading, fetchServices } = useEServices();
  const navigate = useNavigate();
  const [filters, setFilters] = useState<ServiceFilters>({
    search: '',
    statuses: [],
    envelope: '',
    caseNumber: '',
    clientMatterNumber: '',
    dateFrom: null,
    dateTo: null,
  });

  // Add state for delete dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState<number | null>(null);

  // Fetch services whenever filters change
  useEffect(() => {
    fetchServices(filters);
  }, [filters, fetchServices]);

  // Handlers for search and filters
  const handleSearchChange = useCallback((searchTerm: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: searchTerm,
    }));
  }, []);

  const handleFilterChange = useCallback((newFilters: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  }, []);

  const columns = [
    { field: 'envelope', headerName: 'Envelope N' },
    { field: 'caseNumber', headerName: 'Case N' },
    { field: 'clientMatterNumber', headerName: 'Client Matter N' },
    {
      field: 'serviceStatus',
      headerName: 'E-Service Status',
      colorMapping: {
        Failed: 'red',
        Submitting: '#0096ff',
        Submitted: '#ffcc00',
        Accepted: 'green',
        Rejected: 'red',
      },
    },
    { field: 'lastUpdate', headerName: 'Last Update' },
    { field: 'description', headerName: 'Description' },
  ];

  const handleViewService = (id: number) => {
    navigate(`/sent-service/${id}`);
  };

  const handleEditService = (id: number) => {
    console.log('Editing service with ID: ', id);
  };

  const handleDeleteService = (id: number) => {
    setSelectedServiceId(id);
    setOpenDialog(true);
    setDeleteError(null);
  };

  const handleConfirmDelete = async () => {
    try {
      // Add your delete logic here
      console.log('Deleting service with ID: ', selectedServiceId);
      // After successful deletion:
      setOpenDialog(false);
      // Optionally refresh the services list
      fetchServices(filters);
    } catch (error) {
      setDeleteError(error);
    }
  };

  const actions = (status: string) => {
    const baseActions = [
      {
        label: 'View',
        onClick: handleViewService,
        icon: <Visibility className="text-[#5261A0]" />,
      },
    ];

    if (status === 'Failed') {
      baseActions.push({
        label: 'Edit',
        onClick: handleEditService,
        icon: <Edit className="text-[#5261A0]" />,
      });
    }

    if (status === 'Submitting' || status === 'Drafts') {
      baseActions.push({
        label: 'Delete',
        onClick: handleDeleteService,
        icon: <Delete className="text-[#5261A0]" />,
      });
    }

    return baseActions;
  };

  return (
    <>
      <div className="flex flex-col space-y-4">
        <FilterServicesSearchBar onSearchChange={handleSearchChange} />
        <FilterServicesPanel onFilterChange={handleFilterChange} />
      </div>
      <CustomTable
        rows={services.map((service) => ({
          ...service,
          actions: actions(service.serviceStatus),
        }))}
        columns={columns}
        loading={loading}
      />
      <Box className="flex flex-col w-full">
        <Link
          to="/file-case"
          className="bg-[#E7ECF8] w-[405px] text-[#5261A0] rounded-full text-center mx-auto text-base py-2 px-5 hover:bg-[#D0D8EE]"
        >
          Submit New E-Service
        </Link>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            width: '430px',
            height: '230px',
            position: 'relative',
          }
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <DialogContentText sx={{ textAlign: 'left', mb: 3 }}>
            Are you sure you want to <b>delete</b> this service?
          </DialogContentText>
          <DialogActions>
            <Box sx={{ display: 'flex', justifyContent: 'right', gap: 2 }}>
              <Button
                onClick={() => setOpenDialog(false)}
                sx={{
                  color: '#5261A0',
                  fontWeight: 700,
                  '&:hover': { backgroundColor: 'transparent' },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirmDelete}
                sx={{
                  color: '#F23131',
                  fontWeight: 700,
                  '&:hover': { backgroundColor: 'transparent' },
                }}
              >
                Delete
              </Button>
            </Box>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SentEServicesTab;
