import Close from '@mui/icons-material/Close';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import RoundedBox from '../../layout/RoundedBox';

interface FilingInfo {
  caseName: string;
  courtName: string;
  filingStatus: string;
  envelope: string;
  caseCategory: string;
  caseType: string;
  filingType: string;
  filingTime: string;
  filingDescription: string;
  clientMatterNumber: string;
  filerName: string;
  email: string;
  firmName: string;
  address: string;
  phoneNumber: string;
  accountName: string;
  waiverAccount: string;
  documents: { documentType: string; fileName: string; description: string }[];
  fees: { serviceFees: string; taxFees: string; filingFee: string; filingServicesFee: string; courtFeesTotal: string };
}

const PreviewSection = ({ title, content }: { title: string, content: React.ReactNode }) => (
  <Box>
    <Box sx={{ 
      backgroundColor: '#E7EDFF', 
      px: 2, 
      py: 1
    }}>
      <Typography sx={{ fontWeight: 500, fontSize: 14 }}>{title}</Typography>
    </Box>
    {content}
  </Box>
);

const RenderSection = ({ title, content }: { title: string, content: React.ReactNode }) => (
  <Box sx={{ mb: 3 }}>
    <Box sx={{ 
      backgroundColor: '#E7EDFF', 
      px: 2, 
      py: 1
    }}>
      <Typography sx={{ fontWeight: 500, fontSize: 14 }}>{title}</Typography>
    </Box>
    {content}
  </Box>
);

// Filing Information Section
const FilingInfoSection = ({ filingData }: { filingData: FilingInfo }) => (
  <Box sx={{ 
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  }}>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Type</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingData.filingType}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Submission Time</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingData.filingTime}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Description</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingData.filingDescription}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Client Matter N</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingData.clientMatterNumber}</Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

// Filer Information Section
const FilerInfoSection = ({ filingData }: { filingData: FilingInfo }) => (
  <Box sx={{ 
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  }}>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filer Name</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingData.filerName}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Email</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingData.email}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Firm Name</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingData.firmName}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Address</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingData.address}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Phone Number</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingData.phoneNumber}</Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

// Billing Information Section
const BillingInfoSection = ({ filingData }: { filingData: FilingInfo }) => (
  <Box sx={{ 
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  }}>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Account Name</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingData.accountName}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: '#F7F9FC'
        }}>
          <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Waiver Account</Typography>
          <Typography sx={{ fontSize: 14 }}>{filingData.waiverAccount}</Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

// Documents Section
const DocumentsSection = ( { filingData }: { filingData: FilingInfo } ) => (
  <Grid item xs={12}>  {/* Full width */}
    <Box>
      <Box sx={{ 
        backgroundColor: '#E7EDFF', 
        px: 2, 
        py: 1
      }}>
        <Typography sx={{ fontWeight: 500, fontSize: 14 }}>Documents</Typography>
      </Box>

      <Box>
        {/* Header Row */}
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          backgroundColor: '#F7F9FC',
          py: 2,
          px: 2,
        }}>
          <Typography sx={{ fontSize: 14, color: 'text.secondary', fontWeight: 'bold' }}>Document Type</Typography>
          <Typography sx={{ fontSize: 14, color: 'text.secondary', fontWeight: 'bold'  }}>File Name</Typography>
          <Typography sx={{ fontSize: 14, color: 'text.secondary', fontWeight: 'bold'  }}>Document Description</Typography>
        </Box>

        {/* Document Rows */}
        {filingData.documents.map((doc, index) => (
          <Box key={index} sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            py: 2,
            px: 2,
            backgroundColor: index % 2 === 0 ? 'white' : '#F7F9FC',
          }}>
            <Typography sx={{ fontSize: 14 }}>{doc.documentType}</Typography>
            <Typography sx={{ fontSize: 14 }}>{doc.fileName}</Typography>
            <Typography sx={{ fontSize: 14 }}>{doc.description}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  </Grid>
);

// Fee Information Section
const FilingInformation: React.FC = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Mock data
  const filingData: FilingInfo = {
    caseName: 'SwiftTech v. CyberSec Co.',
    courtName: '4th Court of Appeals',
    filingStatus: 'Submitted',
    envelope: '38294874',
    caseCategory: 'Technology Dispute',
    caseType: 'Contract Breach',
    filingType: 'Legal Complaint',
    filingTime: 'Wed Feb 21, 2024, 03:55:15 PM',
    filingDescription: 'Motion ($10.00)',
    clientMatterNumber: 'CMT-2024-6789',
    filerName: 'Alex Turner',
    email: '4th Court of Appeals',
    firmName: 'Turner Legal',
    address: '1831 Tranquil Lane, Houston, Texas, 77000',
    phoneNumber: '(555) 987-6543',
    accountName: 'Payment Account',
    waiverAccount: 'Yes',
    documents: [
      {
        documentType: 'Lead Document',
        fileName: 'ComplaintDoc.pdf',
        description: 'Motion ($10.00)',
      },
      {
        documentType: 'Attachments',
        fileName: 'WitnessStatements.pdf',
        description: 'Motion ($10.00)',
      },
    ],
    fees: {
      serviceFees: '$0.00',
      taxFees: '$0.00',
      filingFee: '$0.00',
      filingServicesFee: '$0.00',
      courtFeesTotal: '$0.00',
    },
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handlePrintReceipt = () => {
    console.log({filingData})
  }

  return (
    <div className='p-6'>
      <h3 className="text-md mb-4 text-[#5261A0] font-medium">Filing Information:</h3>
      <RoundedBox className="bg-white px-10 py-12">
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <PreviewSection
        title="Case Information"
        content={
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                }}>
                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Location</Typography>
                  <Typography sx={{ fontSize: 14 }}>{filingData.courtName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                  backgroundColor: '#F7F9FC'
                }}>
                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Court | Calendar</Typography>
                  <Typography sx={{ fontSize: 14 }}>{filingData.courtName}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                }}>
                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Envelope | Draft N</Typography>
                  <Typography sx={{ fontSize: 14 }}>{filingData.envelope}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
      />
    </Grid>
    <Grid item xs={6}>
      <PreviewSection
        title="Filing Information"
        content={
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                }}>
                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Type</Typography>
                  <Typography sx={{ fontSize: 14 }}>{filingData.filingType}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                  backgroundColor: '#F7F9FC'
                }}>
                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Submission Time</Typography>
                  <Typography sx={{ fontSize: 14 }}>{filingData.filingTime}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 2,
                }}>
                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Description</Typography>
                  <Typography sx={{ fontSize: 14 }}>{filingData.filingDescription}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
      />
    </Grid>
  </Grid>
</RoundedBox>

        {/* Dialog */}
        {isDialogOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center pt-5 z-50">
            <div className="bg-white rounded-lg w-[1200px] max-h-[95vh] overflow-y-scroll pb-12" 
              style={{ 
                scrollbarWidth: 'none',  // Firefox
                msOverflowStyle: 'none',  // IE/Edge
                '&::-webkit-scrollbar': {  // Chrome/Safari/Webkit
                  display: 'none'
                }
              }}
  >
            {/* Header */}
            <div className="p-6 flex justify-between items-center">
              <div className="flex flex-col items-center gap-4">
                  <img
                    src="/images/logo-header.svg"
                    alt="Logo"
                    className="object-contain w-56"
                  />
                <h2 className="text-md font-bold space-x-2 uppercase text-black tracking-[.7em]">Filing Receipt</h2>
              </div>
              <Button
                  onClick={closeDialog}
                  sx={{
                    backgroundColor: "#757575",
                    color: 'white',
                    borderRadius: "50%",
                    minWidth: '28px',
                    width: '28px',
                    height: '28px',
                    padding: 0,
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#757575',
                    },
                  }}
                >
                <Close />
              </Button>
            </div>

              <div className="p-6 space-y-6">
                <Box sx={{ p: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <RenderSection
                        title="Filing Information"
                        content={<FilingInfoSection filingData={filingData} />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <RenderSection
                        title="Filer Information"
                        content={<FilerInfoSection filingData={filingData} />}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <RenderSection
                        title="Billing Information"
                        content={<BillingInfoSection filingData={filingData} />}
                      />
                    </Grid>
                    <Grid container spacing={2}>
                      <DocumentsSection filingData={filingData} />
                    </Grid>
                    <Grid item xs={6}>
                      <RenderSection
                        title="Envelope Fee Information"
                        content={
                          <Box sx={{ 
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                          }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box sx={{ 
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  p: 2,
                                }}>
                                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>My File Runner Service Fees</Typography>
                                  <Typography sx={{ fontSize: 14 }}>{filingData.fees.serviceFees}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ 
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  p: 2,
                                  backgroundColor: '#F7F9FC'
                                }}>
                                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>My File Runner Tax Fees</Typography>
                                  <Typography sx={{ fontSize: 14 }}>{filingData.fees.taxFees}</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <RenderSection
                        title="Filing Fee"
                        content={
                          <Box sx={{ 
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                          }}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Box sx={{ 
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  p: 2,
                                }}>
                                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Fee</Typography>
                                  <Typography sx={{ fontSize: 14 }}>{filingData.fees.filingFee}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ 
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  p: 2,
                                  backgroundColor: '#F7F9FC'
                                }}>
                                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing & Services Fee</Typography>
                                  <Typography sx={{ fontSize: 14 }}>{filingData.fees.filingServicesFee}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ 
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  p: 2,
                                }}>
                                  <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Court Fees (Total)</Typography>
                                  <Typography sx={{ fontSize: 14 }}>{filingData.fees.courtFeesTotal}</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <div className="flex justify-center mb-4">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#5261A0",
                    textTransform: "capitalize",
                    fontSize: 14,
                    paddingX: 18,
                    "&:hover": { backgroundColor: "#3e4d7a" },
                  }}
                  onClick={handlePrintReceipt}
                >
                  Print Receipt
                </Button>
              </div>
            </div>
          </div>
        )}

      <div className="flex justify-center mt-4">
        <Button
          sx={{
            backgroundColor: "#E5EBF8",
            color: "#5261A0",
            textTransform: "capitalize",
            fontSize: 14,
            paddingX: 8,
            borderRadius: "100px",
          }}
          onClick={openDialog}
        >
          View All Filing Information
        </Button>
      </div>
    </div>
  );
};

export default FilingInformation;
